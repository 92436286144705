import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { CalcAge, GenderKo, YyyyMmDd, ClientStateKo, YyyyMmDdHhMmSs, LivingKo, MarriageKo, EmploymentKo, NationalityKo, MedicationKo, ScheduleKindKo, SessionStateKo, RiskKo, ReferralKo, DesiredServiceKo, ReasonKo } from 'components/Function/ChangeText';
import { CSVLink } from "react-csv";

import { DashboardLayout } from 'components/Layout';
import { ClientReportView } from 'components/User/Client';

const ClientReportContainer = (props) => {
    const pages = [
        { name: '내담자', link: '/client' },
        { name: '리포트', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [clients, setClients] = useState([]);
    const [report, setReport] = useState({
        Kind: 1,
        ClientState: 1,
        StartDate: '',
        EndDate: '',
        Scales: [],
        Option: [],
        Separator: ','
    });
    const [scales, setScales] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClickDownload = () => {
        setLoading(true);
        let rowData = [...clients];
        if(Number(report.ClientState) === 1){
            rowData = rowData.filter((item) => (item.State <= 7));
        }else if(Number(report.ClientState) === 2){
            rowData = rowData.filter((item) => (item.State === 8));
        }
        let csvData = [
            ['내담자 번호', '내담자ID', '이름', '상담자', '가입일시', '현황', '생년월일', '만 나이', '성별', '이메일', '연락처', '주소', '주거형태', '주거형태 상세', '결혼유무', '최종학력', '직업', '부양가족 수', '국적', '국적 상세', '종교', '복용약물', '복용약물 상세', '위험도', '비고', '찾아오게 된 경위', '찾아오게 된 경위 상세', '찾아오게 된 목적', '상담 받고 싶은 점', '이전 상담 여부', '현재 고민/어려움 (혹은 상담목표)', '그룹', 'My분류']
        ];
        if(report.Kind === 1){
            // 리포트 종류 내담자
            if(report.StartDate){
                rowData = rowData.filter((item) => (new Date(report.StartDate) <= new Date(item.createdAt)));
            }
            if(report.EndDate){
                rowData = rowData.filter((item) => (new Date(`${report.EndDate} 23:59:59`) >= new Date(item.createdAt)));
            }
            for(let i = 0; i < rowData.length; i++){
                let desiredService = '';
                for(let j = 0; j < rowData[i].DesiredService.length; j++){
                    if(desiredService === ''){
                        desiredService = DesiredServiceKo(rowData[i].DesiredService[j]);                        
                    }else{
                        desiredService += `, ${DesiredServiceKo(rowData[i].DesiredService[j])}`;
                    }
                }
                let reason = '';
                for(let j = 0; j < rowData[i].Reason.length; j++){
                    if(reason === ''){
                        reason = ReasonKo(rowData[i].Reason[j]);                        
                    }else{
                        reason += `, ${ReasonKo(rowData[i].Reason[j])}`;
                    }
                }
                let priorTreatment = rowData[i].PriorTreatment?.PriorExperience ? 'O' : 'X';
                csvData.push([i+1, rowData[i].NickName, rowData[i].Name_decrypt, rowData[i].Counselor_id.Name, YyyyMmDdHhMmSs(rowData[i].createdAt), ClientStateKo(rowData[i].State), rowData[i].Birth, CalcAge(rowData[i].Birth), GenderKo(rowData[i].Gender), rowData[i].Email_decrypt, rowData[i].Phone_decrypt, rowData[i].Address, LivingKo(rowData[i].Living), rowData[i].LivingDetail, MarriageKo(rowData[i].Marriage), rowData[i].Education, EmploymentKo(rowData[i].Employment), rowData[i].Dependents !== null ? `${rowData[i].Dependents}명` : undefined, NationalityKo(rowData[i].Nationality), rowData[i].NationalityDetail, rowData[i].Religion, MedicationKo(rowData[i].Medication), rowData[i].MedicationDetail, RiskKo(rowData[i].Risk), rowData[i].Memo, ReferralKo(rowData[i].Referral), rowData[i].ReferralDetail, desiredService, reason, priorTreatment, rowData[i].Concern, ( rowData[i].Group.length ? rowData[i].Group[0].Name : '' ), rowData[i].Category]);
            }
        }else{
            // 리포트 종류 상담회기
            csvData[0].push('상담 번호');
            csvData[0].push('상담일시');
            csvData[0].push('상담종류');
            csvData[0].push('상태');
            csvData[0].push('상담비');
            csvData[0].push('지불여부');
            csvData[0].push('상담내용');
            csvData[0].push('기능평가');
            for(let i = 0; i < report.Scales.length; i++){
                const tumpScales = scales.filter((item) => (item._id === report.Scales[i]))[0];
                csvData[0].push(tumpScales.Label);
            }
            let dc = 1;
            let fd = 0;
            let cnt = 0;
            for(let i = 0; i < rowData.length; i++){
                for(let j = 0; j < rowData[i].Schedule_ids.length; j++){
                    let ids = false; // insert_data_start
                    let ide = false; // insert_data_end
                    if(!report.StartDate){
                        ids = true;
                    }else if(new Date(report.StartDate) <= new Date(rowData[i].Schedule_ids[j].StartDate)){
                        ids = true;
                    }
                    if(!report.EndDate){
                        ide = true;
                    }else if(new Date(`${report.EndDate} 23:59:59`) >= new Date(rowData[i].Schedule_ids[j].StartDate)){
                        ide = true
                    }

                    if(ids && ide){
                        if(fd === 0){
                            fd = 1;
                            cnt++;
                            let desiredService = '';
                            for(let j = 0; j < rowData[i].DesiredService.length; j++){
                                if(desiredService === ''){
                                    desiredService = DesiredServiceKo(rowData[i].DesiredService[j]);                        
                                }else{
                                    desiredService += `, ${DesiredServiceKo(rowData[i].DesiredService[j])}`;
                                }
                            }
                            let reason = '';
                            for(let j = 0; j < rowData[i].Reason.length; j++){
                                if(reason === ''){
                                    reason = ReasonKo(rowData[i].Reason[j]);                        
                                }else{
                                    reason += `, ${ReasonKo(rowData[i].Reason[j])}`;
                                }
                            }
                            let priorTreatment = rowData[i].PriorTreatment?.PriorExperience ? 'O' : 'X';
                            csvData.push([cnt, rowData[i].NickName, rowData[i].Name_decrypt, rowData[i].Counselor_id.Name, YyyyMmDdHhMmSs(rowData[i].createdAt), ClientStateKo(rowData[i].State), rowData[i].Birth, CalcAge(rowData[i].Birth), GenderKo(rowData[i].Gender), rowData[i].Email_decrypt, rowData[i].Phone_decrypt, rowData[i].Address, LivingKo(rowData[i].Living), rowData[i].LivingDetail, MarriageKo(rowData[i].Marriage), rowData[i].Education, EmploymentKo(rowData[i].Employment), rowData[i].Dependents !== null ? `${rowData[i].Dependents}명` : undefined, NationalityKo(rowData[i].Nationality), rowData[i].NationalityDetail, rowData[i].Religion, MedicationKo(rowData[i].Medication), rowData[i].MedicationDetail, RiskKo(rowData[i].Risk), rowData[i].Memo, ReferralKo(rowData[i].Referral), rowData[i].ReferralDetail, desiredService, reason, priorTreatment, rowData[i].Concern, ( rowData[i].Group.length ? rowData[i].Group[0].Name : '' ), rowData[i].Category]);
                        }else{
                            csvData.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
                        }
                        csvData[dc].push(rowData[i].Schedule_ids[j].ScheduleNo);
                        csvData[dc].push(`${YyyyMmDd(rowData[i].Schedule_ids[j].StartDate)} ${rowData[i].Schedule_ids[j].StartTime}`);
                        csvData[dc].push(ScheduleKindKo(rowData[i].Schedule_ids[j].Kind.Detail));
                        csvData[dc].push(SessionStateKo(rowData[i].Schedule_ids[j].State));
                        csvData[dc].push(rowData[i].Schedule_ids[j].IsFee && rowData[i].Schedule_ids[j].Fee ? rowData[i].Schedule_ids[j].Fee.toLocaleString() : '');
                        csvData[dc].push(rowData[i].Schedule_ids[j].IsFee ? 'O' : 'X');
                        csvData[dc].push(rowData[i].Schedule_ids[j].Note);
                        csvData[dc].push(rowData[i].Schedule_ids[j].GAF);
                        for(let l = 0; l < report.Scales.length; l++){
                            const tumpScaleAnswer = rowData[i].Schedule_ids[j].ScaleAnswer.filter((item) => (item.Scale_id === report.Scales[l]))[0];
                            if(tumpScaleAnswer?.Scale_id === report.Scales[l]){
                                const tumpScale = scales.filter((a) => (a._id === report.Scales[l]))[0];
                                if(tumpScale.CalScore){
                                    let point = 0;
                                    for(let k = 0; k < tumpScaleAnswer.Answer.length; k++){
                                        let tumpItem = tumpScale.Item_ids.filter((item) => (item._id === tumpScaleAnswer.Answer[k].Item_id))[0];
                                        for(let k2 = 0; k2 < tumpScaleAnswer.Answer[k].Answer.length; k2++){
                                            // if(tumpScale.Negative){
                                            //     point -= tumpItem.Points[`${tumpScaleAnswer.Answer[k].Answer[k2]}`];
                                            // }else{
                                                point += tumpItem.Points[`${tumpScaleAnswer.Answer[k].Answer[k2]}`];
                                            // }
                                        }
                                    }
                                    csvData[dc].push(point);
                                }else{
                                    csvData[dc].push('O');
                                }
                            }else{
                                csvData[dc].push('');
                            }
                        }
                        dc += 1;
                    }
                } 
                fd = 0;
            }
        }
        setCsvData(csvData);
        setTimeout(() => {
            clickLink();
            setLoading(false);
            enqueueSnackbar('다운로드가 완료되었습니다.', { variant: 'info' });
        }, [2000]);
    };
    const clickLink = () => {
        window.document.getElementById('csv_download').click();
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/client/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadClients = [...res.data.ClientDatas];
                let loadScales = [];
                for(let i = 0; i < loadClients.length; i++){
                    loadClients[i].Age = CalcAge(loadClients[i].Birth);
                    loadClients[i].GenderValue = GenderKo(loadClients[i].Gender);
                    loadClients[i].BirthValue = YyyyMmDd(loadClients[i].Birth);
                    loadClients[i].StateValue = ClientStateKo(loadClients[i].State);
                    loadClients[i].CounselorName = loadClients[i].Counselor_id ? loadClients[i].Counselor_id.Name : '';
                    loadClients[i].Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
                    loadClients[i].PrimaryScale_id = loadClients[i].Scales.filter((item) => (item.Primary && item.Scale_id))[0];
                    loadClients[i].PrimaryScale = loadClients[i].PrimaryScale_id ? loadClients[i].PrimaryScale_id.Scale_id.Label : '';
                    loadScales = loadScales.concat(loadClients[i].Scales);
                }
                loadScales = loadScales.filter((item) => (!item.Basic && item.Scale_id));
                for(let i = 0; i < loadScales.length; i++){
                    loadScales[i] = loadScales[i].Scale_id;
                }
                let tump = [];
                tump = loadScales.filter((v, i) => { //문제풀이 정보의 중복을 제거하여 임시로 저장 => 가장 최근의 문제기록만 남게 됨.
                    return (loadScales.findIndex((w, j) => {     
                        return v._id === w._id;
                    })
                    === i);
                });
                // console.log(loadClients)
                // console.log(tump);
                setScales(tump);
                setClients(loadClients);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [enqueueSnackbar, cookies.LoginKey, setClients]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientReportView 
                clients={clients}
                report={report}
                setReport={setReport}
                scales={scales}
                handleClickDownload={handleClickDownload}
                loading={loading}
            />
            <CSVLink style={{ display: 'none' }} data={csvData} separator={report.Separator} id='csv_download' filename={`download_${YyyyMmDdHhMmSs(new Date())}_${report.Kind === 1 ? '내담자' : '상담회기'}`}>Download me</CSVLink>
        </DashboardLayout>
    );
};

export default ClientReportContainer;