import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { SuperviseeDetailView } from 'components/User/Manage/Supervisee';
import { YyyyMmDd, ScheduleKindKo, ServiceKo, SessionStateKo, GoalActiveKo, GoalPeriodKo, YyMmDd } from 'components/Function/ChangeText';

const SuperviseeDetailContainer = (props) => {
    const { clientNo } = useParams();
    const [cookies] = useCookies(['LoginKey', 'Status']);
    const { enqueueSnackbar } = useSnackbar();
    const [client, setClient] = useState({
        NickName: '', // 내담자 ID
        Name: '', // 이름
        Email: '', // 이메일
        Phone: '', // 연락처
        Counselor_id: { _id : '', Name: '', Open: false }, // 담당자 _id
        CounselorId: '',
        Risk: '', // 위험도
        Address: '', // 현주소
        Brith: '', // 생년월일
        Gender: '', // 성별
        Marriage: '', // 결혼 유무
        Military: '', // 병역
        Education: '', // 최종학력
        Employment: '', // 직업
        Living: '', // 주거형태
        LivingDetail: '', // 주거형태 상세
        Dependents: '', // 부양가족 수
        Nationality: '', // 국적
        NationalityDetail: '', // 국적 상세
        Religion: '', // 종교
        Medication: '', // 복용약물
        MedicationDetail: '', //복용약물 상세
        Family: [{ // 가족 사항
            Relation: '', // 관계
            Age: '', // 연령
            Name: '', // 이름
            Phone: '' // 연락처
        }],
        Referral: '', // 찾아오게된 경위
        ReferralDetail: '', // 경위 상세
        DesiredService: [], // 찾아온 목적
        Reason: [],  // 상담 받고 싶은 점
        Concern: '', // 현재 고민이나 어려움
        DesiredTest: [], // 받고 싶은 검사
        PriorTreatment: {  // 이전 검사
            PriorExperience: false, // 여부
            When: '', // 언제
            Where: '', // 어디서 
            How: [], // 치료 유형
        },
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Schedule_ids: [],
        Scales: []
    });
    const pages = [
        { name: '상담자 관리', link: '/manage/supervisee' },
        { name: '상담자(수퍼바이지) 상세', link: '' },
        { name: client.Name ? `이름: <b>${client.Name}</b>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;내담자ID: <b>${client.NickName}</b>` : `내담자ID: <b>${client.NickName}</b>`, link: '' }
    ];
    
    const [selectSession, setSelectSession] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Status: 1,
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [goals, setGoals] = useState([]);
    const [selectGoal, setSelectGoal] = useState({});

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/superviser/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadClient = res.data.ClientData;
                loadClient.Email = loadClient.Email_decrypt;
                loadClient.Phone = loadClient.Phone_decrypt;
                loadClient.Name = loadClient.Name_decrypt;
                loadClient.Available = loadClient.Available.length ? loadClient.Available : [[], [], [], [], [], [], []];
                loadClient.AvailableDay = [];
                loadClient.CreatedAtValue = YyMmDd(loadClient.createdAt);
                for(let i = 0; i < loadClient.Available.length; i++){
                    if(loadClient.Available[i].length){
                        loadClient.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadClient.Available[i].length; j++){
                            loadClient.Available[i][j] = loadClient.Available[i][j].toString();
                        }
                    }
                }
                loadClient.Scales = loadClient.Scales.filter((item) => (item.Scale_id?.State === 1));
                loadClient.Schedule_ids = loadClient.Schedule_ids.filter((item) => (item.Counselor_id._id === loadClient.Counselor_id._id));
                for(let i = 0; i < loadClient.Schedule_ids.length; i++){
                    loadClient.Schedule_ids[i].No = i + 1;
                    loadClient.Schedule_ids[i].ClientId = loadClient.Schedule_ids[i].Client_id._id;
                    loadClient.Schedule_ids[i].CounselorId = loadClient.Schedule_ids[i].Counselor_id._id;
                    loadClient.Schedule_ids[i].CounselorName = loadClient.Schedule_ids[i].Counselor_id.Name;
                    loadClient.Schedule_ids[i].StartDateValue = YyyyMmDd(loadClient.Schedule_ids[i].StartDate);
                    loadClient.Schedule_ids[i].StartDateTimeValue = `${YyyyMmDd(loadClient.Schedule_ids[i].StartDate)} ${loadClient.Schedule_ids[i].StartTime}`;
                    loadClient.Schedule_ids[i].KindDetailValue = ScheduleKindKo(loadClient.Schedule_ids[i].Kind.Detail);
                    loadClient.Schedule_ids[i].ServiceValue = ServiceKo(loadClient.Schedule_ids[i].Service);
                    loadClient.Schedule_ids[i].StateValue = SessionStateKo(loadClient.Schedule_ids[i].State);
                    if(loadClient.Schedule_ids[i].ScaleAnswer.length){
                        for(let j = 0; j < loadClient.Schedule_ids[i].ScaleAnswer.length; j++){
                            if(loadClient.Schedule_ids[i].ScaleAnswer[j].Answer.length){
                                let tumpScale = loadClient.Scales.filter((item) => (item.Scale_id?._id === loadClient.Schedule_ids[i].ScaleAnswer[j]?.Scale_id))[0]?.Scale_id;
                                if(tumpScale){
                                    if(tumpScale.CalScore){
                                    let tumpPoint = 0;
                                    let maxPoint = 0;
                                    let subScalePoint = {};
                                        for(let k = 0; k < loadClient.Schedule_ids[i].ScaleAnswer[j].Answer.length; k++){
                                            for(let k2 = 0; k2 < loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer.length; k2++){
                                                let tumpItem = tumpScale.Item_ids.filter((item) => (item._id === loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Item_id))[0];
                                                if( tumpItem !== undefined ) {
                                                    if(tumpItem.SubScale){
                                                        if(subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`]){
                                                            // if(tumpScale.Negative){
                                                            //     subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] -= tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }else{
                                                                subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] += tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }
                                                        }else{
                                                            // if(tumpScale.Negative){
                                                            //     subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] = tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]]*(-1) : 0;   
                                                            // }else{
                                                                subScalePoint[`${tumpScale._id}${tumpItem.SubScale-1}`] = tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                            // }
                                                        }
                                                    }
                                                    // if(tumpScale.Negative){
                                                    //     tumpPoint -= tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]];   
                                                    //     maxPoint -= Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                                                    // }else{
                                                            tumpPoint += tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] ? tumpItem.Points[loadClient.Schedule_ids[i].ScaleAnswer[j].Answer[k].Answer[k2]] : 0;   
                                                        maxPoint += Math.max.apply(null, tumpItem.Points) ? Math.max.apply(null, tumpItem.Points) : 0;
                                                    // }
                                                }
                                            }
                                        }
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}`] = tumpPoint;
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}Max`] = maxPoint;
                                        Object.assign(loadClient.Schedule_ids[i], subScalePoint);
                                    }else{
                                        loadClient.Schedule_ids[i][`${loadClient.Schedule_ids[i].ScaleAnswer[j].Scale_id}`] = 'O';
                                    }
                                }
                            }
                        }
                    }
			    }
                setClient(loadClient);
                
                setSelectSession({
                    Client_id: loadClient._id,
                    Counselor_id: loadClient.Counselor_id ? loadClient.Counselor_id._id : '',
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Status: 1,
                    Room_id: '',
                    Kind: { State: 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    Memo: ''
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        // 목표
        // await axios.get(`/api/goal/${clientNo}/${cookies.LoginKey}`, config)
        // .then((res) => {
        //     if(!res.data.ErrorMessage){
        //         let loadGoals = [...res.data.GoalDatas];
        //         for(let i = 0; i < loadGoals.length; i++){
        //             loadGoals[i].No = i + 1;
        //             loadGoals[i].ActiveValue = GoalActiveKo(loadGoals[i].Active);
        //             loadGoals[i].PeriodValue = GoalPeriodKo(loadGoals[i].Period);
        //             if(loadGoals[i].Points.length === 0){
        //                 loadGoals[i].StartEnd = ` - `;
        //             }else if(loadGoals[i].Points.length === 1){
        //                 loadGoals[i].StartEnd = `${loadGoals[i].Points[0].Point} - `;
        //             }else{
        //                 loadGoals[i].StartEnd = `${loadGoals[i].Points[0].Point} - ${loadGoals[i].Points[loadGoals[i].Points.length - 1].Point}`;
        //             }
        //         }
        //         setGoals(loadGoals);
        //     }else{
        //         enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
        //     }
        // })
        // .catch((err) => {
        //     console.log(err);
        // });
    }, [enqueueSnackbar, cookies.LoginKey, clientNo, setClient, setGoals]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <SuperviseeDetailView 
                client={client}
                setClient={setClient}
                selectSession={selectSession}
                setSelectSession={setSelectSession}
                goals={goals}
                selectGoal={selectGoal}
                setSelectGoal={setSelectGoal}
                status={cookies.Status}
            />
        </DashboardLayout>
    );
};

export default SuperviseeDetailContainer;