export const Risk = () => {
    return (
        [
            { value: '1', label: '낮음' },
            { value: '2', label: '중간' },
            { value: '3', label: '높음' }
        ]
    );
};

export const Gender = () => {
    return (
        [
            { value: '1', label: '남성' },
            { value: '2', label: '여성' },
            // { value: '3', label: '논바이너리' },
            // { value: '4', label: '다른 성 정체성' }
            { value: '3', label: '기타' }
        ]
    );
};

export const Employment = () => {
    return (
        [
            { value: '1', label: '회사원/피고용인' },
            { value: '2', label: '자영업자' },
            { value: '3', label: '구직활동 중' },
            { value: '4', label: '구직활동 중이지 않음' },
            { value: '5', label: '학생' },
            { value: '6', label: '전업주부' },
            { value: '7', label: '자원 봉사' },
            { value: '8', label: '퇴직' },
            { value: '9', label: '명시되지 않음/기타' }
        ]
    );
};

export const Living = () => {
    return (
        [
            { value: '1', label: '자취' },
            { value: '2', label: '동거' },
            { value: '3', label: '부모(혹은 원가족)와 함께' },
            { value: '4', label: '배우자(혹은 결혼 후 형성한 가족)와 함께' },
            { value: '5', label: '기숙사/숙소' },
            { value: '6', label: '기타' }
        ]
    );
};

export const Nationality = () => {
    return (
        [
            { value: '1', label: '한국' },
            { value: '2', label: '미국' },
            { value: '3', label: '중국' },
            { value: '4', label: '베트남' },
            { value: '5', label: '태국' },
            { value: '6', label: '우즈베키스탄' },
            { value: '7', label: '필리핀' },
            { value: '8', label: '일본' },
            { value: '9', label: '기타' }
        ]
    );
};

export const Medication = () => {
    return (
        [
            { value: '1', label: '없음' },
            { value: '2', label: '항우울제' },
            { value: '3', label: '항불안제/수면제' },
            { value: '4', label: '항정신병제' },
            { value: '5', label: '기분 안정제' },
            { value: '6', label: '기타' }
        ]
    );
};

export const PossibleDay = () => {
    return(
        [
            { value: '1', label: '일요일' },
            { value: '2', label: '월요일' },
            { value: '3', label: '화요일' },
            { value: '4', label: '수요일' },
            { value: '5', label: '목요일' },
            { value: '6', label: '금요일' },
            { value: '7', label: '토요일' },
        ]
    );
};

export const PossibleTime = () => {
    return(
        [
            { value: '1', label: '07:00 ~ 08:00' },
            { value: '2', label: '08:00 ~ 09:00' },
            { value: '3', label: '09:00 ~ 10:00' },
            { value: '4', label: '10:00 ~ 11:00' },
            { value: '5', label: '11:00 ~ 12:00' },
            { value: '6', label: '12:00 ~ 13:00' },
            { value: '7', label: '13:00 ~ 14:00' },
            { value: '8', label: '14:00 ~ 15:00' },
            { value: '9', label: '15:00 ~ 16:00' },
            { value: '10', label: '16:00 ~ 17:00' },
            { value: '11', label: '17:00 ~ 18:00' },
            { value: '12', label: '18:00 ~ 19:00' },
            { value: '13', label: '19:00 ~ 20:00' },
            { value: '14', label: '20:00 ~ 21:00' },
            { value: '15', label: '21:00 ~ 22:00' },
            { value: '16', label: '22:00 ~ 23:00' },
            { value: '17', label: '23:00 ~ 24:00' }
        ]
    );
};

export const Marriage = () => {
    return (
        [
            { value: '1', label: '미혼' },
            { value: '2', label: '기혼' },
            { value: '3', label: '이혼' },
            { value: '4', label: '별거' },
            { value: '5', label: '재혼' },
            { value: '6', label: '기타' }
        ]
    );
};

export const Military = () => {
    return (
        [
            { value: '1', label: '현역' },
            { value: '2', label: '방위' },
            { value: '3', label: '미필' },
            { value: '4', label: '면제' },
            { value: '5', label: '해당하지 않음' }
        ]
    );
};

export const Referral = () => {
    return (
        [
            { value: '1', label: '자진해서' },
            { value: '2', label: '인터넷 검색이나 안내 팜플렛을 통해' },
            { value: '3', label: '주위 사람의 권유' },
            { value: '4', label: '기타' }
        ]
    );
};

export const DesiredService = () => {
    return (
        [
            { value: '1', label: '개인 상담' },
            { value: '2', label: '집단 상담' },
            { value: '3', label: '가족 상담' },
            { value: '4', label: '심리 검사' }
        ]
    );
};

export const Reason = () => {
    return (
        [
            { value: '1', label: '성격' },
            { value: '2', label: '심리 정서' },
            { value: '3', label: '직장' },
            { value: '4', label: '학업' },
            { value: '5', label: '진로' },
            { value: '6', label: '가족관계' },
            { value: '7', label: '연인관계' },
            { value: '8', label: '대인관계' },
            { value: '9', label: '기타' }
        ]
    );
};

export const DesiredTest = () => {
    return (
        [
            { value: '1', label: '성격' },
            { value: '2', label: '정신건강' },
            { value: '3', label: '진로 및 적성' },
            { value: '4', label: '지능 및 인지' },
            { value: '5', label: '기타' }
        ]
    );
};

export const BeforeSession = () => {
    return (
        [
            { value: '1', label: '상담' },
            { value: '2', label: '심리검사' },
            { value: '3', label: '약물치료' }
        ]
    );
};

export const SessionKind = () => {
    return (
        [
            { value: '1', label: '개인상담' },
            { value: '2', label: '접수면접' },
            { value: '3', label: '검사실시' },
            { value: '4', label: '검사해석' },
            { value: '5', label: '집단상담' },
            { value: '6', label: '커플상담' },
            { value: '7', label: '가족상담' },
            { value: '8', label: '위기상담' },
            { value: '9', label: '면담·자문' },
            { value: '10', label: '종결' }
        ]
    );
};

export const ScheduleKind = () => {
    return (
        [
            { value: '14', label: '회의' },
            { value: '13', label: '사례회의' },
            { value: '11', label: '개인수퍼비전' },
            { value: '12', label: '그룹수퍼비전' },
            { value: '25', label: '공개사례발표' },
            { value: '15', label: '교육' },
            { value: '16', label: '특강' }, 
            { value: '18', label: '강의' },
            { value: '17', label: '준비' },
            { value: '19', label: 'OFF' },
            { value: '20', label: '[예약가능]' },
            { value: '21', label: '[예약가능]상담' },
            { value: '22', label: '[예약가능]접수면접' },
            { value: '23', label: '[예약가능]검사실시' },
            { value: '24', label: '[예약가능]검사해석' },
        ]
    );
};

export const Service = () => {
    return (
        [
            { value: '1', label: '대면' },
            { value: '2', label: '화상' },
            { value: '3', label: '전화' },
            { value: '4', label: '문자' },
            { value: '5', label: '이메일' }
        ]
    );
};

export const SessionTime = () => {
    return (
        [
            { value: 5, label: '5분' },
            { value: 15, label: '15분' },
            { value: 30, label: '30분' },
            { value: 45, label: '45분' },
            { value: 50, label: '50분' },
            { value: 60, label: '60분' },
            { value: 90, label: '90분' },
            { value: 120, label: '120분' },
        ]
    );
};

export const RepeatDay = () => {
    let tumpDay = [];
    for(let i = 0; i < 28; i++){
        tumpDay.push({ value: i+1, label: `${i+1}일` });
    }
    return tumpDay;
};

export const RepeatCount = () => {
    let tumpCnt = [];
    for(let i = 1; i < 20; i++){
        tumpCnt.push({ value: i+1, label: `${i+1}회` });
    }
    return tumpCnt;
};

export const Times = () => {
    return(
        ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
        '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
        // ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
        // '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    );
};

export const TimesOnly = () => {
    return(
        ['07', '08', '09', '10', '11', '12',
        '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
        // ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
        // '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    );
};

export const Minute = () => {
    return(
        ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
    );
};

export const Separator = () => {
    return (
        [
            { value: ',', label: 'Comma ,' },
            { value: '|', label: 'Pipe |' },
            { value: '/', label: 'Slash /' },
            { value: ';', label: 'Semicolon ;' },
            { value: '  ', label: 'Tap &x09' }
        ]
    );
};

export const RoomState = () => {
    return(
        [
            { value: 1, label: '사용가능' },
            { value: 2, label: '사용불가' }
        ]
    );
};

export const GoalPeriod = () => {
    return(
        [
            { value: 1, label: '1개월 미만' },
            { value: 2, label: '1개월 이상 3개월 미만' },
            { value: 3, label: '3개월 이상 1년 미만' },
            { value: 4, label: '1년 이상 5년 미만' },
            { value: 5, label: '5년 이상' },
            { value: 6, label: '간헐적 / 반복적' }
        ]
    );
};

export const GoalActive = () => {
    return(
        [
            { value: 1, label: '진행중' },
            { value: 2, label: '종료' }
        ]
    );
};

export const ClientState = () => {
    return(
        [
            { value: 1, label: '신청' },
            { value: 2, label: '접수면접예약' },
            { value: 3, label: '접수면접실시' },
            { value: 4, label: '검사예약' },
            { value: 5, label: '검사실시' },
            { value: 6, label: '검사해석' },
            { value: 7, label: '상담중' },
            { value: 8, label: '종결' }
        ]
    );
};

export const ScaleOption = () => {
    return (
        [
            { value: '1', label: '일반 검사' },
            { value: '2', label: '피드백' },
            { value: '3', label: '기본 서류' }
        ]
    );
};

export const SesstionState = () => {
    return (
        [
            { value: '1', label: '예약' },
            { value: '2', label: '취소' },
            { value: '3', label: '불참' },
            { value: '4', label: '참석' },
        ]
    );
};

export const ClosingReason = () => {
    return (
        [
            { value: '1', label: '회기 완료' },
            { value: '2', label: '서비스 거부' },
            { value: '3', label: '이사, 거주지 변경' },
            { value: '4', label: '타 기관 서비스 이용' },
            { value: '5', label: '사망' },
            { value: '6', label: '기타' }
        ]
    );
};

export const Region = () => {
    return (
        [
            { value: '1', label: '서울' },
            { value: '2', label: '경기' },
            { value: '3', label: '인천' },
            { value: '4', label: '부산' },
            { value: '5', label: '대구' },
            { value: '6', label: '광주' },
            { value: '7', label: '대전' },
            { value: '8', label: '울산' },
            { value: '9', label: '강원' },
            { value: '10', label: '충북' },
            { value: '11', label: '충남' },
            { value: '12', label: '전북' },
            { value: '13', label: '전남' },
            { value: '14', label: '경북' },
            { value: '15', label: '경남' },
            { value: '16', label: '제주' },
            { value: '17', label: '세종' }
        ]
    );
};

export const License = () => { 
    return (
        [
            { value: '1', label: '(국가전문자격)정신건강전문요원 1급'},
            { value: '2', label: '(국가전문자격)청소년상담사 1급'},
            { value: '3', label: '(국가전문자격)전문상담교사 1급'},
            { value: '4', label: '(민간자격)임상심리전문가(한국심리학회)'},
            { value: '5', label: '(민간자격)상담심리사 1급(한국상담심리학회/한국심리학회)'},
            { value: '6', label: '(민간자격)전문상담사 1급(한국상담학회)'},
            { value: '7', label: '(국가전문자격)정신건강전문요원 2급'},
            { value: '8', label: '(국가전문자격)청소년상담사 2급'},
            { value: '9', label: '(국가전문자격)전문상담교사 2급'},
            { value: '10', label: '(국가기술자격)임상심리사 1급'},
            { value: '11', label: '(민간자격)상담심리사 2급(한국상담심리학회/한국심리학회)'},
            { value: '12', label: '(민간자격)전문상담사 2급(한국상담학회)'},
            { value: '13', label: '기타(1급 유형)'},
            { value: '14', label: '기타(2급 유형)'}
        ]
    );
};