import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ReportList } from 'domains/User/Report';
import NotFound from 'routes';

const ReportRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ReportList />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ReportRoute;