import React, { useEffect, useState } from 'react';
import { Grid, FormControl, OutlinedInput, FormHelperText } from '@mui/material';
import { TooltipButton } from '../Button';

/**
 * 
 * @param {string} value 값
 * @param {function(): string} handleChange 
 * @param {string} placeholder 도움말
 * @param {boolean} required 필수 입력 여부
 * @param {boolean} error 에러 여부
 * @param {boolean} disabled 에러 여부
 * @param {string} label 라벨
 * @param {number} rows 줄 수
 * @description 
 */
const TextForm = (props) => {
    const handlePress = (e) => {
        if(props.handlePress && props.value){
            if(e.code === 'Enter'){
                props.handlePress();
            }else{
                return false;
            }
        }else{
            return false;
        }
    };
    return (
        <>
            <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                <Grid container>
                {props.label ?
                    <Grid item style={{ marginBottom: '8px' }}>
                        {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                    </Grid> :
                    null
                }
                {props.tooltip ?
                    <Grid item>
                        <TooltipButton tooltip={props.tooltip}/>
                    </Grid>:
                    null
                }
                </Grid>
                <OutlinedInput
                    type={'text'}
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    disabled={Boolean(props.disabled)}
                    onChange={props.handleChange}
                    rows={props.rows && props.rows !== 'auto' ? props.rows : ''}
                    multiline={Boolean(props.rows)}
                    sx={{ height: 'auto' }}
                    onKeyPress={handlePress}
                />
                {props.error ? <FormHelperText style={{ marginLeft: 0 }}>&nbsp;{props.errorMessage ? props.errorMessage : ' '}</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
            </FormControl>
        </>
    );
};

export default TextForm;