import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm, SelectForm } from 'components/Items/Form';
import { ConfirmModal, FormModal } from 'components/Items/Modal';

const TeamDetailView = (props) => {
    const navigate = useNavigate();
    const { teamNo } = useParams();
    const [openTeamDeleteModal, setOpenTeamDeleteModal] = useState(false);
    const [openMemberAddModal, setOpenMemberAddModal] = useState(false);
    const [openMemberRemoveModal, setOpenMemberRemoveModal] = useState(false);
    const [search, setSearch] = useState('');
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'Email', label: '이메일' },
        { key: 'Phone', label: '연락처' },
        { key: 'StatusKo', label: '권한' },
    ];
    const handleCloseMemberAdd = () => {
        setOpenMemberAddModal(false);
        props.setAddMember('');
    };
    const handleClickDetail = (data) => {
        navigate(`/manage/member/${data.UserNo}`);
    };
    const handleClickDelete = (data) => {
        props.setRemoveMember(data._id);
        setOpenMemberRemoveModal(true);
    };
    const handleCloseMemberRemove = () => {
        setOpenMemberRemoveModal(false);
        props.setRemoveMember('');
    };

    return (
        <>
        <DetailContainer
            title='팀 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenTeamDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/manage/team/${teamNo}/update`) } ]}
        >
            <DataBox
                datas={[
                    { key: '이름', value: props.team.Name },
                    { key: '인원', value: props.team.UserCnt }
                ]}
            />
        </DetailContainer>
        <DetailContainer
            title='팀원 목록'
            button={{ label: '팀원 추가', handleClick: () => setOpenMemberAddModal(true) }}
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={props.team.User_ids}
                        delete={true}
                        update={false}
                        search={search}
                        handleClickDetail={handleClickDetail}
                        handleClickDelete={handleClickDelete}
                    />
                </Grid>
            </Grid>
        </DetailContainer>
        <ConfirmModal
            title='팀 삭제'
            icon='error'
            open={openTeamDeleteModal}
            handleClose={() => setOpenTeamDeleteModal(false)}
            handleClickSubmit={props.handleClickRemoveTeam}
            submitLabel='삭제'
        >
            팀 정보를 삭제 하시겠습니까? 팀 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        <FormModal
            open={openMemberAddModal}
            handleClose={handleCloseMemberAdd}
            handleClickSubmit={() => {props.handleClickAddMember();handleCloseMemberAdd();}}
            title='팀원 추가'
            submitLabel='추가'
        >
            <SelectForm
                label='직원'
                required={true}
                error={false}
                value={props.addMember}
                datas={props.members}
                handleChange={(e) => props.setAddMember(e.target.value)}
            />
        </FormModal>
        <ConfirmModal
            title='팀원 삭제'
            icon='error'
            open={openMemberRemoveModal}
            handleClose={handleCloseMemberRemove}
            handleClickSubmit={() => {props.handleClickRemoveMember();handleCloseMemberRemove();}}
            submitLabel='삭제'
        >
            팀원 정보를 삭제 하시겠습니까? 팀원 삭제 시 복구가 불가능합니다.<br/>(직원 정보는 유지되며, <b>{props.team.Name}</b>에서 제외됩니다.)
        </ConfirmModal>
        </>
    );
};

export default TeamDetailView;