import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Grid, FormHelperText } from '@mui/material';
import { TooltipButton } from '../Button';


const SelectForm = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
            <Grid container>
            {props.label ?
                <Grid item style={{ marginBottom: '8px' }}>
                    {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                </Grid> :
                null
            }
            {props.tooltip ?
                <Grid item>
                    <TooltipButton tooltip={props.tooltip}/>
                </Grid>:
                null
            }
            </Grid>
        <Select
            value={props.value || ''}
            onChange={props.handleChange}
            displayEmpty
            className='select_placeholder'
            disabled={props.disabled}
        >
            <MenuItem value='' disabled={!(props.cancel && Boolean(props.value))}><span>{props.cancel && Boolean(props.value) ? '선택 취소' : '선택해 주세요.'}</span></MenuItem>
            {props.datas?.map((item, i) => (
            <MenuItem key={i} value={item.value} disabled={Boolean(item.disabled)}>{item.label}</MenuItem> 
            ))}
        </Select>
        {props.error ? <FormHelperText style={{ marginLeft: 0 }}>{props.errorMessage}</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
    </FormControl>
    );
};

export default SelectForm;