import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

import { DashboardLayout } from 'components/Layout';
import { MessageListView } from 'components/User/Message';
import { MemberStatus, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const MessageListContainer = (props) => {
    const pages = [
        { name: '메시지', link: '' },
        { name: '메시지 목록', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey', 'UserNo']);
    const [messages, setMessages] = useState([]);
    const [members, setMembers] = useState([]);
    const [sendMessage, setSendMessage] = useState({
        Reciver_id: '',
        Title: '',
        Content: ''
    });

    const handleClickDetailBtn = async(data) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.patch(`/api/message/${data.MessageNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeMessages = [...messages];
                for(let i = 0; i < changeMessages.length; i++){
                    if(changeMessages[i]._id === data._id){
                        changeMessages[i].Read = new Date();
                        changeMessages[i].ReadValue = YyyyMmDdHhMmSs(new Date());
                    }
                }
                setMessages(changeMessages);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickRestoreBtn = async(data) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.put(`/api/message/${data.MessageNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeMessages = [...messages];
                for(let i = 0; i < changeMessages.length; i++){
                    if(changeMessages[i]._id === data._id){
                        changeMessages[i].DeletedAtValue = '-';
                        for(let j = 0 ; j < changeMessages[i].Deleted.length; j++){
                            if(changeMessages[i].Deleted[j].UserNo === Number(cookies.UserNo)){
                                delete changeMessages[i].Deleted[j];
                            }
                        }
                    }
                }
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setMessages(changeMessages);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickSendBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(sendMessage);
        await axios.post(`/api/message/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setSendMessage({ Reciver_id: '', Title: '', Content: '' });
                let changeMessages = [...messages];
                changeMessages.push(res.data.Result);
                changeMessages[changeMessages.length - 1].No = changeMessages.length;
                changeMessages[changeMessages.length - 1].Reciver_id = members.filter((item) => (item._id === changeMessages[changeMessages.length - 1].Reciver_id))[0];
                changeMessages[changeMessages.length - 1].Sender_id = members.filter((item) => (item._id === changeMessages[changeMessages.length - 1].Sender_id))[0];
                changeMessages[changeMessages.length - 1].ReciverName = changeMessages[changeMessages.length - 1].Reciver_id.Name;
                changeMessages[changeMessages.length - 1].SenderName = changeMessages[changeMessages.length - 1].Sender_id.Name;
                changeMessages[changeMessages.length - 1].CreatedAtValue = YyyyMmDdHhMmSs(changeMessages[changeMessages.length - 1].createdAt);
                changeMessages[changeMessages.length - 1].ReadValue = '-';
                changeMessages[changeMessages.length - 1].DeletedAtValue = '-';
                setMessages(changeMessages);
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });    
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickDeleteBtn = async(data) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/message/${data.MessageNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeMessages = [...messages];
                for(let i = 0; i < changeMessages.length; i++){
                    if(changeMessages[i]._id === data._id){
                        changeMessages[i].DeletedAtValue = YyyyMmDdHhMmSs(new Date());
                        changeMessages[i].Deleted = {
                            UserNo: cookies.UserNo,
                            At: new Date(),
                            Hard: false
                        };
                    }
                }
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setMessages(changeMessages);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickHardDeleteBtn = async(data) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/message/hard/${data.MessageNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let changeMessages = [...messages];
                for(let i = 0; i < changeMessages.length; i++){
                    if(changeMessages[i]._id === data._id){
                        delete changeMessages[i]
                    }
                }
                enqueueSnackbar(res.data.Message, { variant: 'success' });
                setMessages(changeMessages);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/message/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMessage = res.data.MessageDatas;
                for(let i = 0; i < loadMessage.length; i++){
                    loadMessage[i].No = i + 1;
                    loadMessage[i].ReciverName = loadMessage[i].Reciver_id.Name;
                    loadMessage[i].SenderName = loadMessage[i].Sender_id.Name;
                    loadMessage[i].CreatedAtValue = YyyyMmDdHhMmSs(loadMessage[i].createdAt);
                    loadMessage[i].ReadValue = loadMessage[i].Read ? YyyyMmDdHhMmSs(loadMessage[i].Read) : '-';
                    const tumpDelete = loadMessage[i].Deleted.filter((item) => (item.UserNo === Number(cookies.UserNo)))[0];
                    loadMessage[i].DeletedAtValue = tumpDelete ? YyyyMmDdHhMmSs(tumpDelete.At) : '-';
                    loadMessage[i].Hard = tumpDelete ? tumpDelete.Hard : false;
                }
                loadMessage = loadMessage.filter((item) => (!item.Hard));
                setMessages(loadMessage);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMembers = res.data.Workers;
                loadMembers = loadMembers.filter((item) => (item._id !== res.data.User_id));
                
                for(let i = 0; i < loadMembers.length; i++){
                    loadMembers[i].No = i + 1;
                    loadMembers[i].StatusKo = MemberStatus(loadMembers[i].Status);
                    loadMembers[i].value = loadMembers[i]._id;
                    loadMembers[i].label = loadMembers[i].Name;
                }
                setMembers(loadMembers);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [cookies.LoginKey, cookies.UserNo, setMessages, enqueueSnackbar]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <MessageListView 
                messages={messages}
                members={members}
                sendMessage={sendMessage}
                setSendMessage={setSendMessage}
                handleClickSendBtn={handleClickSendBtn}
                handleClickDeleteBtn={handleClickDeleteBtn}
                handleClickDetailBtn={handleClickDetailBtn}
                handleClickRestoreBtn={handleClickRestoreBtn}
                handleClickHardDeleteBtn={handleClickHardDeleteBtn}
            />
        </DashboardLayout>
    );
};

export default MessageListContainer;