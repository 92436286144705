import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

import { Times } from 'components/Function/AddDatas';
import { ScheduleKindKo, SelectScheduleBorderColor, SelectScheduleBgColor } from 'components/Function/ChangeText';

const DataCalendarDay = (props) => {
    const navigate = useNavigate();
    if(props.date){
        return (
            <Grid className='table_container'>
                <table className='day_table'>
                    <thead>
                        <tr><th></th><th></th></tr>
                    </thead>
                    <tbody>
                        {Times().map((time, i) => (
                        <tr key={i}>
                            <td
                                style={{ textAlign: 'center' }}
                            >
                                <div style={{ marginTop: '-16.5px'}}>{time}</div>
                            </td>
                            <td
                                style={{ borderBottom: '1px solid #D9D9D9' }}
                            >
                                <Grid container style={{ color: '#252525' }} spacing={1}>
                                    {props.datas.filter((item) => (new Date(new Date(item.StartDate).setHours(0, 0, 0)).toString() === new Date(props.date).toString())).map((item, k) => (
                                    item.StartTime.split(':')[0] === time.split(':')[0] ?
                                    <Grid item xs={12} sm={4} md={3} lg={2} className='week_schedule' key={k} onClick={() => navigate(`/schedule/${item.ScheduleNo}`)}>
                                        <Grid container className={`schedule_border_color${SelectScheduleBorderColor(item.Kind.Detail, item.Date, item.State, item.Visit)}`}>
                                            <Grid item xs={12} className={`schedule_bg_color${SelectScheduleBgColor(item.Kind.Detail)}`} style={{ fontSize: '12px'}}>
                                                {ScheduleKindKo(item.Kind.Detail)}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>[{item.StartTime}]</b> {item.Room_id ? item.Room_id.Name : ''}
                                            </Grid>
                                            <Grid>
                                                {item.Counselor_id.Name} {item.Client_id ? `🤝 ${item.Client_id.Name_decrypt}` : ''}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :null
                                    ))}
                                </Grid>
                                {props.handleClickCreateSchdule ? 
                                <IconButton className='hidden_button' onClick={(e)=>{ e.stopPropagation(); props.handleClickCreateSchdule(props.date, time);}}>
                                    <Add style={{ fontSize: '14px'}}/>
                                </IconButton>:null}
                            </td>
                        </tr>
                        ))}
                        <tr>
                            <td style={{ textAlign: 'center'}}><div style={{ marginTop: '-16.5px', minHeight: '10px'}}>24:00</div></td>
                        </tr>
                    </tbody>
                </table>
            </Grid>
        );
    }else{
        return false;
    }
};

export default DataCalendarDay;