import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ClientChartView } from 'components/User/Client';

const ClientChartContainer = (props) => {
    const pages = [
        { name: '상담폴더', link: '/client' },
        { name: '전체차트', link: '' }
    ];

    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey', 'Status']);
    const [clients, setClients] = useState([]);
    const [scales, setScales] = useState([]);
    const [userId, setUserId] = useState('');
    const [teams, setTeams] = useState([]);

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/client/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadClients = res.data.ClientDatas;
                for(let i = 0; i < loadClients.length; i++){
                    let tumpSchedules = loadClients[i].Schedule_ids.sort((a, b) => (new Date(b.StartDate) - new Date(a.StartDate)));
                    tumpSchedules = tumpSchedules.filter((item) => (item.State === 4));
                    if(tumpSchedules.length > 0){
                        loadClients[i].LastVist = tumpSchedules[0].StartDate;
                    }
                }
                setClients(loadClients);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        await axios.get(`/api/team/chart/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUserId(res.data.User_id);
                setTeams(res.data.TeamDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        await axios.get(`/api/scale/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadScales = [...res.data.ScaleDatas];
                loadScales = loadScales.filter((item) => (item.State === 1));
                setScales(loadScales);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [enqueueSnackbar, cookies.LoginKey, setClients, setScales]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientChartView 
                clients={clients}
                scales={scales}
                userId={userId}
                teams={teams}
                status={cookies.Status}
            />
        </DashboardLayout>
    );
};

export default ClientChartContainer;