import React from 'react';
import Routes from 'routes';

/**
 * @title AppPresenter
 * @description Render Router
 */
const AppPresenter = (props) => {
  return (<Routes />);
};

export default AppPresenter;
