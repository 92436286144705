import React, { useState, useEffect } from 'react';
import { FormControl, Grid, RadioGroup, Radio, FormControlLabel, FormHelperText, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { FormContainer } from 'components/Items/Container';
import { TextForm, NumberForm } from 'components/Items/Form';
import { ScaleOption } from 'components/Function/AddDatas';
import { LargeCancelButton, LargeSubmitButton } from 'components/Items/Button';

const ScaleUpdateView = (props) => {
    const scale = props.scale;
    const setScale = props.setScale;
    const navigate = useNavigate();
    const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
    const handleChangeLabel = (e) => {
        setScale({ ...scale, Label: e.target.value });
    };
    const handleChangeScaleKind = (e) => {
        setScale({ ...scale, ScaleKind: Number(e.target.value) });
    };
    const handleChangeScaleCalScore = () => {
        setScale({ ...scale, CalScore: !scale.CalScore });
    };
    const handleChangeNegative = () => {
        setScale({ ...scale, Negative: !scale.Negative });
    };
    const handleChangeSubScale = (e, key) => {
        let changeSubScale = [...scale.SubScale];
        changeSubScale[key] = e.target.value;
        setScale({ ...scale, SubScale: changeSubScale });
    };
    const handleClickPlus = () => {
        let changeSubScale = [...scale.SubScale];
        changeSubScale.push('');
        setScale({ ...scale, SubScale: changeSubScale });
    };
    const handleClickMinus = () => {
        let changeSubScale = [...scale.SubScale];
        changeSubScale.pop();
        setScale({ ...scale, SubScale: changeSubScale });
    };
    const handleChangeContent = (e) => {
        setScale({ ...scale, Content: e.target.value });
    };
    const handleChangeDetail = (e) => {
        setScale({ ...scale, Detail: e.target.value });
    };
    const handleChangeMin = (e) => {
        setScale({ ...scale, Min: e.target.value });
    };
    const handleChangeMax = (e) => {
        setScale({ ...scale, Max: e.target.value });
    };
    const handleChangeRCI = (e) => {
        setScale({ ...scale, RCI: e.target.value });
    };
    const handleChangeSubMin = (e) => {
        setScale({ ...scale, SubMin: e.target.value });
    };
    const handleChangeSubMax = (e) => {
        setScale({ ...scale, SubMax: e.target.value });
    };
    const handleChangeStandard = (e) => {
        setScale({ ...scale, Standard: e.target.value });
    };
    useEffect(() => {
        let tump = true;
        if(scale.Label && Number(scale.Min) > -1 && Number(scale.Max) > -1 && Number(scale.Standard) > -1){
            tump = false;
        }
        setDisabledUpdateBtn(tump);
    }, [scale]);
    
    return (
        <FormContainer title='검사 수정'>
            <TextForm
                label='이름'
                required={true}
                error={false}
                value={scale.Label}
                placeholder='이름을 입력해주세요.'
                handleChange={handleChangeLabel}
            />
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    검사 종류 <span style={{ color: '#D3A7FF' }}>*</span>
                </Grid>
                <RadioGroup row>
                    {ScaleOption().map((item, i) => (
                    <FormControlLabel key={i} value={item.value} onChange={handleChangeScaleKind} control={<Radio checked={scale.ScaleKind === i+1}/>} label={item.label} />
                    ))} 
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    점수 계산 <span style={{ color: '#D3A7FF' }}>*</span>
                </Grid>
                <RadioGroup row>
                    <FormControlLabel onChange={handleChangeScaleCalScore} control={<Radio checked={scale.CalScore} />} label={'O'} />
                    <FormControlLabel onChange={handleChangeScaleCalScore} control={<Radio checked={!scale.CalScore} />} label={'X'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
                <Grid style={{ marginBottom: '8px' }}>
                    역산 여부 <span style={{ color: '#D3A7FF' }}>*</span>
                </Grid>
                <RadioGroup row>
                    <FormControlLabel onChange={handleChangeNegative} control={<Radio checked={scale.Negative}/>} label={'O'} />
                    <FormControlLabel onChange={handleChangeNegative} control={<Radio checked={!scale.Negative}/>} label={'X'} />
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
            </FormControl>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <NumberForm
                        label='최소값'
                        required={true}
                        error={false}
                        placeholder='최소값을 입력해 주세요.'
                        value={scale.Min}
                        handleChange={handleChangeMin}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='최대값'
                        required={true}
                        error={false}
                        placeholder='최대값을 입력해 주세요.'
                        value={scale.Max}
                        handleChange={handleChangeMax}
                    />
                </Grid>
            </Grid>
            <NumberForm
                label='기준값'
                required={true}
                error={false}
                placeholder='기준값을 입력해 주세요.'
                value={scale.Standard}
                handleChange={handleChangeStandard}
            />
            <NumberForm
                label='RCI'
                required={true}
                error={false}
                placeholder='RCI값을 입력해 주세요.'
                value={scale.RCI}
                handleChange={handleChangeRCI}
                minus={true}
            />
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    하위 요인
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClickMinus} disabled={scale.SubScale.length < 2}>
                        <Remove/>
                    </IconButton>
                    <IconButton onClick={handleClickPlus}>
                        <Add />
                    </IconButton>
                </Grid>
                {scale.SubScale.map((item, i) => (
                <TextForm
                    key={i}
                    required={false}
                    error={false}
                    value={item}
                    placeholder={`${i+1}번째 하위 요인을 입력해 주세요.`}
                    handleChange={(e) => handleChangeSubScale(e, i)}
                />
                ))}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <NumberForm
                        label='하위요인 최소값'
                        required={true}
                        error={false}
                        placeholder='하위요인의 최소값을 입력해 주세요.'
                        value={scale.SubMin}
                        handleChange={handleChangeSubMin}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='하위요인 최대값'
                        required={true}
                        error={false}
                        placeholder='하위요인의 최대값을 입력해 주세요.'
                        value={scale.SubMax}
                        handleChange={handleChangeSubMax}
                    />
                </Grid>
            </Grid>
            <TextForm
                label='내용'
                required={false}
                error={false}
                value={scale.Content}
                handleChange={handleChangeContent}
                rows={6}
            />
            <TextForm
                label='지시문'
                required={false}
                error={false}
                value={scale.Detail}
                handleChange={handleChangeDetail}
                rows={6}
            />
            <Grid container spacing={1} justifyContent={'flex-end'}>
                <Grid item xs={6} md={4}>
                    <LargeCancelButton label='취소' handleClick={() => navigate(-1)}/>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LargeSubmitButton 
                        label='수정'
                        handleClick={props.handleClickUpdateBtn}
                        disabled={disabledUpdateBtn}
                        loading={props.updating}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    );
};

export default ScaleUpdateView;