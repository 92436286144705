import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Pagination, Button } from '@mui/material';
import { ArrowUpward, DeleteOutlineOutlined, CreateOutlined } from '@mui/icons-material';
import ICONLABEL from 'images/icon_label.png';

const DataTable = (props) => {
    const tableTop = window.document.querySelector("#table_top")?.offsetTop;
    const [turn, setTurn] = useState(180);
    const [sort, setSort] = useState(0);
    const [page, setPage] = useState(1);
    const [top, setTop] = useState(0);

    const handleClickSort = (key) => {
        if(props.keys[key].label){
            if(sort === key){
                turn === 180 ?
                setTurn(0) :
                setTurn(180)
            }else{
                setTurn(0);
                setSort(key);
            }
        }else{
            return false;
        }
    };

    const filterDatas = (datas) => {
        if(datas){
            return datas.filter((item) => {
                let returnData = false;
                for(let i = 0; i < props.keys.length; i++){
                    if(item[`${props.keys[i].key}`]?.toString().indexOf(props.search) > -1){
                        returnData = true;
                        break;                    
                    }
                }
                return returnData;
            });
        }else{
            return [];
        }  
    };

    const sortDatas = (datas) => {
        const key = props.keys[sort].key;
        const regex = /^(-){0,1}[0-9]+$/;
        let num = true;
        let per = true;
        if(sort !== 0){
            for(let i = 0; i < datas.length; i++){
                if(datas[i][key]){
                    if(!regex.test(datas[i][key])){
                        num = false;
                        break;
                    }
                }
            }
            for(let i = 0; i < datas.length; i++){
                if(datas[i][key]){
                    if(!datas[i][key].toString().includes('%')){
                        per = false;
                        break;
                    }
                }
            }
        }

        if(turn === 180){
            return datas.sort((a, b) => {
                if( sort === 0 ) {
                    return b[key] - a[key];
                }else if(num){
                    return b[key] !== undefined ? Number(b[key]) - Number(a[key]) : -1;
                }else if(per){
                    return b[key] !== undefined ? Number(b[key].replace('%', '')) - Number(a[key]?.replace('%', '')) : -1;
                }else {
                    // return (String(b[key]).localeCompare(String(a[key])));
                    const valueOfKeyA = b[key]?.toString()?.toUpperCase();
                    const valueOfKeyB = a[key]?.toString()?.toUpperCase();
                    return valueOfKeyA?.localeCompare(valueOfKeyB, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            });
        }else{
            return datas.sort((a, b) => {
                if(sort === 0) {
                    return a[key] - b[key];
                }else if(num){
                    return a[key] !== undefined ? Number(a[key]) - Number(b[key]) : -1;
                }else if(per){
                    return a[key] !== undefined ? Number(a[key].replace('%', '')) - Number(b[key]?.replace('%', '')) : -1;
                } else {
                    // return (String(a[key]).localeCompare(String(b[key])));
                    const valueOfKeyA = a[key]?.toString()?.toUpperCase();
                    const valueOfKeyB = b[key]?.toString()?.toUpperCase();
                    return valueOfKeyA?.localeCompare(valueOfKeyB, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
            });
        }
    };

    useEffect(() => {
        setPage(1);
    }, [props.search]);

    useEffect(() => {
        setTop(tableTop - 220);
    }, [tableTop]);

    useEffect(() => {
        setTurn(180);
        setSort(0);
        setPage(1);
    }, [props.datas]);

    return (
        <div className='table_container' id='table_top'>
            <table className='data_table'>
                <thead>
                    <tr>
                        {props.keys.map((item, i) => (
                        <th onClick={() => handleClickSort(i)} key={i}>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    {item.key === 'RCIIcon' ?
                                    <span style={{ position: 'relative' }}><img src={ICONLABEL} style={{ position: 'absolute', width: '16px', height: '16px', top: '0' }} alt='icon_label'/>&nbsp;&nbsp;</span>:
                                    <span>{item.label}&nbsp;&nbsp;</span>
                                    }
                                    {sort === i ?
                                    <span>
                                        <b style={{ color: '#5E5E5E' }}>{turn === 0 ? '↑': '↓'}</b>
                                    </span>
                                    :null}
                                </Grid>
                                {/* <Grid item style={{ width: '20px', height: '20px' }}>
                                    {sort === i ?
                                    <ArrowUpward
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            transform: `rotate(${turn}deg)`,
                                            transition: 'all ease 0.25s',
                                            color: '#5E5E5E'
                                        }}
                                    />:null}
                                </Grid> */}
                            </Grid>
                        </th>
                        ))}
                        {props.delete ? <th></th>: null}
                        {props.update ? <th></th>: null}
                        {props.button ? <th></th>: null}
                    </tr>
                </thead>
                <tbody>
                    {filterDatas(props.datas).length > 0 ?
                    sortDatas(filterDatas(props.datas)).map((data, i) => (
                    i >= (page - 1) * 20 && i < page * 20 ?
                    <tr key={i}>
                        {props.keys.map((item, j) => (
                            <td key={j} className='td_onclick' onClick={() => props.handleClickDetail(data)} style={{ borderLeft: j !== 0 && item.borderLeft ? '1px solid #C6E7CF' : '' }}>
                                {data[`${item.key}`]?.toString().indexOf('<small>') > -1 ? <div dangerouslySetInnerHTML={{ __html: data[`${item.key}`] }}/> : data[`${item.key}`]}
                            </td>
                        ))}
                        {props.delete ?
                        <td style={{ textAlign: 'center' }}>
                            <IconButton onClick={() => props.handleClickDelete(data)} size='small' sx={{ padding: 0 }}>
                                <DeleteOutlineOutlined />
                            </IconButton>
                        </td>:null}
                        {props.update ?
                        <td style={{ textAlign: 'center' }}>
                            <IconButton onClick={() => props.handleClickUpdate(data)} size='small' sx={{ padding: 0 }}>
                                <CreateOutlined />
                            </IconButton>
                        </td> : null}
                        {props.button ?
                        <td>
                            <Button variant='outlined' size='small' onClick={() => props.handleClickButton(data)}>{props.buttonLabel}</Button>
                        </td> : null}
                    </tr> : null
                    )) :
                    <tr>
                        <td colSpan={props.keys.length + (props.delete ? 1 : 0) + (props.update ? 1 : 0) + (props.button ? 1 : 0)} style={{ textAlign: 'center' }}>
                            일치하는 정보가 없습니다.
                        </td>
                    </tr>}
                    <tr>
                        <td colSpan={props.keys.length + (props.delete ? 1 : 0) + (props.update ? 1 : 0) + (props.button ? 1 : 0)} style={{ textAlign: 'center', borderBottom: '2px solid #C6E7CF' }}>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                    <Pagination
                                        count={Math.ceil(filterDatas(props.datas).length/20) ? Math.ceil(filterDatas(props.datas).length/20) : 1}
                                        color='primary'
                                        size='small'
                                        page={page}
                                        onChange={(_, v) => {setPage(v);window.scrollTo(0, top)}}
                                    />
                                </Grid>
                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;