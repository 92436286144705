import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControlLabel, FormGroup, Checkbox } from '@mui/material';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { DataTable } from 'components/Items/Table';
import { SmallSubmitButton } from 'components/Items/Button';

const SuperviseeListView = (props) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState([true, false]);

    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'CounselorName', label: '상담자' },
        { key: 'Name_decrypt', label: '내담자' },
        { key: 'NickName', label: '내담자ID' },
        { key: 'StateValue', label: '현황' },
        { key: 'LastVisit', label: '최근 일정' },
        { key: 'NextVisit', label: '다음 일정' },
        { key: 'VisitCount', label: '방문' },
        { key: 'PrimaryScale', label: '주요 검사', borderLeft: true },
        { key: 'LastPoint', label: '마지막' },
        { key: 'Indiff', label: '증감' },
        { key: 'Increase', label: '증감률' },
        { key: 'RCIPer', label: 'RCI%' },
        { key: 'RCIIcon', label: '' },
        { key: 'GroupName', label: '그룹' }
    ];
    const { handleClickDownload } = props;

    const handleClickDetail = (data) => {
        navigate(`/manage/supervisee/${data.ClientNo}`);
    };

    const filterDatas = (datas) => {
        if(checked[0] && checked[1]){
            return datas;
        }else if(checked[0]){
            return datas.filter((item) => (item.State !== 8));
        }else if(checked[1]){
            return datas.filter((item) => (item.State === 8));
        }else{
            return datas;
        }
    };

    const handleClickChecked = (e, i) => {
        let changeChecked = [...checked];
        changeChecked[i] = e.target.checked;
        if(changeChecked[0] && changeChecked[1]){
            setCheckedAll(true);
        }
        if(!changeChecked[0] || !changeChecked[1]){
            setCheckedAll(false);
        }
        setChecked(changeChecked);
    };
    
    const handleClickCheckedAll = (e) => {
        setCheckedAll(e.target.checked);
        setChecked([e.target.checked, e.target.checked]);
    };

    return (
        <>
        <ListContainer title='상담자(수퍼바이지) 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={3} lg={4} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={9} lg={8} justifyContent={'flex-end'} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    <Grid item sm={6} md={7} sx={{ pl: 3 }}>
                        <FormGroup row>
                            <FormControlLabel control={<Checkbox checked={checkedAll} onChange={(e) => handleClickCheckedAll(e)}/>} label='전체' />
                            <FormControlLabel control={<Checkbox checked={checked[0]} onChange={(e) => handleClickChecked(e, 0)}/>} label='진행중' />
                            <FormControlLabel control={<Checkbox checked={checked[1]} onChange={(e) => handleClickChecked(e, 1)} />} label='종결' />
                        </FormGroup>
                    </Grid>
                    <Grid item sm={6} md={5} textAlign={'right'}>
                        <SmallSubmitButton label='다운로드' handleClick={handleClickDownload} loading={props.loading} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={filterDatas(props.clients)}
                    delete={false}
                    update={false}
                    search={search}
                    handleClickDetail={handleClickDetail}
                />
            </Grid>
        </ListContainer>
        </>
    );
};

export default SuperviseeListView;