import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ClientList, ClientDetail, ClientReport, ClientChart, ClientUpdate } from 'domains/User/Client';
import NotFound from 'routes';

const ClientRoute = () => {
    return (
        <Routes>
            <Route path='' element={<ClientList />} />
            <Route path='report' element={<ClientReport />} />
            <Route path='chart' element={<ClientChart />} />
            <Route path=':clientNo' element={<ClientDetail />} />
            {/* <Route path=':clientNo/update' element={<ClientUpdate />} /> */}
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default ClientRoute;