import React from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TaskAltOutlined, ReportProblemOutlined, InfoOutlined } from '@mui/icons-material';
import { SmallCancelButton, SmallSubmitButton } from '../Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
/**
 * 
 * @param {boolean} open
 * @param {function} handleClose
 * @param {string} submitLabel
 * @param {string} icon
 */
const ConfirmModal = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth='sm'
                scroll='body'
            >
                <DialogTitle style={{ padding: '24px 24px 0px 24px' }}>
                    <Grid container alignItems={'center'} style={{ fontSize: '120%', marginBottom: '16px', fontWeight: 'bold' }}>
                        <Grid item style={{ lineHeight: '100%', width: '25px', marginRight: '8px' }}>
                            {props.icon === 'success' ?
                                <TaskAltOutlined color='success'/> :
                                props.icon === 'error' ? 
                                    <ReportProblemOutlined color='error' /> :
                                    <InfoOutlined color='info' />
                            }
                        </Grid>
                        <Grid item>
                            {props.title}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: '0px 24px', marginBottom: '16px' }}>
                    <Grid style={{ paddingLeft: '33px' }}>
                        {props.children}
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
                    <SmallCancelButton
                        handleClick={props.handleClose}
                        label={'취소'}
                    />
                    <SmallSubmitButton
                        handleClick={props.handleClickSubmit}
                        label={props.submitLabel}
                        disabled={props.disabled}
                        loading={props.loading}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmModal;