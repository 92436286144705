import React, { useCallback, useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { DashboardLayout } from 'components/Layout';
import { MemberDetailView } from 'components/User/Manage/Member';
import { CalcAge, GenderKo } from 'components/Function/ChangeText';

const MemberDetailContainer = (props) => {
    const pages = [
        { name: '직원 관리', link: '/manage/member' },
        { name: '직원 상세', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { memberNo } = useParams();
    const [cookies, , removeCookies] = useCookies(['LoginKey']);
    const [member, setMember] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Status: '',
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Region: [], // 활동 지역
        License: [], // 자격증
        Degree: '',
        UniversityStatus: '',
        University: '',
        Major: ''
    });
    const [clients, setClients] = useState([{
        ClientNo: '',
        NickName: '',
        Name_decrypt: '',
        AgeValue: '',
        Birth: '',
        GenderValue: '',
        Phone_decrypt: '',
        Email_decrypt: ''
    }]);
    const [loading, setLoading] = useState(false);

    const handleClickPasswordReset = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.patch(`/api/manager/employee/${memberNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'success' });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickTransfer = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(member);
        await axios.put(`/api/manager/employee/transfer/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    removeCookies('LoginKey', { path: '/' });
                    removeCookies('UserNo', { path: '/' });
                    removeCookies('Name', { path: '/' });
                    removeCookies('Status', { path: '/' });
                    navigate(`/login`);
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [2000]);
            }else{
                setLoading(false);
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/employee/${memberNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/employee/${memberNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                let loadMember = { ...res.data.Worker };
                loadMember.Available = loadMember.Available.length ? loadMember.Available : [[], [], [], [], [], [], []];
                loadMember.AvailableDay = [];
                for(let i = 0; i < loadMember.Available.length; i++){
                    if(loadMember.Available[i].length){
                        loadMember.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadMember.Available[i].length; j++){
                            loadMember.Available[i][j] = loadMember.Available[i][j].toString();
                        }
                    }
                }
                setMember(loadMember);
                if(res.data.Worker.Status === 1 || res.data.Worker.Status === 2){
                    let loadClients = [...res.data.ClientDatas];
                    for(let i = 0; i < loadClients.length; i++){
                        loadClients[i].No = i + 1;
                        loadClients[i].AgeValue = CalcAge(loadClients[i].Birth);
                        loadClients[i].GenderValue = GenderKo(loadClients[i].Gender);
                    }
                    setClients(res.data.ClientDatas);
                }
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [memberNo, enqueueSnackbar, cookies.LoginKey, setMember, setClients]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    return (
        <DashboardLayout pages={pages} status={props.status}>
            <MemberDetailView 
                member={member}
                clients={clients}
                handleClickDeleteBtn={handleClickDeleteBtn}
                handleClickPasswordReset={handleClickPasswordReset}
                handleClickTransfer={handleClickTransfer}
                loading={loading}
            />
        </DashboardLayout>
    );
};

export default MemberDetailContainer;