import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailContainer, ListContainer } from 'components/Items/Container';
import { DataBox } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';
import { ItemShortForm, ItemMultipleChoice, ItemLikert } from 'components/Items/View';
import { ScaleKind, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const ScaleDetailView = (props) => {
    const navigate = useNavigate();
    const { scaleNo } = useParams();
    const [openScaleDeleteModal, setOpenScaleDeleteModal] = useState(false);
    const [openItemDeleteModal, setOpenItemDeleteModal] = useState(false);

    const handleClickDelete = (data) => {
        props.setSelectItem(data)
        setOpenItemDeleteModal(true);
    };
    
    return (
        <>
        <DetailContainer
            title='검사 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenScaleDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/admin/scale/${scaleNo}/update`) } ]}
            switch={{ label: props.scale.State === 1 ? <p style={{ width: '45px', fontWeight: 'bold', margin: 0, textAlign: 'right' }}>출시</p> : <p style={{ color: '#5E5E5E', width: '45px', fontWeight: 'normal', margin: 0, textAlign: 'right' }}>미출시</p>, value: props.scale.State === 1 ? true : false, handleChange: props.handleChangeState }}
        >
            <DataBox
                datas={[
                    { key: '이름', value: props.scale.Label },
                    { key: '검사 종류', value: ScaleKind(props.scale.ScaleKind) },
                    { key: '점수 계산', value: props.scale.CalScore ? 'O' : 'X' },
                    { key: '역산', value: props.scale.Negative ? 'O' : 'X' },
                    { key: '최소값', value: props.scale.Min },
                    { key: '최대값', value: props.scale.Max },
                    { key: '기준값', value: props.scale.Standard },
                    { key: 'RCI', value: props.scale.RCI },
                    { key: '하위요인 최소값', value: props.scale.SubMin },
                    { key: '하위요인 최대값', value: props.scale.SubMax },
                    { key: '생성 일시', value: YyyyMmDdHhMmSs(props.scale.createdAt) }
                ]}
            />
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            하위 요인
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                padding: '4px 16px'
                            }}
                        >
                            {props.scale.SubScale?.map((item, i) => (
                                <div key={i}>{item}</div>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            내용
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{ padding: '4px 16px' }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: props.scale.Content?.replaceAll(`\n`, '<br/>') }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            지시문
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{ padding: '4px 16px' }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: props.scale.Detail?.replaceAll(`\n`, '<br/>') }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DetailContainer>
        <ListContainer
            title='문항 목록'
            button={{ label: '문항 추가', handleClick: () => navigate(`/admin/scale/${scaleNo}/create`) }}
        >
            <Grid container style={{ marginBottom: '8px'}}>
                <Grid item xs={12}>
                    {/* 하위요인순으로 출력 */}
                    {/* {props.scale.Item_ids?.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => ( */}
                    {/* 입력순으로 출력 */}
                    {props.scale.Item_ids?.map((q, j) => (
                    <Grid key={j} style={{ marginBottom: '8px' }}>
                        {q.ItemKind === 1 ?
                        <ItemShortForm 
                            item={q} no={j+1} scale={props.scale}
                            // subScale={q.SubScale && (j === 0 || q.SubScale !== props.scale.Item_ids[j-1].SubScale)}
                            lastItem={props.scale.Item_ids.length === j+1} delete={() => handleClickDelete(q)} update={() => navigate(`/admin/scale/${props.scale.ScaleNo}/${q.ItemNo}/update`)}/> :
                        q.ItemKind === 2 ?
                        <ItemMultipleChoice 
                            item={q} no={j+1} scale={props.scale}
                            // subScale={q.SubScale && (j === 0 || q.SubScale !== props.scale.Item_ids[j-1].SubScale)}
                            lastItem={props.scale.Item_ids.length === j+1} delete={() => handleClickDelete(q)} update={() => navigate(`/admin/scale/${props.scale.ScaleNo}/${q.ItemNo}/update`)}/> :
                        q.ItemKind === 3 ?
                        <ItemLikert 
                            item={q} no={j+1} scale={props.scale} 
                            // subScale={q.SubScale && (j === 0 || q.SubScale !== props.scale.Item_ids[j-1].SubScale)} 
                            lastItem={props.scale.Item_ids.length === j+1} delete={() => handleClickDelete(q)} update={() => navigate(`/admin/scale/${props.scale.ScaleNo}/${q.ItemNo}/update`)}/> :
                        null}
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </ListContainer>
        <ConfirmModal
            title='검사 삭제'
            icon='error'
            open={openScaleDeleteModal}
            handleClose={() => setOpenScaleDeleteModal(false)}
            handleClickSubmit={() => props.handleClickScaleDeleteBtn()}
            submitLabel='삭제'
        >
            검사 정보를 삭제 하시겠습니까? 검사 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        {props.selectItem.ItemNo ?
        <ConfirmModal
            title='문항 삭제'
            icon='error'
            open={openItemDeleteModal}
            handleClose={() => setOpenItemDeleteModal(false)}
            handleClickSubmit={() => {setOpenItemDeleteModal(false);props.handleClickItemDeleteBtn();}}
            submitLabel='삭제'
        >
            문항 정보를 삭제 하시겠습니까? 문항 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default ScaleDetailView;