import React, { useState } from 'react';
import { Grid, Box, Tabs, Tab, IconButton, CircularProgress } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import { DetailContainer, ListContainer } from 'components/Items/Container';
import { DataBox } from 'components/Items/Table';
import { ChartView, SessionView, GoalView, ProfileView, SummaryView, BasicView } from './Session';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';
import { GenderKo, NationalityKo, MarriageKo, MilitaryKo, EmploymentKo, LivingKo } from 'components/Function/ChangeText';
import { TooltipButton } from 'components/Items/Button';

const ClientDetailView = (props) => {
    const datas = [
        { key: '이름', value: props.client.Name },
        { key: '내담자ID', value: props.client.NickName },
        { key: '이메일', value: props.client.Email },
        { key: '연락처', value: props.client.Phone },
        { key: '비고', value: props.client.Memo, long: true },
        { key: '현 주소', value: props.client.Address },
        { key: '생년월일', value: props.client.Birth },
        { key: '성별', value: props.client.Gender !== 3 ? GenderKo(props.client.Gender) : props.client.GenderDetail },
        { key: '국적', value: props.client.Nationality !== 9 ? NationalityKo(props.client.Nationality) : props.client.NationalityDetail },
        { key: '종교', value: props.client.Religion },
        { key: '결혼유무', value: props.client.Marriage !== 6 ? MarriageKo(props.client.Marriage) : props.client.MarriageDetail },
        { key: '병역', value: MilitaryKo(props.client.Military) },
        { key: '최종학력', value: props.client.Education },
        { key: '직업', value: props.client.Employment !== 9 || !props.client.EmploymentDetail ? EmploymentKo(props.client.Employment) : props.client.EmploymentDetail },
        { key: '주거 형태', value: props.client.Living !== 6 ? LivingKo(props.client.Living) :props.client.LivingDetail },
        { key: '부양가족 수', value: props.client.Dependents || props.client.Dependents === 0 ? `${props.client.Dependents} 명` : '' },
        { key: '그룹', value: props.client.Group ? props.client.Group.Name : '' },
        { key: '상담자', value: props.client.Counselor_id ? props.client.Counselor_id.Name : '' },
        { key: '신청일', value: props.client.CreatedAtValue }
    ];

    const [tab, setTab] = useState(2);

    const handleClickRefresh = () => {
        props.setLoading(true);
        props.loadData();
    };

    return (
        <>
        <ListContainer>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}>
                        {/* <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)} scrollButtons={true} variant='scrollable' allowScrollButtonsMobile> */}
                        <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)}>
                            <Tab label='프로필' tabIndex={0} sx={{ fontSize: '110%' }} />
                            <Tab label='기본서류' tabIndex={1} sx={{ fontSize: '110%' }} />
                            <Tab label='상담(ROM)' tabIndex={2} sx={{ fontSize: '110%' }} />
                            {/* <Tab label='목표' tabIndex={3}/> */}
                            <Tab label='차트' tabIndex={3} sx={{ fontSize: '110%' }} />
                            <Tab label='요약' tabIndex={4} sx={{ fontSize: '110%' }} />
                            <Grid style={{ position: 'absolute', right: '0', bottom: '0' }}>
                                <TooltipButton tooltip={'내담자의 검사응답 여부(응답:회색) 및 검사결과를 바로 반영하기 위해서 새로고침을 합니다'}/>
                                {props.loading ?
                                <IconButton style={{ transform: 'translate(0, -5px)' }} disabled={props.loading}>
                                    <CircularProgress size={24} />
                                </IconButton>:
                                <IconButton style={{ transform: 'translate(0, -5px)' }} onClick={handleClickRefresh}>
                                    <Refresh />
                                </IconButton>}
                            </Grid>
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                    tab === 0 ?
                    <ProfileView 
                        client={props.client} 
                        setClient={props.setClient} 
                        handleClickStateBtn={props.handleClickStateBtn}
                        scales={props.scales}
                        clientScales={props.clientScales}
                        setClientScales={props.setClientScales}
                        handleClickScaleBtn={props.handleClickScaleBtn}
                        handleClickDeleteScaleBtn={props.handleClickDeleteScaleBtn}
                        datas={datas}
                    /> :
                    tab === 1 ?
                    <BasicView 
                        client={props.client}
                        setClient={props.setClient}
                        basicScale={props.client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3))}
                        scales={props.scales.filter((item) => (item.ScaleKind === 3))}
                        handleClickBasicBtn={props.handleClickBasicBtn}
                        handleClickBasicDeleteBtn={props.handleClickBasicDeleteBtn}
                        handleClickUrlSendBtn={props.handleClickUrlSendBtn}
                        send={props.send}
                        setSend={props.setSend}
                        handleClickReceipt={props.handleClickReceipt}
                        handleClickReference={props.handleClickReference}
                        handleClickReport={props.handleClickReport}
                        handleClickRequest={props.handleClickRequest}
                        handleClickETC={props.handleClickETC}
                    /> :
                    tab === 2 ? 
                    <SessionView 
                        client={props.client}
                        setClient={props.setClient}
                        selectSession={props.selectSession}
                        session={props.session}
                        rooms={props.rooms}
                        setSelectSession={props.setSelectSession}
                        setRooms={props.setRooms}
                        setSession={props.setSession}
                        handleClickSessionSave={props.handleClickSessionSave}
                        handleClickCreateBtn={props.handleClickCreateBtn}
                        handleClickUpdateBtn={props.handleClickUpdateBtn}
                        handleClickDeleteBtn={props.handleClickDeleteBtn}
                        handleClickUrlSendBtn={props.handleClickUrlSendBtn}
                        send={props.send}
                        setSend={props.setSend}
                        user={props.user}
                    /> :
                    // tab === 3 ?
                    // <GoalView 
                    //     goal={props.goal}
                    //     setGoal={props.setGoal}
                    //     goals={props.goals}
                    //     setGoals={props.setGoals}
                    //     selectGoal={props.selectGoal}
                    //     setSelectGoal={props.setSelectGoal}
                    //     handleClickCreateGoalBtn={props.handleClickCreateGoalBtn}
                    //     handleClickUpdateGoalBtn={props.handleClickUpdateGoalBtn}
                    //     handleClickDeleteGoalBtn={props.handleClickDeleteGoalBtn}
                    //     handleClickGoalPointsBtn={props.handleClickGoalPointsBtn}
                    //     point={props.point}
                    //     setPoint={props.setPoint}
                    //     client={props.client}
                    // /> :
                    tab === 3 ?
                    <ChartView 
                        client={props.client}
                        goals={props.goals}
                    /> :
                    <SummaryView 
                        goals={props.goals}
                        client={props.client}
                    />
                    } 
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default ClientDetailView;