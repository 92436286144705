import React, { useState } from 'react';
import { Grid, FormControl, OutlinedInput, FormHelperText } from '@mui/material';

/**
 * 
 * @param {string} number
 * @param {function(): string} handleChange
 * @param {string} placeholder
 * @param {boolean} required
 * @param {boolean} error
 * @param {string} label
 * @param {number} rows
 * @description 
 */
const NumberForm = (props) => {
    const [numberError, setNumberError] = useState(false);
    const handleChangeNumber = (e) => {
        if(e.target.value < 0 && !props.minus){
            setNumberError(true);
        }else{
            setNumberError(false);
            props.handleChange(e);
        }
    };
    return (
        <>
            <FormControl variant='outlined' className='form_default' fullWidth={true} error={props.error}>
                {props.label ?
                    <Grid style={{ marginBottom: '8px' }}>
                        {props.label} {props.required ? <span style={{ color: '#D3A7FF' }}>*</span> : null}
                    </Grid> :
                    null
                }
                <OutlinedInput
                    type={'number'}
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={handleChangeNumber}
                    rows={props.rows ? props.rows : 1}
                    multiline={props.rows}
                />
                {/* {props.error ? <FormHelperText style={{ marginLeft: 0 }}>Error</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>} */}
                {numberError ? <FormHelperText style={{ marginLeft: 0, color: 'red' }}>숫자는 0보다 작을 수 없습니다.</FormHelperText> : <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>}
            </FormControl>
        </>
    );
};

export default NumberForm;