import React, { useEffect, useState } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { SearchForm } from 'components/Items/Form';
import { DataTableCollapsible } from 'components/Items/Table';
import { DetailModal } from 'components/Items/Modal';
import { RiskKo } from 'components/Function/ChangeText';

const SummaryView = (props) => { 
    const { goals, client } = props;
    const [search1, setSearch1] = useState('');
    const [search2, setSearch2] = useState('');
    const [selectGoal, setSelectGoal] = useState({});
    const [selectSession, setSelectSession] = useState({});
    const [openSessionDetailModal, setOpenSessionDetailModal] = useState(false);
    const [openGoalDetailModal, setOpenGoalDetailModal] = useState(false);
    const [detail, setDetail] = useState(false);
    const handleClickSessionDetail = (data) => {
        setSelectSession(data);
        setOpenSessionDetailModal(true);
    };
    const handleClickGoalDetail = (data) => {
        setSelectGoal(data);
        setOpenGoalDetailModal(true);
    };
    let keys = [
        { key: 'No', label: 'No.' },
        { key: 'StartDateValue', label: '상담 일시'},
        { key: 'KindDetailValue', label: '상담 종류'}
    ];
    for(let i = 0; i < client.Scales.length; i++){
        if(!client.Scales[i].Basic){
            keys.push({ key: `${client.Scales[i].Scale_id._id}`, label: `${client.Scales[i].Scale_id.Label}` });
            for(let j = 0; j < client.Scales[i].Scale_id.SubScale.length; j++){
                keys.push({ key: `${client.Scales[i].Scale_id._id}${j}`, label: `${client.Scales[i].Scale_id.SubScale[j]}` });
            }
        }   
    }
    
    const keys2 = [{ key: 'No', label: 'No.'},
                        { key: 'Label', label: '제목'},
                        { key: 'ActiveValue', label: '모니터 여부'},
                        { key: 'SetDate', label: '설정 시기' },
                        { key: 'PeriodValue', label: '고민기간' },
                        { key: 'StartEnd', label: '시작 - 마지막' }];

    
    return (
        <>
        <Grid container style={{ marginBottom: '32px' }}>
            <Grid item xs={12}>
                <h3>검사 요약</h3>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: '16px' }}>
                <SearchForm
                    value={search1}
                    handleChange={(e) => setSearch1(e.target.value)}
                />
            </Grid>
            <Grid item xs={4} md={6} style={{ marginBottom: '16px' }}>
                <FormGroup>
                    <FormControlLabel onChange={() => setDetail(!detail)} control={<Checkbox checked={detail} />} label='상세보기' style={{ marginLeft: 'auto' }} />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <DataTableCollapsible
                    keys={keys}
                    datas={client.Schedule_ids}
                    search={search1}
                    update={false}
                    delete={false}
                    handleClickDetail={handleClickSessionDetail}
                    detail={detail}
                    defaultSort={[180, 1]}
                />
            </Grid>
        </Grid>
        {/* <Grid container>
            <Grid item xs={12}>
                <h3>목표 요약</h3>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                <SearchForm
                    value={search2}
                    handleChange={(e) => setSearch2(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    keys={keys2}
                    datas={goals}
                    search={search2}
                    update={false}
                    delete={false}
                    handleClickDetail={handleClickGoalDetail}
                />
            </Grid>
        </Grid> */}
        {selectSession.ScheduleNo ?
        <DetailModal
            title='상담 상세 보기'
            open={openSessionDetailModal}
            handleClose={() => setOpenSessionDetailModal(false)}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <p>상담 일시</p>
                    <div className='div_data_value'>{selectSession.StartDateValue} ~ {selectSession.EndTime}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>상담실</p>
                    <div className='div_data_value'>{selectSession.Room_id ? selectSession.Room_id.Name : 'ㅤ'}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>상담 종류</p>
                    <div className='div_data_value'>{selectSession.KindDetailValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>상담 방식</p>
                    <div className='div_data_value'>{selectSession.ServiceValue}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>메모</p>
                    <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectSession.Memo ? selectSession.Memo.replaceAll('\n', '<br/>') : 'ㅤ' }}/>
                </Grid>
                <Grid item xs={12}>
                    <p>상담 내용</p>
                    <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectSession.Note ? selectSession.Note.replaceAll('\n', '<br/>') : 'ㅤ' }}/>
                </Grid>
                <Grid item xs={6}>
                    <p>기능 평가</p>
                    <div className='div_data_value'>{selectSession.GAF ? selectSession.GAF : 'ㅤ'}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>위험 수준</p>
                    <div className='div_data_value'>{RiskKo(selectSession.Risk)}</div>
                </Grid>
                {client.Scales.map((item, i) => (
                <Grid item xs={6} key={i}>
                    <p>{item.Scale_id.Label}</p>
                    <div className='div_data_value'>{selectSession[item.Scale_id._id] !== undefined ? selectSession[item.Scale_id._id] : 'ㅤ'}</div>
                </Grid>
                ))}
            </Grid>
        </DetailModal>:null}
        {selectGoal.GoalNo ?
        <DetailModal
            title='목표 상세 보기'
            open={openGoalDetailModal}
            handleClose={() => setOpenGoalDetailModal(false)}
        >
            <Grid container>
                <Grid item xs={12}>
                    <p>제목</p>
                    <div className='div_data_value'>{selectGoal.Label}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>모니터 여부</p>
                    <div className='div_data_value'>{selectGoal.ActiveValue}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>시작 - 마지막</p>
                    <div className='div_data_value'>{selectGoal.StartEnd}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>설정 시기</p>
                    <div className='div_data_value'>{selectGoal.SetDate}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>고민 기간</p>
                    <div className='div_data_value'>{selectGoal.PeriodValue}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>내용</p>
                    <div 
                        className='div_data_value'
                        dangerouslySetInnerHTML={{ 
                            __html: selectGoal.Detail ? selectGoal.Detail.replaceAll('\n', '<br/>') : 'ㅤ'
                        }} 
                    />    
                </Grid>
            </Grid>
        </DetailModal>:null}
        </>
    );
};

export default SummaryView;