import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { LoginLayout } from 'components/Layout';
import { PasswordForm } from 'components/Items/Form';
import { LargeSubmitButton } from 'components/Items/Button';

const RegistPasswordView = (props) => {
    const { user, setUser } = props;
    const [showPassword, setShowPassword] = useState(false);
    return (
        <>
        <LoginLayout>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    minHeight: '100vh',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    position: 'relative',
                    zIndex: '2'
                }}
            >
                <Grid
                    xs={12} sm={10} md={8} lg={6} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '30px',
                        padding: '60px 4%'
                    }}
                >
                    <h1 style={{ textAlign: 'center' }}>비밀번호 생성</h1>
                    <PasswordForm
                        label='비밀번호 생성'
                        required={false}
                        error={false}
                        value={user.ChangePassword}
                        disabled={true}
                        handleChange={(e) => setUser({ ...user, ChangePassword: e.target.value })}
                        showPassword={showPassword}
                        handleClick={() => setShowPassword(!showPassword)}
                    />
                    <PasswordForm
                        label='비밀번호 생성 확인'
                        required={false}
                        error={user.ChangePassword !== user.ConfirmPassword}
                        errorMessage={'비밀번호가 일치하지 않습니다.'}
                        value={user.ConfirmPassword}
                        disabled={true}
                        handleChange={(e) => setUser({ ...user, ConfirmPassword: e.target.value })}
                        showPassword={showPassword}
                        handleClick={() => setShowPassword(!showPassword)}
                    />
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item xs={6} md={4} lg={3}>
                            <LargeSubmitButton
                                label='생성하기'
                                loading={props.loading}
                                handleClick={props.handleClickBtn}
                                disabled={!user.ChangePassword || user.ChangePassword !== user.ConfirmPassword}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LoginLayout>
        </>
    );
};

export default RegistPasswordView;