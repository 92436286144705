import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { Box, Drawer, CssBaseline, AppBar, Toolbar, List, Typography, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Breadcrumbs, Link, IconButton, Avatar, Menu, MenuItem, Hidden, FormControlLabel, Checkbox, FormGroup, Grid } from '@mui/material';
import { Person, Email, CalendarMonth, Badge, People, MeetingRoom, NavigateNext, AccountTree, ManageAccounts, SpatialAudioOff, ConnectWithoutContact, ContactSupport, BorderColor, Assessment } from '@mui/icons-material';

import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import LOGO from 'images/logo.png';
import MessageBadge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import LoginBackground from 'images/LoginBackground.png';

const drawerWidth = 180;

const DashboardLayout = (props) => {
    const { window } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [cookies, , removeCookies] = useCookies(['Name', 'UserNo', 'Status', 'LoginKey']);
    const status = Number(cookies.Status);
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const container = window !== undefined ? () => window().document.body : undefined;
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    // 메시지 숫자 표시
    const [badgeCount, setBadgeCount] = useState(props.setBadgeCount);
    const count = useSelector((state) => state.counter.value);
    const [screenSave, setScreenSave] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // 메시지 눌렀을 때 숫자 변경
    const handleChangeBadgeCount = () => {
        navigate('/message');
        setBadgeCount(0);
    };
    // 메시지 눌렀을 때 숫자 변경

    const handleClickLogout = () => {
        removeCookies('LoginKey', { path: '/' });
        removeCookies('UserNo', { path: '/' });
        removeCookies('Name', { path: '/' });
        removeCookies('Status', { path: '/' });
        navigate(`/login`);
        enqueueSnackbar('성공적으로 로그아웃 되었습니다.', { variant: 'success' });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/message/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let count = 0;
                let loadMessage = res.data.MessageDatas;
                for(let i = 0; i < loadMessage.length; i++){
                    if( !loadMessage[i].Read ) {
                        if( loadMessage[i].Reciver_id.UserNo === Number(cookies.UserNo) && Boolean(loadMessage[i].Deleted.length === 0 ) )
                            count++;
                    };
                }
                setBadgeCount(count);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [status]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas, count]);

    const drawer = <>
                    <Toolbar style={{ padding: '0 16 0 16', textAlign: 'center' }}>
                        <img src={LOGO} alt='logo' style={{ width: '75%', margin: 'auto' }}/>
                    </Toolbar>
                        <Divider />
                        <List>
                            {status === 1 || status === 2 || status === 3 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'client' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/client')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'client' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText primary={'내담자'} style={{ color: pathname.split('/')[2] === 'client' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 1 || status === 2 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'supervisee' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/supervisee')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'supervisee' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <ConnectWithoutContact />
                                    </ListItemIcon>
                                    <ListItemText primary={'상담자'} style={{ color: pathname.split('/')[2] === 'supervisee' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 1 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'member' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/member')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'member' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Badge />
                                    </ListItemIcon>
                                    <ListItemText primary={'직원'} style={{ color: pathname.split('/')[2] === 'member' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 1 ? 
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'team' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/team')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'team' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <AccountTree />
                                    </ListItemIcon>
                                    <ListItemText primary={'팀'} style={{ color: pathname.split('/')[2] === 'team' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 1 || status === 3 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'room' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/room')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'room' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <MeetingRoom />
                                    </ListItemIcon>
                                    <ListItemText primary={'상담실'} style={{ color: pathname.split('/')[2] === 'room' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 1 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'group' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/manage/group')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'group' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <People />
                                    </ListItemIcon>
                                    <ListItemText primary={'그룹'} style={{ color: pathname.split('/')[2] === 'group' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status !== 5 ?
                            <Divider />:null}
                            {status === 1 || status === 2 ?
                            <ListItem style={{ borderRight: pathname.split('/')[1] === 'client' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/client')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[1] === 'client' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText primary={'상담폴더'} style={{ color: pathname.split('/')[1] === 'client' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status !== 5 ?
                            <ListItem style={{ borderRight: pathname.split('/')[1] === 'message' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={handleChangeBadgeCount}>
                                    <ListItemIcon style={{ color: pathname.split('/')[1] === 'message' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Email />
                                    </ListItemIcon>
                                    <MessageBadge badgeContent={badgeCount} color="primary">
                                        <ListItemText primary={'메시지'} style={{ color: pathname.split('/')[1] === 'message' ? '#000' : '#8B8B8B' }}/>
                                    </MessageBadge>
                                </ListItemButton>
                            </ListItem>:null}
                            {status !== 5 ? 
                            <ListItem style={{ borderRight: pathname.split('/')[1] === 'schedule' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/schedule')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[1] === 'schedule' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <CalendarMonth />
                                    </ListItemIcon>
                                    <ListItemText primary={'스케줄'} style={{ color: pathname.split('/')[1] === 'schedule' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            <ListItem style={{ borderRight: pathname.split('/')[1] === 'report' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/report')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[1] === 'report' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Assessment />
                                    </ListItemIcon>
                                    <ListItemText primary={'기록'} style={{ whiteSpace: 'nowrap', color: pathname.split('/')[1] === 'report' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>
                            {status === 5 ?
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'user' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/admin/user')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'user' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText primary={'유저 관리'} style={{ color: pathname.split('/')[2] === 'user' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 5 ? 
                            <ListItem style={{ borderRight: pathname.split('/')[2] === 'scale' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/admin/scale')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[2] === 'scale' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <BorderColor />
                                    </ListItemIcon>
                                    <ListItemText primary={'검사 관리'} style={{ color: pathname.split('/')[2] === 'scale' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                            {status === 5 ? 
                            <ListItem style={{ borderRight: pathname.split('/')[1] === 'admin' && pathname.split('/')[2] === 'contact' ? '4px solid #A1D8A0' : '0px' }}>
                                <ListItemButton onClick={() => navigate('/admin/contact')}>
                                    <ListItemIcon style={{ color: pathname.split('/')[1] === 'admin' && pathname.split('/')[2] === 'contact' ? '#A1D8A0' : '#8B8B8B' }} sx={{minWidth: '45px'}}>
                                        <ContactSupport />
                                    </ListItemIcon>
                                    <ListItemText primary={'문의 관리'} style={{ color: pathname.split('/')[1] === 'admin' && pathname.split('/')[2] === 'contact' ? '#000' : '#8B8B8B' }}/>
                                </ListItemButton>
                            </ListItem>:null}
                        </List>
                    </>;
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` }
                }}
                style={{ boxShadow: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: '#FFF' }}
            >
                <Toolbar style={{ backgroundColor: '#FFF', justifyContent: 'space-between' }}>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Hidden only={['xs', 'sm']}>
                        <Typography variant='h6' noWrap component='div'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNext fontSize='small' />}
                                >
                                    {props.pages.map((page, i) => (
                                    page.link ?
                                    <Link href={page.link} key={i}>
                                        <div dangerouslySetInnerHTML={{ __html: page.name }} />
                                    </Link>:
                                    <Typography key={i}>
                                        <div dangerouslySetInnerHTML={{ __html: page.name }} />
                                    </Typography>
                                    ))}
                                </Breadcrumbs>
                            </Stack>
                        </Typography>
                    </Hidden>
                    <Grid>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={screenSave} onChange={() => setScreenSave(!screenSave)} />} label='화면보호' />
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                <Typography noWrap component='div'
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <IconButton>
                                        <Avatar sx={{ bgcolor: '#A1D8A0' }}>
                                            {cookies.Status === '1' ?
                                                <ManageAccounts />:
                                                cookies.Status === '2' ?
                                                    <SpatialAudioOff />:
                                                    cookies.Status === '3' ?
                                                    <Person/>:
                                                    <ManageAccounts />
                                            }
                                        </Avatar>
                                    </IconButton>
                                    <span className='avatar_right_name'>
                                        {cookies.Name}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant='permanent'
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                }}
                style={{ width: '180px' }}
                open
                >
                {drawer}
            </Drawer>
            <Box
                component='main'
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: screenSave ? 0 : 3, overflowY: screenSave ? 'hidden' : 'auto' }}
            >
                <Toolbar />
                {screenSave ? 
                <Grid style={{ backgroundColor: '#FFF', width: '100%', height: '100%', backgroundImage: `url('${LoginBackground}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item style={{ fontSize: '1rem', color: '#FFF', lineHeight: '1.5' }}>True growth emerges when we listen not only to our own voice,</Grid>
                    <Grid item style={{ fontSize: '1rem', color: '#FFF', lineHeight: '1.5' }}>but also to the feedback that reflects the perspective of others.</Grid>
                    <Grid item style={{ fontSize: '1rem', color: '#FFF', lineHeight: '1.5' }}>By understanding each other, we can find the way forward.</Grid>
                </Grid> : 
                props.children}
            </Box>
            <Menu
                id='account-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {status !== 5 ?
                <MenuItem onClick={() => navigate(`/user/update`)}>
                    개인정보 수정
                </MenuItem>:null}
                {status !== 5 ? 
                <MenuItem onClick={() => navigate(`/user/contact`)}>
                    문의하기
                </MenuItem>:null}
                <MenuItem onClick={handleClickLogout}>
                    로그아웃
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default DashboardLayout;