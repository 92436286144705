import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';

const TooltipButton = (props) => {
    const [open, setOpen] = useState(false);
    return(
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.tooltip.replaceAll('\n', '<br/>')}}/>} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} onClick={() => setOpen(true)}>
            <Help color='secondary' fontSize={props.size ? props.size : 'small'} sx={{ pt: 0.5 }}/>
        </Tooltip>
    );
};

export default TooltipButton;