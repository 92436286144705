import React, { useState, useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Divider, IconButton, Collapse, Radio, RadioGroup } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DateForm, NumberForm, SearchForm, SelectForm, TimeForm, TextForm } from 'components/Items/Form';
import { DataBox, DataTableButton } from 'components/Items/Table';
import { useParams } from 'react-router-dom';

import { SmallCancelButton, SmallSubmitButton } from 'components/Items/Button';
import { FormModal, ConfirmModal } from 'components/Items/Modal';
import { RepeatDay, SessionKind, Service, RepeatCount, Risk, SesstionState } from 'components/Function/AddDatas';
import { ItemLikert, ItemMultipleChoice, ItemShortForm } from 'components/Items/View';
import { YyyyMmDd } from 'components/Function/ChangeText';

const SessionView = (props) => {  
    const { clientNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [selectScaleId, setSelectScaleId] = useState('');
    const [openResetModal, setOpenResetModal] = useState(false);
    const session = props.session;
    const setSession = props.setSession;
    const selectSession = props.selectSession;
    const setSelectSession = props.setSelectSession;
    const rooms = props.rooms;
    const setRooms = props.setRooms;
    const [search, setSearch] = useState('');
    const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
    const [openSessionCreateModal, setOpenSessionCreateModal] = useState(false);
    const [openSessionUpdateModal, setOpenSessionUpdateModal] = useState(false);
    const [openSessionDeleteModal, setOpenSessionDeleteModal] = useState(false);
    const [openUrlSendModal, setOpenUrlSendModal] = useState(false);
    const [scaleNo, setScaleNo] = useState('');
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'StartDateTimeValue', label: '상담 일시'},
                        { key: 'KindDetailValue', label: '상담 종류'},
                        { key: 'ServiceValue', label: '상담 방식' },
                        { key: 'StateValue', label: '상태' }];
    
    const handleCloseUrlSend = () => {
        setOpenUrlSendModal(false);
        props.setSend({ Email: false, Kakao: false });
    };

    const handleClickScales = () => {
        window.open(`${window.location.origin}/scale/${clientNo}/${selectSession.ScheduleNo}/${scaleNo}/loginKey`);
    };
    
    const handleChangeGAF = (e) => {
        if(String(e.target.value).indexOf('.') === -1){
            if(e.target.value > 0 && e.target.value < 101){
                setSelectSession({ ...selectSession, GAF: e.target.value });
            }
            if(e.target.value === ''){
                setSelectSession({ ...selectSession, GAF: '' });
            }
        }else{
            return false;
        }
    };

    const handleCloseCreateSession = () => {
        setOpenSessionCreateModal(false);
        setSession({
            Client_id: props.client._id,
            Counselor_id: props.client.Counselor_id ? props.client.Counselor_id?._id : '',
            StartDate: '',
            StartTime: '',
            EndTime: '',
            Service: '',
            State: '',
            Status: 1,
            Room_id: '',
            Kind: { State: 1, Detail: '' },
            Repeat: { State: false, Count: '', Days: '' },
            IsFee: false,
            Fee: '',
            Memo: ''
        });
    };
    const handleChangeKindDetail = (e) => {
        let changeSession = { ...session };
        changeSession.Kind.Detail = e.target.value;
        setSession(changeSession);
    };
    const handleChangeStartTime = (e) => {
        let changeSession = { ...session };
        changeSession.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSession.EndTime = `${tumpHours}:${tumpMinutes}`
        setSession(changeSession)
    };
    const handleChangeRepeatCount = (e) => {
        let changeSession = { ...session };
        changeSession.Repeat.Count = e.target.value;
        setSession(changeSession);
    };
    const handleChangeRepeatDays = (e) => {
        let changeSession = { ...session };
        changeSession.Repeat.Days = e.target.value;
        setSession(changeSession);
    };

    useEffect(() => {
        let tump = false;
        if(!session.Kind.Detail){
            tump = true;
        }
        if(!session.StartDate){
            tump = true;
        }
        if(!session.StartTime){
            tump = true;
        }
        if(!session.Service){
            tump = true;
        }
        if(!session.Counselor_id){
            tump = true;
        }
        setDisabledCreateBtn(tump);
    }, [session]);

    const handleResetRooms = () => {
        let changeRooms = [...rooms];
        for(let i = 0; i < changeRooms.length; i++){
            changeRooms[i].disabled = false;
        }
        if(session.StartDate && session.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(session.StartDate) === 0));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${session.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${session.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${session.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${session.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        if(selectSession.StartDate && selectSession.StartTime){
            for(let i = 0; i < changeRooms.length; i++){
                let tumpDatas = changeRooms[i].Schedule_ids.filter((item) => (new Date(item.StartDate) - new Date(selectSession.StartDate) === 0 && selectSession._id !== item._id));
                tumpDatas = tumpDatas.filter((item) => ((
                    new Date(`2000-01-01 ${selectSession.StartTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${selectSession.StartTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                ) || (
                    new Date(`2000-01-01 ${selectSession.EndTime}`) >= new Date(`2000-01-01 ${item.StartTime}`) && 
                    new Date(`2000-01-01 ${selectSession.EndTime}`) <= new Date(`2000-01-01 ${item.EndTime}`)
                )));
                if(tumpDatas.length > 0){
                    changeRooms[i].disabled = true;
                }
            }
        }
        setRooms(changeRooms);
    };
    
    useEffect(() => {
        handleResetRooms();
    }, [session.StartDate, session.StartTime, selectSession.StartDate, selectSession.StartTime]);

    const handleClickSessionDetail = (data) => {
        setSelectSession(data);
        setTimeout(() => {
            const sessionTop = window.document.querySelector('#session_detail').offsetTop - 48;
            window.scrollTo(0, sessionTop);
        }, [100]);
    };

    const handleClickSessionUpdate = (data) => {
        setSelectSession(data);
        setOpenSessionUpdateModal(true);
    };

    const handleChangeKindDetailUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Kind.Detail = e.target.value;
        setSelectSession(changeSession);
    };
    const handleChangeStartTimeUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.StartTime = e.target.value;
        let tumpTime = new Date(`1980-01-01 ${e.target.value}`);
        tumpTime = new Date(tumpTime.setMinutes(tumpTime.getMinutes() + 50));
        const tumpHours = tumpTime.getHours() > 9 ? tumpTime.getHours() : `0${tumpTime.getHours()}`;
        const tumpMinutes = tumpTime.getMinutes() > 9 ? tumpTime.getMinutes() : `0${tumpTime.getMinutes()}`;
        changeSession.EndTime = `${tumpHours}:${tumpMinutes}`
        setSelectSession(changeSession)
    };
    const handleChangeRepeatCountUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Repeat.Count = e.target.value;
        setSelectSession(changeSession);
    };
    const handleChangeRepeatDaysUpdate = (e) => {
        let changeSession = { ...selectSession };
        changeSession.Repeat.Days = e.target.value;
        setSelectSession(changeSession);
    };

    const handleClickSessionDelete = (data) => {
        setSelectSession(data);
        setOpenSessionDeleteModal(true);
    };
    
    const handleClickOpenSacle = (key) => {
        let openScales = { ...props.client };
        openScales.Scales[key].Open = openScales.Scales[key].Open ? false : true;
        props.setClient(openScales);
    };

    const handleClickResetBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        let changeAnswer = { ...selectSession };
        for(let i = 0; i < changeAnswer.ScaleAnswer.length; i++){
            if(changeAnswer.ScaleAnswer[i].Scale_id === selectScaleId){
                changeAnswer.ScaleAnswer[i].Answer = [];
            }
        }
        const body = JSON.stringify({ Answer: changeAnswer.ScaleAnswer });
        await axios.post(`/api/scale/${selectSession.ScheduleNo}/${clientNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(res.data.ErrorMessage){
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }else{
                setSelectSession(changeAnswer);
                setOpenResetModal(false);
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };
    
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px', textAlign: 'right' }}>
                    <SmallSubmitButton 
                        label='상담 생성'
                        handleClick={() => setOpenSessionCreateModal(true)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTableButton
                        keys={keys}
                        datas={props.client.Schedule_ids}
                        search={search}
                        update={true}
                        delete={true}
                        handleClickDetail={handleClickSessionDetail}
                        handleClickUpdate={handleClickSessionUpdate}
                        handleClickDelete={handleClickSessionDelete}
                        defaultSort={[180, 1]}
                        client={props.client}
                        setOpenUrlSendModal={setOpenUrlSendModal}
                        setSelectSession={setSelectSession}
                        setScaleNo={setScaleNo}
                    />
                </Grid>
            </Grid>
            <Grid id='session_detail' />
            {selectSession.ScheduleNo ?
            <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3>상담 정보</h3>
                    <DataBox
                        datas={[
                            { key: '상담 번호', value: selectSession.ScheduleNo },
                            { key: '상담실', value: selectSession.Room_id ? selectSession.Room_id.Name : '' },
                            { key: '상담 일', value: YyyyMmDd(selectSession.StartDate) },
                            { key: '상담 시간', value: `${selectSession.StartTime} ~ ${selectSession.EndTime}` },
                            { key: '상담 종류', value: selectSession.KindDetailValue },
                            { key: '상담 방식', value: selectSession.ServiceValue },
                            { key: '메모', value: selectSession.Memo.replaceAll('\n', '<br/>') },
                            { key: '상담비', value: selectSession.Fee }
                        ]}
                    />
                    {/* <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0',
                            marginTop: '8px'
                        }}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} md={1.5} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            메모
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9} md={10.5}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                padding: '4px 16px'
                            }}
                        >
                            <div 
                                dangerouslySetInnerHTML={{ 
                                    __html: selectSession.Memo.replaceAll('\n', '<br/>')
                                }} 
                            />
                        </Grid>
                    </Grid> */}
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1, mt: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3>상담 내용</h3>    
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={10}>
                            <FormControl fullWidth>
                                <Grid style={{ marginBottom: '8px' }}>
                                    방문 여부
                                </Grid>
                                <RadioGroup row>
                                    {SesstionState().map((item, i) => (
                                    <FormControlLabel key={i} value={item.value} onChange={(e) => setSelectSession({ ...selectSession, State: e.target.value })} control={<Radio checked={Number(selectSession.State) === Number(item.value)}/>} label={item.label} />
                                    ))}
                                </RadioGroup>
                                <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                            </FormControl> 
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={10}>
                            <TextForm
                                label='회기 노트'
                                required={false}
                                error={false}
                                value={selectSession.Note ? selectSession.Note : ''}
                                rows={8}
                                handleChange={(e) => setSelectSession({ ...selectSession, Note: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'} spacing={1}>
                        <Grid item xs={6} md={5}>
                            <NumberForm
                                label='기능 평가'
                                required={false}
                                error={false}
                                value={selectSession.GAF ? selectSession.GAF : ''}
                                placeholder='1이상 100이하의 정수를 입력해 주세요.'
                                handleChange={handleChangeGAF}
                            />
                        </Grid>
                        <Grid item xs={6} md={5}>
                            <SelectForm
                                label='위험 수준'
                                required={false}
                                error={false}
                                value={selectSession.Risk ? selectSession.Risk : ''}
                                datas={Risk()}
                                handleChange={(e) => setSelectSession({ ...selectSession, Risk: e.target.value })}
                                cancel={Boolean(selectSession.Risk)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <SmallSubmitButton
                                label='저장'
                                handleClick={props.handleClickSessionSave}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 1, mt: 1 }}/>
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{ marginBottom: 0 }}>검사 목록</h3>
                    <Grid container>
                        {props.client.Scales ? 
                        <Grid item xs={12}>
                            {/* <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <h4>검사</h4>
                                </Grid>
                                <Grid item >
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <SmallCancelButton
                                                label='URL'
                                                handleClick={() => setOpenUrlSendModal(true)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <SmallCancelButton
                                                label='입력'
                                                handleClick={handleClickScales}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            {props.client.Scales.map((item, i) => (
                            item.Scale_id.ScaleKind === 1 ?
                            <Grid container key={i} style={{ marginBottom: '8px'}}>
                                <Grid container item xs={8} alignItems={'center'}>
                                    <Grid item>
                                        <IconButton onClick={() => handleClickOpenSacle(i)}>
                                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                                    </Grid>
                                </Grid>
                                {selectSession.ScaleAnswer.filter((s) => (s.Scale_id === item.Scale_id._id)).length === 0 ?
                                null:
                                <Grid item xs={4} spacing={1}  justifyContent={'flex-end'} container>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='PDF'
                                            handleClick={() => window.open(`${window.location.origin}/scale/pdf/${selectSession.ScheduleNo}/${item.Scale_id.ScaleNo}`)}
                                            disabled={!Boolean(selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0].Answer.length)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='초기화'
                                            handleClick={() => {setSelectScaleId(item.Scale_id._id);setOpenResetModal(true);}}
                                            disabled={!Boolean(selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0].Answer.length)}
                                        />
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Collapse in={item.Open}>
                                        <Grid style={{ marginBottom : '16px' }}>
                                            {item.Scale_id.Detail}
                                        </Grid>
                                        {item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                                        <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                                            {q.ItemKind === 1 ?
                                            <ItemShortForm 
                                                item={q} 
                                                no={j+1} 
                                                scale={item.Scale_id} 
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)} 
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 2 ?
                                            <ItemMultipleChoice
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 3 ?
                                            <ItemLikert
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            null}
                                        </Grid>
                                        ))}
                                        <Divider />
                                    </Collapse>
                                </Grid>
                            </Grid>
                            :null
                            ))}
                            <h4>피드백</h4>
                            {props.client.Scales.map((item, i) => (
                            item.Scale_id.ScaleKind === 2 ?
                            <Grid container key={i} style={{ marginBottom: '8px'}}>
                                <Grid container item xs={8} alignItems={'center'}>
                                    <Grid item>
                                        <IconButton onClick={() => handleClickOpenSacle(i)}>
                                            <KeyboardArrowUp style={{ transform: `rotate(${item.Open ? 0 : 180}deg)`, transition: 'all ease 0.25s' }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <b>{item.Scale_id.Label}</b>{item.Primary ? <b style={{ color: '#A1D8A0' }}> ★</b> : ''}
                                    </Grid>
                                </Grid>
                                {selectSession.ScaleAnswer.filter((s) => (s.Scale_id === item.Scale_id._id)).length === 0 ?
                                null:
                                <Grid item xs={4} spacing={1}  justifyContent={'flex-end'} container>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='PDF'
                                            handleClick={() => window.open(`${window.location.origin}/scale/pdf/${selectSession.ScheduleNo}/${item.Scale_id.ScaleNo}`)}
                                            disabled={!Boolean(selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0].Answer.length)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <SmallCancelButton
                                            label='초기화'
                                            handleClick={() => {setSelectScaleId(item.Scale_id._id);setOpenResetModal(true);}}
                                            disabled={!Boolean(selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0].Answer.length)}
                                        />
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Collapse in={item.Open}>
                                        <Grid style={{ marginBottom : '16px' }}>
                                            {item.Scale_id.Detail}
                                        </Grid>
                                        {item.Scale_id.Item_ids.sort((a, b) => (a.SubScale - b.SubScale)).map((q, j) => (
                                        <Grid key={j} style={{ marginBottom: '8px', padding: '4px 8px' }}>
                                            {q.ItemKind === 1 ?
                                            <ItemShortForm 
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 2 ?
                                            <ItemMultipleChoice
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            q.ItemKind === 3 ?
                                            <ItemLikert
                                                item={q}
                                                no={j+1}
                                                scale={item.Scale_id}
                                                subScale={q.SubScale && (j === 0 || q.SubScale !== item.Scale_id.Item_ids[j-1].SubScale)}
                                                lastItem={item.Scale_id.Item_ids.length === j+1}
                                                answer={selectSession.ScaleAnswer.filter((a) => (a.Scale_id === item.Scale_id._id))[0]?.Answer.filter((a) => (a.Item_id === q._id))[0]?.Answer}
                                            /> :
                                            null}
                                        </Grid>
                                        ))}
                                        <Divider />
                                    </Collapse>
                                </Grid>
                            </Grid>
                            :null
                            ))}
                        </Grid>:
                        <Grid item xs={12}>
                            등록된 검사가 없습니다.
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>:
            null}

            {/* 내담자 상담 생성 모달 */}
            <FormModal
                title='내담자 상담 생성'
                open={openSessionCreateModal}
                handleClose={handleCloseCreateSession}
                submitLabel='생성'
                handleClickSubmit={() => { props.handleClickCreateBtn();setOpenSessionCreateModal(false); }}
                disabled={disabledCreateBtn}
            >
                <SelectForm
                    label='상담 종류'
                    required={true}
                    error={false}
                    value={session.Kind.Detail}
                    datas={SessionKind()}
                    handleChange={handleChangeKindDetail}
                />
                <DateForm
                    label='상담 일'
                    required={true}
                    error={false}
                    value={session.StartDate}
                    handleChange={(e) => setSession({ ...session, StartDate: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 시작 시간'
                            required={true}
                            error={false}
                            value={session.StartTime}
                            handleChange={handleChangeStartTime}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 종료 시간'
                            required={true}
                            error={false}
                            value={session.EndTime}
                            handleChange={(e) => setSession({ ...session, EndTime: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <SelectForm
                    label='서비스 방식'
                    required={true}
                    error={false}
                    value={session.Service}
                    datas={Service()}
                    handleChange={(e) => setSession({ ...session, Service: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담자'
                            required={true}
                            error={false}
                            disabled={true}
                            value={props.user?._id}
                            datas={[{ value: props.user?._id, label: props.user?.Name }]}
                            handleChange={(e) => setSession({ ...session, Counselor_id: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담실'
                            required={false}
                            error={!Boolean(session.StartDate) || !Boolean(session.StartTime)}
                            errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                            cancel={true}
                            disabled={!Boolean(session.StartTime)}
                            value={session.Room_id}
                            datas={props.rooms}
                            handleChange={(e) => setSession({ ...session, Room_id: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <TextForm
                    label='메모'
                    required={false}
                    error={false}
                    value={session.Memo}
                    rows={4}
                    handleChange={(e) => setSession({ ...session, Memo: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Grid style={{ marginBottom: '8px' }}>
                            상담비 지불 여부
                        </Grid>
                        <RadioGroup row>
                            <FormControlLabel value={true} onChange={() => setSession({ ...session, IsFee: true })} control={<Radio checked={Boolean(session.IsFee)} />} label={'O'} />
                            <FormControlLabel value={false} onChange={() => setSession({ ...session, IsFee: false })} control={<Radio checked={!Boolean(session.IsFee)} />} label={'X'} />
                        </RadioGroup>
                        <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                    </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <NumberForm
                            label='상담비'
                            required={false}
                            error={false}
                            // disabled={!Boolean(session.IsFee)}
                            placeholder='상담비를 입력해 주세요.'
                            value={session.Fee}
                            handleChange={(e) => setSession({ ...session, Fee: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={'stretch'}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                반복 여부
                            </Grid>
                            <FormGroup row>
                                <FormControlLabel 
                                    onChange={() => setSession({ ...session, Repeat: { State: !Boolean(session.Repeat.State), Count: '', Days: '' }})} 
                                    control={<Checkbox checked={Boolean(session.Repeat.State)}/>} 
                                />
                            </FormGroup>
                            <FormHelperText style={{ marginLeft: 0, marginTop: 16, whiteSpace: 'nowrap' }}>* 반복 설정 시 상담비와 상담실은 첫번째 상담만 설정됩니다.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 횟수'
                            required={false}
                            error={false}
                            disabled={!Boolean(session.Repeat.State)}
                            value={session.Repeat.Count}
                            datas={RepeatCount()}
                            handleChange={handleChangeRepeatCount}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 주기'
                            required={false}
                            error={false}
                            disabled={!Boolean(session.Repeat.State)}
                            value={session.Repeat.Days}
                            datas={RepeatDay()}
                            handleChange={handleChangeRepeatDays}
                        />
                    </Grid>
                </Grid>
            </FormModal>

            {/* 내담자 상담 수정 모달 */}
            <FormModal
                title='내담자 상담 수정'
                open={openSessionUpdateModal}
                handleClose={() => setOpenSessionUpdateModal(false)}
                submitLabel='수정'
                handleClickSubmit={() => { props.handleClickUpdateBtn(); setOpenSessionUpdateModal(false); }}
            >
                <SelectForm
                    label='상담 종류'
                    required={true}
                    error={false}
                    value={selectSession.Kind.Detail}
                    datas={SessionKind()}
                    handleChange={handleChangeKindDetailUpdate}
                />
                <DateForm
                    label='상담 일'
                    required={true}
                    error={false}
                    value={YyyyMmDd(selectSession.StartDate)}
                    handleChange={(e) => setSelectSession({ ...selectSession, StartDate: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 시작 시간'
                            required={true}
                            error={false}
                            value={selectSession.StartTime}
                            handleChange={handleChangeStartTimeUpdate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimeForm
                            label='상담 종료 시간'
                            required={true}
                            error={false}
                            value={selectSession.EndTime}
                            handleChange={(e) => setSelectSession({ ...selectSession, EndTime: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <SelectForm
                    label='서비스 방식'
                    required={true}
                    error={false}
                    value={selectSession.Service}
                    datas={Service()}
                    handleChange={(e) => setSelectSession({ ...selectSession, Service: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담자'
                            required={true}
                            error={false}
                            disabled={true}
                            value={selectSession.Counselor_id._id}
                            datas={[{ value: props.user?._id, label: props.user?.Name }]}
                            handleChange={(e) => setSelectSession({ ...selectSession, Counselor_id: { _id: e.target.value }})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectForm
                            label='상담실'
                            required={false}
                            error={!Boolean(selectSession.StartDate) || !Boolean(selectSession.StartTime)}
                            errorMessage={'상담 일 및 시간을 먼저 설정해주세요.'}
                            cancel={true}
                            disabled={!Boolean(selectSession.StartTime)}
                            value={selectSession.Room_id ? selectSession.Room_id._id : ''}
                            datas={props.rooms}
                            handleChange={(e) => setSelectSession({ ...selectSession, Room_id: { _id: e.target.value }})}
                        />
                    </Grid>
                </Grid>
                <TextForm
                    label='메모'
                    required={false}
                    error={false}
                    value={selectSession.Memo}
                    rows={4}
                    handleChange={(e) => setSelectSession({ ...selectSession, Memo: e.target.value })}
                />
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Grid style={{ marginBottom: '8px' }}>
                            상담비 지불 여부
                        </Grid>
                        <RadioGroup row>
                            <FormControlLabel value={true} onChange={() => setSelectSession({ ...selectSession, IsFee: true })} control={<Radio checked={Boolean(selectSession.IsFee)} />} label={'O'} />
                            <FormControlLabel value={false} onChange={() => setSelectSession({ ...selectSession, IsFee: false })} control={<Radio checked={!Boolean(selectSession.IsFee)} />} label={'X'} />
                        </RadioGroup>
                        <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                    </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <NumberForm
                            label='상담비'
                            required={false}
                            error={false}
                            // disabled={!Boolean(selectSession.IsFee)}
                            placeholder='상담비를 입력해 주세요.'
                            value={selectSession.Fee}
                            handleChange={(e) => setSelectSession({ ...selectSession, Fee: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Grid style={{ marginBottom: '8px' }}>
                                반복 여부
                            </Grid>
                            <FormGroup row>
                                <FormControlLabel 
                                    onChange={() => setSelectSession({ ...selectSession, Repeat: { State: !Boolean(selectSession.Repeat.State), Count: '', Days: '' }})} 
                                    control={<Checkbox checked={Boolean(selectSession.Repeat.State)}/>} 
                                />
                            </FormGroup>
                            <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 횟수'
                            required={false}
                            error={false}
                            disabled={!Boolean(selectSession.Repeat.State)}
                            value={selectSession.Repeat.Count}
                            datas={RepeatCount()}
                            handleChange={handleChangeRepeatCountUpdate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectForm
                            label='반복 주기'
                            required={false}
                            error={false}
                            disabled={!Boolean(selectSession.Repeat.State)}
                            value={selectSession.Repeat.Days}
                            datas={RepeatDay()}
                            handleChange={handleChangeRepeatDaysUpdate}
                        />
                    </Grid>
                </Grid>
            </FormModal>
            <ConfirmModal
                title='내담자 일정 삭제'
                icon='error'
                open={openSessionDeleteModal}
                handleClose={() => setOpenSessionDeleteModal(false)}
                handleClickSubmit={() => { props.handleClickDeleteBtn();setOpenSessionDeleteModal(false); }}
                submitLabel='삭제'
            >
                내담자 상담 일정을 삭제 하시겠습니까? 내담자 상담 삭제 시 복구가 불가능합니다.
            </ConfirmModal>
            <FormModal
                title='URL 전송'
                open={openUrlSendModal}
                handleClose={() => handleCloseUrlSend()}
                submitLabel='확인'
                handleClickSubmit={() => { props.handleClickUrlSendBtn(selectSession.ScheduleNo, scaleNo);handleCloseUrlSend(); }}
                disabled={false}
            >
                <FormControl fullWidth>
                    <FormGroup>
                        <FormControlLabel 
                            value={props.send.Email}
                            onChange={() => props.setSend({ ...props.send, Email: !props.send.Email })}
                            control={<Checkbox checked={props.send.Email}/>}
                            label={`이메일 전송 (${props.client.Email_decrypt})`}
                        />
                        <FormControlLabel 
                            value={props.send.Kakao}
                            onChange={() => props.setSend({ ...props.send, Kakao: !props.send.Kakao })}
                            disabled={true}
                            // disabled={!Boolean(props.client.Phone_decrypt) || !Boolean(props.client.Phone_decrypt.indexOf('010') > -1)}
                            control={<Checkbox checked={props.send.Kakao}/>}
                            label={`카카오톡 전송 ${props.client.Phone_decrypt ? `(${props.client.Phone_decrypt})` : ''}`}
                        />
                    </FormGroup>
                    <FormHelperText style={{ marginLeft: 0 }}> </FormHelperText>
                </FormControl>
            </FormModal>
            <ConfirmModal
                title='검사 초기화'
                open={openResetModal}
                handleClose={() => setOpenResetModal(false)}
                submitLabel='초기화'
                handleClickSubmit={handleClickResetBtn}
            >
                초기화 버튼 클릭 시 해당 검사가 초기화 됩니다. 초기화 시 복구가 불가능합니다.
            </ConfirmModal>
        </>
    );
};

export default SessionView;