import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { ReportListView } from 'components/User/Report';
import { ScheduleKindKo, ServiceKo, SessionStateKo, YyyyMmDd } from 'components/Function/ChangeText';

const ReportListContainer = (props) => {
    const pages = [
        { name: '메시지', link: '' },
        { name: '메시지 목록', link: '' }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey', 'UserNo']);
    const [schedules, setSchedules] = useState([]);
    const [user, setUser] = useState({});
    const [members, setMembers] = useState([]);

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/schedule/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                await axios.get(`/api/manager/group/${cookies.LoginKey}`, config)
                .then((res2) => {
                    let loadSchedules = [...res.data.ScheduleDatas];
                    let loadUser = { ...res.data.UserData };
                    let loadGroups = [...res2.data.GroupDatas];
                    let loadMembers = [];
                    
                    if(loadUser.Status === 2){
                        loadSchedules = loadSchedules.filter((item) => (item.Counselor_id?._id === res.data.UserData._id));
                    }
                    loadSchedules = loadSchedules.filter((item) => (item.Kind.State === 1));
                    loadSchedules = loadSchedules.sort((a, b) => {
                        if(a.StartDate > b.StartDate) return 1;
                        if(a.StartDate < b.StartDate) return -1;
                        else return 0;
                    });
                    for(let i = 0; i < loadSchedules.length; i++){
                        loadSchedules[i].No = i+1;
                        for(let j = 0; j < loadGroups.length; j++){
                            if(loadGroups[j].Client_ids.indexOf(loadSchedules[i].Client_id._id) > -1){
                                loadSchedules[i].GroupName = loadGroups[j].Name;
                                break;
                            }
                        }
                        const tumpMember = { value: loadSchedules[i].Counselor_id._id, label: loadSchedules[i].Counselor_id.Name };
                        if(!loadMembers.filter((a) => (a.value === loadSchedules[i].Counselor_id._id))[0]){
                            loadMembers.push(tumpMember);
                        }
                        loadSchedules[i].CounselorName = loadSchedules[i].Counselor_id.Name;
                        loadSchedules[i].ClientName = loadSchedules[i].Client_id.Name_decrypt;
                        loadSchedules[i].NickName = loadSchedules[i].Client_id.NickName;
                        loadSchedules[i].StartDateValue = `${YyyyMmDd(loadSchedules[i].StartDate)} ${loadSchedules[i].StartTime}`;
                        loadSchedules[i].KindDetailValue = ScheduleKindKo(loadSchedules[i].Kind.Detail);
                        loadSchedules[i].ServiceValue = ServiceKo(loadSchedules[i].Service);
                        loadSchedules[i].StateValue = SessionStateKo(loadSchedules[i].State);
                        loadSchedules[i].IsFeeValue = loadSchedules[i].IsFee ? 'O' : 'X';
                        loadSchedules[i].FeeValue = loadSchedules[i].Fee ? loadSchedules[i].Fee : '';
                    }
                    setSchedules(loadSchedules);
                    setUser(loadUser);
                    setMembers(loadMembers);
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [setSchedules, enqueueSnackbar, cookies.LoginKey]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ReportListView
                user={user}
                schedules={schedules}
                members={members}
            />
        </DashboardLayout>
    );
};

export default ReportListContainer;