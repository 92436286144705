import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { ItemMultipleChoice, ItemShortForm, ItemLikert } from './Items/View';
import { YyyyMmDd } from './Function/ChangeText';
import { useParams } from 'react-router-dom';

// Create Document Component
const TablePDFView = (props) => {
    const { start, end } = useParams();
  return (
    <>
    {props.loading ? 
    <Grid style={{ padding: '60px'}}>
      <CircularProgress />
    </Grid>
    :
    <Grid >
        <h1 style={{ textAlign: 'center' }}>{'내담자 상담목록 및 정산내역'}</h1>
        <p style={{textAlign: 'right', paddingRight: '16px' }}>
            <b>{props.user.Name}</b>
            <br/><br/>
            {start !== 'null' || end !== 'null' ?
            <span>{start !== 'null' ? YyyyMmDd(start) : ''} ~ {end !== 'null' ? YyyyMmDd(end) : ''}</span>:
            null}
        </p>
        {/* <p>{props.scale.Scale_id.Detail}</p> */}
        <Grid>
        <div className='table_container full_width' id='table_top'>
            <table className='data_table'>
                <thead>
                    <tr>
                        {props.keys.map((item, i) => (
                        <th key={i}>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    <span>{item.label}</span>
                                </Grid>
                            </Grid>
                        </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.schedules.length > 0 ?
                    (props.schedules).map((data, i) => (
                    <tr key={i}>
                        {props.keys.map((item, j) => (
                            <td key={j} className='td_onclick' onClick={() => props.handleClickDetail(data)}>
                                {data[`${item.key}`]?.toString().indexOf('<small>') > -1 ? <div dangerouslySetInnerHTML={{ __html: data[`${item.key}`] }}/> : data[`${item.key}`]}
                            </td>
                        ))}
                    </tr>
                    )) :
                    <tr>
                        <td colSpan={props.keys.length} style={{ textAlign: 'center' }}>
                            일치하는 정보가 없습니다.
                        </td>
                    </tr>}
                    <tr>
                        <td colSpan={props.keys.length} style={{ textAlign: 'center', borderBottom: '2px solid #C6E7CF' }}>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                </Grid>
                            </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </Grid>
        <Grid style={{ textAlign: 'right', paddingRight: '16px' }}>
            합계: <u><b>{Number(props.sumFee).toLocaleString()}원</b></u>
        </Grid>
      </Grid>}
      </>
)};

export default TablePDFView;