import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { ClientDetailView } from 'components/User/Manage/Client';
import { ServiceKo, YyyyMmDd, ScheduleKindKo, SessionStateKo, YyMmDd } from 'components/Function/ChangeText';

const ClientDetailContainer = (props) => {
    const pages = [
        { name: '내담자 관리', link: '/manage/client' },
        { name: '내담자 상세', link: '' }
    ];
    const { clientNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [client, setClient] = useState({
        NickName: '', // 내담자 ID
        Name: '', // 이름
        Email: '', // 이메일
        Phone: '', // 연락처
        Counselor_id: { _id : '', Name: '' }, // 담당자 _id
        CounselorId: '',
        Risk: '', // 위험도
        Address: '', // 현주소
        Brith: '', // 생년월일
        Gender: '', // 성별
        Marriage: '', // 결혼 유무
        Military: '', // 병역
        Education: '', // 최종학력
        Employment: '', // 직업
        Living: '', // 주거형태
        LivingDetail: '', // 주거형태 상세
        Dependents: '', // 부양가족 수
        Nationality: '', // 국적
        NationalityDetail: '', // 국적 상세
        Religion: '', // 종교
        Medication: '', // 복용약물
        MedicationDetail: '', //복용약물 상세
        Family: [{ // 가족 사항
            Relation: '', // 관계
            Age: '', // 연령
            Name: '', // 이름
            Phone: '' // 연락처
        }],
        Referral: '', // 찾아오게된 경위
        ReferralDetail: '', // 경위 상세
        DesiredService: [], // 찾아온 목적
        Reason: [],  // 상담 받고 싶은 점
        Concern: '', // 현재 고민이나 어려움
        DesiredTest: [], // 받고 싶은 검사
        PriorTreatment: {  // 이전 검사
            PriorExperience: false, // 여부
            When: '', // 언제
            Where: '', // 어디서 
            How: [], // 치료 유형
        },
        AvailableDay: [], // 상담가능 요일
        Available: [[], [], [], [], [], [], []], // 상담 가능 요알, 시간
        Schedule_ids: []
    });
    const [session, setSession] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Status: 1,
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [selectSession, setSelectSession] = useState({
        Client_id: '',
        Counselor_id: '',
        StartDate: '',
        StartTime: '',
        EndTime: '',
        Service: '',
        State: '',
        Status: 1,
        Room_id: '',
        Kind: { State: 1, Detail: '' },
        Repeat: { State: false, Count: '', Days: '' },
        IsFee: false,
        Fee: '',
        Memo: ''
    });
    const [sessions, setSessions] = useState([]);
    const [members, setMembers] = useState([]);
    const [memberId, setMemberId] = useState('');
    const [rooms, setRooms] = useState([]);
    const [send, setSend] = useState({
        Email: false,
        Kakao: false
    });

    const handleClickCreateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(session);
        await axios.post(`/api/manager/session/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUpdateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectSession);
        await axios.put(`/api/manager/session/${selectSession.ScheduleNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/session/${selectSession.ScheduleNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    loadData();
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickDeleteUserBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/manager/client/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    navigate(-1);
                }, [250]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const handleClickUrlSendBtn = async(scheduleNo, itemid) => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const label = scheduleNo === 'basic' ? client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3 && item.Scale_id._id === itemid))[0].Scale_id.Label : client.Scales.filter((a) => (a.Scale_id.ScaleNo === itemid))[0].Scale_id.Label;
        const body = JSON.stringify({
            Email: client.Email_decrypt,
            // Label: scheduleNo === 'basic' ? client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3 && item.Scale_id._id === itemid))[0].Scale_id.Label : `${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3))[0].Scale_id.Label} ${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length > 1 ? ` 외 ${client.Scales.filter((item) => (item.Scale_id.ScaleKind !== 3)).length-1}개` : ''}`,
            // Link: scheduleNo === 'basic' ? `${window.location.origin}/scale/${clientNo}/basic/${itemid}` : `${window.location.origin}/scale/${clientNo}/${scheduleNo}`
            Label: label,
            Link: scheduleNo === 'basic' ? `${window.location.origin}/scale/${clientNo}/basic/${itemid}` : `${window.location.origin}/scale/${clientNo}/${scheduleNo}/${itemid}`,
            basic: scheduleNo === 'basic' ? true : false
        });
        if(send.Email){
            await axios.post(`/api/client/email/${cookies.LoginKey}`, body, config)
            .then(async(res) => {
                if(!res.data.ErrorMessage){
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    const title = `내담자 ${client.Name_decrypt ? client.Name_decrypt : client.NickName}에게 ${label} 링크 전송`;
                    const content = `이메일(${client.Email_decrypt})로 링크가 전송되었습니다.`;
                    // 담당 상담자가 본인일 때
                    if(memberId === selectSession.Counselor_id._id){
                        await axios.post(`/api/message/${cookies.LoginKey}`, { Reciver_id: selectSession.Counselor_id._id, Title: title, Content: content }, config)
                        .then(() => {})
                        .catch(() => enqueueSnackbar('Network Error!', { variant: 'error' }));    
                    }else{
                        await axios.post(`/api/message/${cookies.LoginKey}`, { Reciver_id: selectSession.Counselor_id._id, Title: title, Content: content }, config)
                        .then(async() => {
                            // 행정 담당자(본인)에게 발송 
                            await axios.post(`/api/message/${cookies.LoginKey}`, { Reciver_id: memberId, Title: title, Content: content }, config)
                            .then(() => {})
                            .catch(() => enqueueSnackbar('Network Error!', { variant: 'error' }));
                        })
                        .catch(() => enqueueSnackbar('Network Error!', { variant: 'error' }));
                    }
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }
        if(send.Kakao){
            enqueueSnackbar('카카오톡 알림 서비스는 준비중 입니다.', { variant: 'info' });
        }
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/client/${clientNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setMemberId(res.data.MemberId);
                let loadClient = res.data.ClientData;
                loadClient.Email = loadClient.Email_decrypt;
                loadClient.Phone = loadClient.Phone_decrypt;
                loadClient.Name = loadClient.Name_decrypt;
                loadClient.Available = loadClient.Available.length ? loadClient.Available : [[], [], [], [], [], [], []];
                loadClient.AvailableDay = [];
                loadClient.CreatedAtValue = YyMmDd(loadClient.createdAt);
                for(let i = 0; i < loadClient.Available.length; i++){
                    if(loadClient.Available[i].length){
                        loadClient.AvailableDay.push((i+1).toString());
                        for(let j = 0; j < loadClient.Available[i].length; j++){
                            loadClient.Available[i][j] = loadClient.Available[i][j].toString();
                        }
                    }
                }
                for(let i = 0; i < loadClient.Schedule_ids.length; i++){
                    loadClient.Schedule_ids[i].No = i + 1;
                    loadClient.Schedule_ids[i].ClientId = loadClient.Schedule_ids[i].Client_id._id;
                    loadClient.Schedule_ids[i].CounselorId = loadClient.Schedule_ids[i].Counselor_id._id;
                    loadClient.Schedule_ids[i].CounselorName = loadClient.Schedule_ids[i].Counselor_id.Name;
                    loadClient.Schedule_ids[i].StartDateValue = `${YyyyMmDd(loadClient.Schedule_ids[i].StartDate)} ${loadClient.Schedule_ids[i].StartTime}`;
                    loadClient.Schedule_ids[i].KindDetailValue = ScheduleKindKo(loadClient.Schedule_ids[i].Kind.Detail);
                    loadClient.Schedule_ids[i].ServiceValue = ServiceKo(loadClient.Schedule_ids[i].Service);
                    loadClient.Schedule_ids[i].StateValue = SessionStateKo(loadClient.Schedule_ids[i].State);
                }
                setSessions(loadClient.Schedule_ids);
                setClient(loadClient);
                setSession({
                    Client_id: loadClient._id,
                    Counselor_id: loadClient.Counselor_id ? loadClient.Counselor_id._id : '',
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Status: 1,
                    Room_id: '',
                    Kind: { State: 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    Memo: ''
                });
                setSelectSession({
                    Client_id: loadClient._id,
                    Counselor_id: loadClient.Counselor_id ? loadClient.Counselor_id._id : '',
                    StartDate: '',
                    StartTime: '',
                    EndTime: '',
                    Service: '',
                    State: '',
                    Status: 1,
                    Room_id: '',
                    Kind: { State: 1, Detail: '' },
                    Repeat: { State: false, Count: '', Days: '' },
                    Memo: ''
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
        await axios.get(`/api/employee/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadMembers = res.data.Workers;
                loadMembers = loadMembers.filter((item) => (item.Status === 1 || item.Status === 2));
                for(let i = 0; i < loadMembers.length; i++){
                    loadMembers[i].value = loadMembers[i]._id;
                    loadMembers[i].label = loadMembers[i].Name;
                }
                loadMembers.sort((a, b) => (String(a.label).localeCompare(String(b.label))));
                setMembers(loadMembers);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
        await axios.get(`/api/manager/room/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadRooms = [...res.data.RoomDatas];
                loadRooms = loadRooms.filter((item) => (item.State === 1));
                for(let i = 0; i < loadRooms.length; i++){
                    loadRooms[i].value = loadRooms[i]._id;
                    loadRooms[i].label = loadRooms[i].Name;
                    loadRooms[i].disabled = false;
                }
                setRooms(loadRooms);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [enqueueSnackbar, cookies.LoginKey, clientNo, setClient, setSession, setMembers, setRooms, setSessions]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <ClientDetailView 
                client={client}
                session={session}
                sessions={sessions}
                members={members}
                rooms={rooms}
                setRooms={setRooms}
                setSession={setSession}
                handleClickCreateBtn={handleClickCreateBtn}
                selectSession={selectSession}
                setSelectSession={setSelectSession}
                handleClickUpdateBtn={handleClickUpdateBtn}
                handleClickDeleteBtn={handleClickDeleteBtn}
                handleClickDeleteUserBtn={handleClickDeleteUserBtn}
                send={send}
                setSend={setSend}
                handleClickUrlSendBtn={handleClickUrlSendBtn}
            />
        </DashboardLayout>
    );
};

export default ClientDetailContainer;