import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm } from 'components/Items/Form';
import { ConfirmModal } from 'components/Items/Modal';

const GroupDetailView = (props) => {
    const navigate = useNavigate();
    const { groupNo } = useParams();
    const [search, setSearch] = useState('');
    const [openGroupDeleteModal, setOpenGroupDeleteModal] = useState(false);
    const keys = [{ key: 'No', label: 'No.'},
                { key: 'NickName', label: '내담자ID'},
                { key: 'Name_decrypt', label: '이름'},
                { key: 'AgeValue', label: '만 나이'},
                { key: 'Birth', label: '생년월일'},
                { key: 'GenderValue', label: '성별'},
                { key: 'Phone_decrypt', label: '연락처'}, 
                { key: 'Email_decrypt', label: '이메일'}];

    const handleClickDetail = (data) => {
        navigate(`/manage/client/${data.ClientNo}`);
    };
    return (
        <>
        <DetailContainer
            title='그룹 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenGroupDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/manage/group/${groupNo}/update`) } ]}
        >
            <DataBox
                datas={[{ key: '그룹명', value: props.group.Name }, { key: '인원', value: `${props.group.Client_ids.length} 명` }]}
            />
        </DetailContainer>
        <DetailContainer
            title='내담자 목록'
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={props.clients}
                        delete={false}
                        update={false}
                        search={search}
                        handleClickDetail={handleClickDetail}
                    />
                </Grid>
            </Grid>   
        </DetailContainer>
        <ConfirmModal
            title='그룹 삭제'
            icon='error'
            open={openGroupDeleteModal}
            handleClose={() => setOpenGroupDeleteModal(false)}
            submitLabel='삭제'
            handleClickSubmit={props.handleClickDeleteBtn}
        >
            그룹 정보를 삭제 하시겠습니까? 그룹 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        </>
    );
};

export default GroupDetailView;