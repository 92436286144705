import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { SuperviseeListView } from 'components/User/Manage/Supervisee';
import { CalcAge, YyyyMmDd, ClientStateKo, GenderKo, YyyyMmDdHhMmSs, ClientClosingKo } from 'components/Function/ChangeText';
import { CSVLink } from "react-csv";
import ICONGREENPLUS from 'images/icon_green_plus.png';
import ICONRED from 'images/icon_red.png';
import ICONYELLOW from 'images/icon_yellow.png';
import ICONGREEN from 'images/icon_green.png';
import ICONORANGE from 'images/icon_orange.png';

const SuperviseeListContainer = (props) => {
    const pages = [
        { name: '상담자 관리', link: '' },
        { name: '상담자(수퍼바이지) 목록', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();

    const [clients, setClients] = useState([]);

    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClickDownload = () => {
        setLoading(true);
        let rowData = [...clients];
        let csvData = [
            ['No.', '상담자', '내담자', '내담자ID', '현황', '최근 일정', '다음 일정', '방문', '주요 검사', '마지막', '증감률', 'RCI%', '사례', '그룹']
        ];
        for(let i = 0; i < rowData.length; i++){
            csvData.push([clients[i].No, clients[i].CounselorName, clients[i].Name_decrypt, clients[i].NickName, clients[i].StateValue?.replace('<small>','').replace('</samll>',''), clients[i].LastVisit, clients[i].NextVisit, clients[i].VisitCount, clients[i].PrimaryScale, clients[i].LastPoint, clients[i].Increase, clients[i].RCIPer, clients[i].RCIKR, clients[i].GroupName]);
        }
        setCsvData(csvData);
        setTimeout(() => {
            clickLink();
            setLoading(false);
            enqueueSnackbar('다운로드가 완료되었습니다.', { variant: 'info' });
        }, [2000]);
    };
    const clickLink = () => {
        window.document.getElementById('csv_download').click();
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/manager/superviser/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadClients = [...res.data.ClientDatas];
                for(let i = 0; i < loadClients.length; i++){
                    loadClients[i].No = i + 1;
                    loadClients[i].Age = CalcAge(loadClients[i].Birth);
                    loadClients[i].GenderValue = GenderKo(loadClients[i].Gender);
                    loadClients[i].BirthValue = YyyyMmDd(loadClients[i].Birth);
                    if(loadClients[i].State === 8){
                        loadClients[i].StateValue = `${ClientStateKo(loadClients[i].State)}<small>${ClientClosingKo(loadClients[i].Closing)}</small>`;
                    }else{
                        loadClients[i].StateValue = ClientStateKo(loadClients[i].State);
                    }
                    loadClients[i].CounselorName = loadClients[i].Counselor_id ? loadClients[i].Counselor_id.Name : '';
                    loadClients[i].Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
                    loadClients[i].Schedule_ids = loadClients[i].Schedule_ids.filter((item) => (item.Counselor_id === loadClients[i].Counselor_id._id));
                    loadClients[i].ScheduleState1 = loadClients[i].Schedule_ids.filter((item) => (item.State === 1));
                    loadClients[i].ScheduleState2 = loadClients[i].Schedule_ids.filter((item) => (item.State === 2));
                    loadClients[i].ScheduleState3 = loadClients[i].Schedule_ids.filter((item) => (item.State === 3));
                    loadClients[i].ScheduleState4 = loadClients[i].Schedule_ids.filter((item) => (item.State === 4));
                    loadClients[i].LastVisit = loadClients[i].ScheduleState4.length > 0 ? YyyyMmDd(loadClients[i].ScheduleState4[loadClients[i].ScheduleState4.length - 1].StartDate) : '';
                    loadClients[i].NextVisit = loadClients[i].ScheduleState1.length > 0 ? YyyyMmDd(loadClients[i].ScheduleState1[0].StartDate) : '';
                    loadClients[i].VisitCount = `${loadClients[i].ScheduleState4.length}회`;
                    loadClients[i].PrimaryScale_id = loadClients[i].Scales.filter((item) => (item.Primary))[0];
                    loadClients[i].PrimaryScale = loadClients[i]?.PrimaryScale_id?.Scale_id ? loadClients[i]?.PrimaryScale_id?.Scale_id?.Label : '';
                    if(loadClients[i].Counselor_id.Open || loadClients[i].Counselor_id.Status === 1){
                        for(let j = 0; j < loadClients[i].Schedule_ids.length; j++){
                            const tumpAnswer = loadClients[i].Schedule_ids[j].ScaleAnswer.filter((item) => (item.Scale_id === loadClients[i].PrimaryScale_id?.Scale_id?._id))[0];
                            if(tumpAnswer){
                                if(loadClients[i].PrimaryScale_id?.Scale_id.CalScore){
                                    let tumpPoint = 0;
                                    let insertPoint = false;
                                    for(let k = 0; k < tumpAnswer.Answer.length; k++){
                                        const tumpItem = loadClients[i].PrimaryScale_id?.Scale_id.Item_ids.filter((item) => (item._id === tumpAnswer.Answer[k].Item_id))[0];
                                        if( tumpItem !== undefined ) {
                                            for(let k2 = 0; k2 < tumpAnswer.Answer[k].Answer.length; k2++){
                                                // if(loadClients[i].PrimaryScale_id?.Scale_id.Negative){
                                                //     tumpPoint -= tumpItem.Points[tumpAnswer.Answer[k].Answer[k2]];
                                                // }else{
                                                    tumpPoint += tumpItem.Points[tumpAnswer.Answer[k].Answer[k2]];
                                                    insertPoint = true;
                                                // }
                                            }
                                        }
                                    }
                                    if(insertPoint){
                                        if(!loadClients[i].FirstPoint){
                                            loadClients[i].FirstPoint = tumpPoint;
                                        }
                                        loadClients[i].LastPoint = tumpPoint;
                                        loadClients[i].Indiff = (loadClients[i].LastPoint - loadClients[i].FirstPoint).toFixed(1).replace('.0', '');
                                        loadClients[i].Increase = loadClients[i].FirstPoint ? ((loadClients[i].LastPoint - loadClients[i].FirstPoint) / Math.abs(loadClients[i].FirstPoint) * 100).toFixed(1) : 0;
                                        loadClients[i].Increase = `${loadClients[i].Increase}%`;
                                        if(loadClients[i].PrimaryScale_id?.Scale_id.RCI && loadClients[i].FirstPoint){
                                            const rciper = (((loadClients[i].LastPoint - loadClients[i].FirstPoint)/loadClients[i].PrimaryScale_id?.Scale_id.RCI)*100);
                                            loadClients[i].RCIPer = `${rciper.toFixed(1)}%`;
                                            if(rciper >= 100){
                                                if(loadClients[i].PrimaryScale_id?.Scale_id.RCI >= 0 && loadClients[i].LastPoint >= loadClients[i].PrimaryScale_id?.Scale_id.Standard){
                                                    loadClients[i].RCIIcon = <img src={ICONGREENPLUS} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 회복된 사례, RCI 양수 일때
                                                    loadClients[i].RCIKR = '회복된 사례';
                                                }else if(loadClients[i].PrimaryScale_id?.Scale_id.RCI < 0 && loadClients[i].LastPoint <= loadClients[i].PrimaryScale_id?.Scale_id.Standard){
                                                    loadClients[i].RCIIcon = <img src={ICONGREENPLUS} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 회복된 사례, RCI 음수 일때
                                                    loadClients[i].RCIKR = '회복된 사례';
                                                }else{
                                                    loadClients[i].RCIIcon = <img src={ICONGREEN} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 개선된 사례
                                                    loadClients[i].RCIKR = '개선된 사례';
                                                }
                                            }else if(rciper <= -100){
                                                loadClients[i].RCIIcon = <img src={ICONRED} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 악화된 사례
                                                loadClients[i].RCIKR = '악화된 사례';
                                            }else{
                                                if(rciper >= 0){
                                                loadClients[i].RCIIcon = <img src={ICONYELLOW} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 미미한 개선
                                                loadClients[i].RCIKR = '미미한 개선';
                                                }else{
                                                loadClients[i].RCIIcon = <img src={ICONORANGE} alt='icon' style={{ width: '16px', marginTop: '4px' }} />; // 미미한 악화
                                                loadClients[i].RCIKR = '미미한 악화';
                                                }
                                            }
                                        }
                                    }
                                }
                                // else{ // 범주형 자료일 때 차이
                                //     loadClients[i].Increase = 'O';
                                //     loadClients[i].LastPoint = 'O';
                                // }
                            }
                        }
                    }
                    // else{ // open = false 일때 없는 데이터와
                    //     loadClients[i].Increase = '-';
                    //     loadClients[i].LastPoint = '-';
                    //     loadClients[i].RCIPer = '-'; 
                    // }
                    loadClients[i].GroupName = loadClients[i].Group.length ? loadClients[i].Group[0].Name : '';
                }
                setClients(res.data.ClientDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, []);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <SuperviseeListView 
                clients={clients}
                handleClickDownload={handleClickDownload}
                loading={loading}
            />
            <CSVLink style={{ display: 'none' }} data={csvData} separator=',' id='csv_download' filename={`download_${YyyyMmDdHhMmSs(new Date())}`}>Download me</CSVLink>
        </DashboardLayout>
    );
};

export default SuperviseeListContainer;