import React, { useEffect, useState } from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';

import { DetailContainer, ListContainer } from 'components/Items/Container';
import { DataBox } from 'components/Items/Table';
import { ProfileView, BasicView, SessionView, GoalView, ChartView, SummaryView } from './Session';
import { GenderKo, NationalityKo, MarriageKo, MilitaryKo, EmploymentKo, LivingKo, UserStatus } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';

const SuperviseeDetailView = (props) => {
    const [tab, setTab] = useState(0);
    useEffect(() => {
        // props.status === '1' 로그인 계정이 상담소장인지 확인
        // props.client.Manager_id === props.client.Counselor_id._id // 로그인 계정이 상담소장인지 확인
        // props.client.Manager_id === props.client.Counselor_id.Supervisor_id // 로그인 계정이 담당 상담자인지 확인
        setTab(props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? 2 : 0 : 2);
        
    }, [props]);

    const datas = [
        { key: '이름', value: props.client.Counselor_id.Name },
        { key: '이메일', value: props.client.Counselor_id.Email },
        { key: '연락처', value: props.client.Counselor_id.Phone },
        { key: '권한', value: UserStatus(props.client.Counselor_id.Status) }
    ];

    const datas2 = [
        { key: '이름', value: props.client.Name },
        { key: '내담자ID', value: props.client.NickName },
        { key: '이메일', value: props.client.Email },
        { key: '연락처', value: props.client.Phone },
        { key: '비고', value: props.client.Memo, long: true },
        { key: '현 주소', value: props.client.Address },
        { key: '생년월일', value: props.client.Birth },
        { key: '성별', value: props.client.Gender !== 3 ? GenderKo(props.client.Gender) : props.client.GenderDetail },
        { key: '국적', value: props.client.Nationality !== 9 ? NationalityKo(props.client.Nationality) : props.client.NationalityDetail },
        { key: '종교', value: props.client.Religion },
        { key: '결혼유무', value: props.client.Marriage !== 6 ? MarriageKo(props.client.Marriage) : props.client.MarriageDetail },
        { key: '병역', value: MilitaryKo(props.client.Military) },
        { key: '최종학력', value: props.client.Education },
        { key: '직업', value: props.client.Employment !== 9 || !props.client.EmploymentDetail ? EmploymentKo(props.client.Employment) : props.client.EmploymentDetail },
        { key: '주거 형태', value: props.client.Living !== 6 ? LivingKo(props.client.Living) :props.client.LivingDetail },
        { key: '부양가족 수', value: props.client.Dependents || props.client.Dependents === 0 ? `${props.client.Dependents} 명` : '' },
        { key: '상담자', value: props.client.Counselor_id ? props.client.Counselor_id.Name : '' },
        { key: '신청일', value: props.client.CreatedAtValue }
    ];
    return (
        <>
        <DetailContainer
            title='상담자 정보'
        >
            <DataBox
                datas={datas}
            />
        </DetailContainer>
        <ListContainer>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)}>
                            <Tab label='프로필' tabIndex={0} sx={{ fontSize: '110%' }} />
                            <Tab label='기본서류' tabIndex={1} sx={{ fontSize: '110%' }} />
                            <Tab label='상담(ROM)' tabIndex={2} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                            {/* <Tab label='목표' tabIndex={3}/> */}
                            <Tab label='차트' tabIndex={3} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                            <Tab label='요약' tabIndex={4} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                    tab === 0 ?
                    <ProfileView 
                        client={props.client}
                        datas={datas2}
                    /> :
                    tab === 1 ?
                    <BasicView 
                        client={props.client}
                        scales={props.client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3))}
                    /> :
                    tab === 2 ? 
                    <SessionView 
                        client={props.client}
                        setClient={props.setClient}
                        selectSession={props.selectSession}
                        setSelectSession={props.setSelectSession}
                    /> :
                    // tab === 3 ?
                    // <GoalView 
                    //     goals={props.goals}
                    //     selectGoal={props.selectGoal}
                    //     setSelectGoal={props.setSelectGoal}
                    // /> :
                    tab === 3 ?
                    <ChartView 
                        client={props.client}
                        goals={props.goals}
                    /> :
                    <SummaryView 
                        goals={props.goals}
                        client={props.client}
                    />
                    } 
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default SuperviseeDetailView;