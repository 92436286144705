import React, { useEffect, useState } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';
import { TooltipButton } from 'components/Items/Button';
import ICONGREENPLUS from 'images/icon_green_plus.png';
import ICONRED from 'images/icon_red.png';
import ICONYELLOW from 'images/icon_yellow.png';
import ICONGREEN from 'images/icon_green.png';
import ICONORANGE from 'images/icon_orange.png';
import ICONLABEL from 'images/icon_label.png';
import { YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const ClientChartView = (props) => {
    const navigate = useNavigate();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const [scales, setScales] = useState([]);
    const [datas, setDatas] = useState([]);
    const [allDatas, setAllDatas] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [subScaleDatas, setSubScaleDatas] = useState([]);
    const [standard, setStandard] = useState(false);
    const [checkedSubScale, setCheckedSubScale] = useState(false);
    const [checkedScales, setCheckedScales] = useState([]);
    const [bgc, setBgc] = useState(false);
    const [reverse, setReverse] = useState(false);
    const [chartMin, setChartMin] = useState('auto');
    const [chartMax, setChartMax] = useState('auto');
    const [cnt, setCnt] = useState({ Plus: 0, Minus: 0, Same: 0, Cancel: 0, Cohen: 0, Hedges: 0 });
    const [markers, setMarkers] = useState([]);
    const [stateChecked, setStateChecked] = useState([true, true]);
    const [teams, setTeams] = useState([]);
    const [teamChecked, setTeamChecked] = useState([]);
    const [teamAllChecked, setTeamAllChecked] = useState(false);

    const filterSessionDatas = (d) => {
      // 진행중 , 종결 filter
      let tumpDatas = [];
      let returnDatas = [...d];
      
      for(let i = 0; i < returnDatas.length; i++){
        let tumpData = [];
        for(let j = 0; j < returnDatas[i].data.length; j++){
          if(returnDatas[i].data[j].State < 8 && stateChecked[0]){
            tumpData.push(returnDatas[i].data[j]);
          }
          if(returnDatas[i].data[j].State === 8 && stateChecked[1]){
            tumpData.push(returnDatas[i].data[j]);
          }
        }
        tumpDatas.push({ _id: returnDatas[i]._id, id: returnDatas[i].id, data: tumpData });
      }
      if(teamAllChecked){ // 전체 체크
        return tumpDatas;
      }else if(teamChecked.filter((a) => (a)).length){ // 팀 체크
        let teamUserList = [];
        for(let i = 0; i < props.teams.length; i++){
          if(teamChecked[i]){
            teamUserList = teamUserList.concat(props.teams[i].User_ids);
          }
        }
        let teamDatas = [];
        for(let i = 0; i < tumpDatas.length; i++){
          let teamData = [];
          for(let j = 0; j < tumpDatas[i].data.length; j++){
            if(teamUserList.indexOf(tumpDatas[i].data[j].User_id) > -1){
              teamData.push(tumpDatas[i].data[j]);
            }
          }
          teamDatas.push({ _id: tumpDatas[i]._id, id: tumpDatas[i].id, data: teamData });
        }
        return teamDatas;
      }else{
        // MyData
        let myDatas = [];
        for(let i = 0; i < tumpDatas.length; i++){
          let myData = [];
          for(let j = 0; j < tumpDatas[i].data.length; j++){
            if(tumpDatas[i].data[j].User_id === props.userId){
              myData.push(tumpDatas[i].data[j]);
            }
          }
          myDatas.push({ _id: tumpDatas[i]._id, id: tumpDatas[i].id, data: myData });
        }
        return myDatas;
      }
    };

    const filterDatas = (d) => {
      // 검사 filter
      let returnDatas = [...d];
      returnDatas = returnDatas.filter((item) => {
        let r = false;
        for(let i = 0; i < checkedScales.length; i++){
          if(checkedScales[i] && item.id === scales[i]){
            r = true;
          }
        }
        return r;
      });
      // 진행중 , 종결 filter
      let tumpDatas = [];
      for(let i = 0; i < returnDatas.length; i++){
        let tumpData = [];
        for(let j = 0; j < returnDatas[i].data.length; j++){
          if(returnDatas[i].data[j].State < 8 && stateChecked[0]){
            tumpData.push(returnDatas[i].data[j]);
          }
          if(returnDatas[i].data[j].State === 8 && stateChecked[1]){
            tumpData.push(returnDatas[i].data[j]);
          }
        }
        tumpDatas.push({ _id: returnDatas[i]._id, id: returnDatas[i].id, data: tumpData });
      }
      if(teamAllChecked){ // 전체 체크
        return tumpDatas;
      }else if(teamChecked.filter((a) => (a)).length){ // 팀 체크
        let teamUserList = [];
        for(let i = 0; i < props.teams.length; i++){
          if(teamChecked[i]){
            teamUserList = teamUserList.concat(props.teams[i].User_ids);
          }
        }
        let teamDatas = [];
        for(let i = 0; i < tumpDatas.length; i++){
          let teamData = [];
          for(let j = 0; j < tumpDatas[i].data.length; j++){
            if(teamUserList.indexOf(tumpDatas[i].data[j].User_id) > -1){
              teamData.push(tumpDatas[i].data[j]);
            }
          }
          teamDatas.push({ _id: tumpDatas[i]._id, id: tumpDatas[i].id, data: teamData });
        }
        return teamDatas;
      }else{
        // MyData
        let myDatas = [];
        for(let i = 0; i < tumpDatas.length; i++){
          let myData = [];
          for(let j = 0; j < tumpDatas[i].data.length; j++){
            if(tumpDatas[i].data[j].User_id === props.userId){
              myData.push(tumpDatas[i].data[j]);
            }
          }
          myDatas.push({ _id: tumpDatas[i]._id, id: tumpDatas[i].id, data: myData });
        }
        return myDatas;
      }
    };

    useEffect(() => {
      let loadDatas = []; // 그래프 출력 데이터
      let plus = 0;
      let minus = 0;
      let same = 0;
      let cancel = 0;
      const tumpScales = props.scales.filter((item) => (item.CalScore)); // 계산 가능한 검사만 저장
      for(let i = 0; i < tumpScales.length; i++){ // 계산 가능한 검사 전체 데이터 셋 생성
        loadDatas.push({ 'id': tumpScales[i].Label, '_id': tumpScales[i]._id, 'data': [] });
      }

      // 1. 모든 검사 데이터셋 생성
      let rawDatas = [];
      for(let i = 0; i < props.clients.length; i++){ // 상담소 내 내담자 만큼 반복
        const tumpClient = props.clients[i];
        tumpClient.Schedule_ids = tumpClient.Schedule_ids.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate))); // 검사 날짜 순으로 정렬
        for(let j = 0; j < tumpClient.Schedule_ids.length; j++){ // 내담자의 스케줄 수 만큼 반복
          const tumpSchedule = tumpClient.Schedule_ids[j];
          if(tumpSchedule.State === 2){ cancel += 1; } // 스케줄 취소 수 카운트
          for(let k = 0; k < tumpSchedule.ScaleAnswer.length; k++){ // 내담자의 스케줄 내 검사 개수 만큼 반복
            const tumpScaleAnswer = tumpSchedule.ScaleAnswer[k];
            if(tumpScaleAnswer.Answer.length){ // 검사 응답 확인
              const equalClientScale = tumpClient.Scales.filter((item) => (item.Scale_id?._id === tumpScaleAnswer.Scale_id))[0];
              const equalScale = equalClientScale?.Scale_id; // 같은 검사 출력
              if(equalScale){
                let open = false;
                let notOpen = false;
                // if(tumpClient.Counselor_id?._id === props.userId){
                //   open = true;
                // }
                
                if(tumpClient.NotOpen){
                  notOpen = true;
                }
                if(props.status === '1' && tumpClient.Counselor_id?.Open){
                  open = true;
                }else if(tumpClient.Counselor_id?._id === props.userId){
                  open = true;
                }
                // raw data 저장
                rawDatas.push({ 
                  StartDate: tumpSchedule.StartDate, // 검사 날짜 
                  // Negative: equalScale.Negative, // 역산 여부
                  Scale_id: equalScale._id, // 검사 ID
                  Item_ids: equalScale.Item_ids, // 검사 내 문항 IDs
                  Answer: tumpScaleAnswer.Answer, // 내담자의 응답
                  ClientNo: tumpClient.ClientNo, // 내담자 번호
                  ClientName: tumpClient.Name_decrypt ? tumpClient.Name_decrypt : tumpClient.NickName, // 내담자 이름
                  Min: equalScale.Min, // 검사의 최소값
                  Max: equalScale.Max, // 검사의 최대값
                  Standard: equalScale.Standard, // Cutoff
                  SubMin: equalScale.SubMin, // 하위요인 최소값
                  SubMax: equalScale.SubMax, // 하위요인 최대값
                  RCI: equalScale.RCI, // RCI
                  State: tumpClient.State, // 상태
                  User_id: tumpClient.Counselor_id?._id, // 상담자 ID
                  Schedule_id: tumpSchedule._id,
                  ScheduleState: tumpSchedule.State,
                  SubScale: equalScale.SubScale,
                  Open: open,
                  NotOpen: notOpen, // ROM 분석 제외
                  Primary: equalClientScale.Primary
                });
              }
            }
          }
        }
      }
      rawDatas = rawDatas.filter((item) => (item.Open)); // 내담자 공개 여부 true, 본인 데이터만 출력

      // 2. 내담자 및 검사 별 처음 점수, 마지막 점수 입력
      for(let i = 0; i < rawDatas.length; i++){ // 검사(스케줄) 데이터셋 개수 만큼 반복
        let tumpPoint = 0; // 응답자의 점수
        let tumpSubPoint = [];
        for(let j = 0; j < rawDatas[i].Answer.length; j++){ // 해당 검사의 응답 개수만큼 반복
          const equalItem = rawDatas[i].Item_ids.filter((item) => (item._id === rawDatas[i].Answer[j].Item_id))[0]; // 응답의 일치하는 문항 정보 저장
          if(equalItem){
            for(let k = 0; k < rawDatas[i].Answer[j].Answer.length; k++){ // 복수 선택일 수 있기 때문에 반복
              // if(rawDatas[i].Negative){ // 역산 여부 확인(true)
              //   tumpPoint -= Number(equalItem.Points[`${rawDatas[i].Answer[k].Answer[k]}`]);
              // }else{ // 역산 여부(false)
              //   tumpPoint += Number(equalItem.Points[`${rawDatas[i].Answer[k].Answer[k]}`]);
              // }

              // 하위요인 점수
              if(equalItem.SubScale){
                tumpSubPoint[equalItem.SubScale-1] = tumpSubPoint[equalItem.SubScale-1] ? tumpSubPoint[equalItem.SubScale-1] + equalItem.Points[`${rawDatas[i].Answer[j].Answer[k]}`] : equalItem.Points[`${rawDatas[i].Answer[j].Answer[k]}`];
              }
              tumpPoint += Number(equalItem.Points[`${rawDatas[i].Answer[j].Answer[k]}`]);
            }
          }
        }
        for(let j = 0; j < loadDatas.length; j++){ // 마음센서 검사 개수만큼 반복
          if(rawDatas[i].Scale_id === loadDatas[j]._id){ // 데이터와 일치하는 검사 확인
            if(loadDatas[j].data.length === 0){ // 검사 데이터 셋의 첫번째 데이터 여부 확인
              loadDatas[j].data.push({ 
                x: tumpPoint, 
                y: tumpPoint, 
                rawx: tumpPoint, 
                rawy: tumpPoint, 
                ClientNo: rawDatas[i].ClientNo, 
                ClientName: rawDatas[i].ClientName, 
                Max: rawDatas[i].Max, 
                Min: rawDatas[i].Min,
                Standard: rawDatas[i].Standard,
                SubMin: rawDatas[i].SubMin,
                SubMax: rawDatas[i].SubMax,
                RCI: rawDatas[i].RCI,
                State: rawDatas[i].State,
                User_id: rawDatas[i].User_id,
                SubPointX: tumpSubPoint,
                SubPointY: tumpSubPoint,
                SubScale: rawDatas[i].SubScale,
                Open: rawDatas[i].Open,
                NotOpen: rawDatas[i].NotOpen,
                Primary: rawDatas[i].Primary
              });
            }else{ // 두번째 이상 데이터
              let insertData = false; // 데이터 입력 여부
              for(let k = 0; k < loadDatas[j].data.length; k++){ // 검사의 데이터 셋 개수만큼 반복
                if(loadDatas[j].data[k].ClientNo === rawDatas[i].ClientNo){ // 같은 내담자가 있는지 확인
                  loadDatas[j].data[k].y = tumpPoint; // 마지막 검사 점수만 수정
                  loadDatas[j].data[k].rawy = tumpPoint; // 마지막 검사 점수만 수정
                  loadDatas[j].data[k].SubPointY = tumpSubPoint;
                  insertData = true;
                }
              }
              if(!insertData){ // 같은 내담자가 없을 때 (데이터 입력 여부 false)
                loadDatas[j].data.push({ 
                  x: tumpPoint, 
                  y: tumpPoint, 
                  rawx: tumpPoint, 
                  rawy: tumpPoint, 
                  ClientNo: rawDatas[i].ClientNo, 
                  ClientName: rawDatas[i].ClientName, 
                  Max: rawDatas[i].Max, 
                  Min: rawDatas[i].Min,
                  Standard: rawDatas[i].Standard,
                  SubMin: rawDatas[i].SubMin,
                  SubMax: rawDatas[i].SubMax,
                  RCI: rawDatas[i].RCI,
                  State: rawDatas[i].State,
                  User_id: rawDatas[i].User_id,
                  SubPointX: tumpSubPoint,
                  SubPointY: tumpSubPoint,
                  SubScale: rawDatas[i].SubScale,
                  Open: rawDatas[i].Open,
                  NotOpen: rawDatas[i].NotOpen,
                  Primary: rawDatas[i].Primary
                });
              }
            }
          }
        }
      }
      
      // console.log(loadDatas)
      // 3. 팀 / 전체 평균 계산
      // const test = [];
      // const tumpTeams = props.teams;
      // for(let i = 0; i < tumpTeams.length; i++){ // 팀 개수 만큼 반족
      //   let teamName = tumpTeams[i].Name;
      //   let teamTotalPointX = 0; // 팀 처음 총점
      //   let teamTotalPointY = 0; // 팀 종료 총점
      //   let teamTotalCnt = 0; // 팀내 검사(스케줄) 개수
      //   for(let j = 0; j < loadDatas.length; j++){ // 검사 개수 만큼 반복
      //     let scaleName = loadDatas[j].id;
      //     for(let k = 0; k < tumpTeams[i].User_ids.length; k++){ // 팀원 수 만큼 반복
      //       const teamMemberScales = loadDatas[j].data.filter((item) => (item.User_id === tumpTeams[i].User_ids[k])); // 팀원 상담 내역
      //       for(let l = 0; l < teamMemberScales.length; l++){
      //         teamTotalPointX += teamMemberScales[l].rawx;
      //         teamTotalPointY += teamMemberScales[l].rawy;
      //         teamTotalCnt++;
      //       }
      //     }
      //     test.push({ 
      //       TeamName: teamName, 
      //       ScaleName: scaleName, 
      //       TotX: teamTotalPointX, 
      //       TotY: teamTotalPointY, 
      //       TotCnt: teamTotalCnt, 
      //       MeanX: teamTotalCnt ? teamTotalPointX/teamTotalCnt : 0,
      //       MeanY: teamTotalCnt ? teamTotalPointY/teamTotalCnt : 0
      //     });
      //   }
      // }

      // for(let i = 0; i < loadDatas.length; i++){
      //   let teamName = '전체';
      //   let scaleName = loadDatas[i].id;
      //   let teamTotalPointX = 0; // 팀 처음 총점
      //   let teamTotalPointY = 0; // 팀 종료 총점
      //   let teamTotalCnt = 0; // 팀내 검사(스케줄) 개수
      //   for(let j = 0; j < loadDatas[i].data.length; j++){
      //     teamTotalPointX += loadDatas[i].data[j].rawx;
      //     teamTotalPointY += loadDatas[i].data[j].rawy;
      //     teamTotalCnt++;
      //   }
      //   test.push({ 
      //     TeamName: teamName, 
      //     ScaleName: scaleName, 
      //     TotX: teamTotalPointX, 
      //     TotY: teamTotalPointY, 
      //     TotCnt: teamTotalCnt, 
      //     MeanX: teamTotalCnt ? teamTotalPointX/teamTotalCnt : 0,
      //     MeanY: teamTotalCnt ? teamTotalPointY/teamTotalCnt : 0
      //   });
      // }
      // console.log(test)
      // console.log(loadDatas)
      
      let teamCheckedCnt = []; // 팀 체크 여부
      let loadTeams = []; // 팀 이름 목록
      const tumpTeams = props.teams;
      for(let i = 0; i < tumpTeams.length; i++){
        teamCheckedCnt.push(false);
        loadTeams.push(tumpTeams[i].Name);
      }
      setTeams(loadTeams);
      setTeamChecked(teamCheckedCnt);
      
      // 4. 시작, 종료 평균, 기타 계산
      let sumX = 0;
      let sumY = 0;
      let loadScales = []; // 검사 이름 목록
      let checkCnt = []; // 검사 체크 여부
      let resultCnt = 0; // 검사 수
      // for(let i = 0; i < loadDatas.length; i++){ // 본인 검사만 저장
      //   loadDatas[i].data = loadDatas[i].data.filter((item) => (item.User_id === props.userId));
      // }
      loadDatas = loadDatas.filter((item) => (item.data.length)); // 검사 데이터가 존재하는 검사만 저장
      for(let i = 0; i < loadDatas.length; i++){ // 검사 개수 만큼 반복
        resultCnt += loadDatas[i].data.length; // 검사(스케줄) 수 추가
        loadScales.push(loadDatas[i].id); // 검사 이름 추가
        checkCnt.push(false); // 검사 체크 추가
        for(let j = 0; j < loadDatas[i].data.length; j++){ // x, y합 계산
          sumX += loadDatas[i].data[j].rawx;
          sumY += loadDatas[i].data[j].rawy;
        }
      }
      
      const meanX = resultCnt > 0 ? sumX/resultCnt : 0;
      const meanY = resultCnt > 0 ? sumY/resultCnt : 0;
      let dSumX = 0;
      let dSumY = 0;
      for(let i = 0; i < loadDatas.length; i++){
        for(let j = 0; j < loadDatas[i].data.length; j++){
          dSumX += (meanX - loadDatas[i].data[j].rawx)**2;
          dSumY += (meanY - loadDatas[i].data[j].rawy)**2;
        }
      }

      const varX = resultCnt > 2 ? dSumX/(resultCnt - 1) : 0;
      const varY = resultCnt > 2 ? dSumY/(resultCnt - 1) : 0;
      const psd = Math.sqrt((varX + varY)/2);
      const calcCohen = psd > 0 ? ((meanY - meanX)/psd).toFixed(2) : 0;
      const calcHedges = resultCnt > 0 ? ((meanY - meanX)/Math.sqrt(((resultCnt-1)*varX+(resultCnt-1)*varY)/(resultCnt + resultCnt - 1))).toFixed(2) : 0;
      let ratio = 0; // 증감율
      setDatas(loadDatas);
      setScales(loadScales);
      if(!checkedScales.length){
        setCheckedScales(checkCnt);
      } 
      for(let i = 0; i < loadDatas.length; i++){
        for(let j = 0; j < loadDatas[i].data.length; j++){
          ratio = ( loadDatas[i].data[j].rawy / loadDatas[i].data[j].rawx ) * 100;
          if(ratio >= 125){
            plus += 1; // 개선됨
          }else if(ratio > 104){
            minus += 1; // 변화 없음
          }else{
            same += 1; // 작은 변화
          }
        }
      }
      setCnt({ Plus: plus, Minus: minus, Same: same, Cancel: cancel, Cohen: calcCohen, Hedges: calcHedges });
    }, [props, stateChecked]);
    
    const handleChange = (e) => {
      let changeChecked = [...checkedScales];
      if(Number(e.target.value) !== 0){
          changeChecked[Number(e.target.value) - 1] = !checkedScales[Number(e.target.value) - 1];
          setCheckedScales(changeChecked);
      }else{
        for(let i = 0; i < changeChecked.length; i++){
          changeChecked[i] = !allChecked;
        }
        setCheckedScales(changeChecked);
      }
      if(changeChecked.length === changeChecked.filter((a) => (a)).length){
        setAllChecked(true);
      }else{
        setAllChecked(false);
      }
    };

    const handleChangeTeamAllChecked = () => {
      setTeamAllChecked(!teamAllChecked);
    };

    const handleChangeTeamChecked = (key) => {
      let changeChecked = [...teamChecked];
      changeChecked[key] = !changeChecked[key];
      setTeamChecked(changeChecked);
    };


    const calcPoint = (data, key) => {
      if(data.length){
        let rciper, icon, cutoff, rci;
        let first = 0;
        let last = 0;
        let cnt = 0;
        for(let i = 0; i < data.length; i++){
          if(!data[i].NotOpen){
            cnt++;
            first += data[i].rawx;
            last += data[i].rawy; 
            rci = data[i].RCI;
            cutoff = data[i].Standard;
          }
        }
        const mFirst = cnt > 0 ? first/cnt : 0;
        const mLast = cnt > 0 ? last/cnt : 0;
        if(rci){
          rciper = (((mLast - mFirst)/rci)*100).toFixed(1);
          if(rciper >= 100){
            if(rci >= 0 && mLast >= cutoff){
              icon = ICONGREENPLUS; // 회복된 사례
            }else if(rci < 0 && mLast <= cutoff){
              icon = ICONGREENPLUS; // 회복된 사례
            }else{ 
              icon = ICONGREEN; // 개선된 사례
            }
          }else if(rciper <= -100){
            icon = ICONRED; // 악화된 사례
          }else{
            if(rciper >= 0){
              icon = ICONYELLOW; // 살짝 좋아지고 있음
            }else{
              icon = ICONORANGE; // 살짝 나빠지고 있음
            }
          }
          rciper = (mLast - mFirst)/rci > 0 ? `+${rciper}%` : `${rciper}%`;
        }
        // if(reverse){
        //   last = last;
        // }

        if(key === 1){
          return cnt > 0 ? (mFirst).toFixed(1).replace('.0', '') : 0;
        }else if(key === 2){
          return cnt > 0 ? (mLast).toFixed(1).replace('.0', '') : 0;
        }else if(key === 3){
          return mFirst !== 0 ? ((mLast - mFirst)/Math.abs(mFirst)) > 0 ? '+'+(((mLast - mFirst)/Math.abs(mFirst))*100).toFixed(1).replace('.0', '')+'%' : (((mLast - mFirst)/mFirst)*100).toFixed(1).replace('.0', '')+'%' : '0%';
        }else if(key === 4){
          return mLast - mFirst > 0 ? '+'+(mLast - mFirst).toFixed(1).replace('.0', '') : (mLast - mFirst).toFixed(1).replace('.0', '');
        }else if(key === 5){
          return rciper;
        }else if(key === 6){
          return icon;
        }else {
          return '';
        }
      }else{
        return '';
      }
    };

    const calcData = (data, key) => {
      let user = []; // 내담자 목록
      let allUser = 0; // 내담자 수
      let notOpenUser = 0; // ROM 결과 제외 수
      let notState8User = 0; // 상담중 수
      let state8Closing1User = 0; // 합의 종결 + closing undefined, null 수
      let state8Closing2User = 0; // 조기 종결 수
      let day10User = 0; // 10일 경과 수
      let day30User = 0; // 30일 경과 수
      let state2Schedule = 0; // 취소된 일정 수
      let state3Schedule = 0; // 불참한 일정 수
      let rci1Scale = 0; // 회복된 사례
      let rci2Scale = 0; // 개선된 사례
      let rci3Scale = 0; // 미미한 사례
      let rci31Scale = 0; // 미미한 사례+
      let rci32Scale = 0; // 미미한 사례-
      let rci4Scale = 0; // 악화된 사례
      for(let i = 0; i < data.length; i++){
        for(let j = 0; j < data[i].data.length; j++){
          const d = data[i].data[j]; // 데이터 1개
          if(user.indexOf(d.ClientNo) === -1){ // 내담자 첫번째 데이터 일때만 입력
            user.push(d.ClientNo);
          }
          if(d.RCI && d.Primary){
            const tumpRCIPer = ((d.rawy - d.rawx)/d.RCI)*100;
            if(tumpRCIPer >= 100){
              if(d.RCI >=0 && d.rawy >= d.Standard){
                rci1Scale++;
              }else if(d.RCI < 0 && d.rawy <= d.Standard){
                rci1Scale++;
              }else{
                rci2Scale++;
              }
            }else if(tumpRCIPer <= -100){
              rci4Scale++;
            }else{
              rci3Scale++;
              if(tumpRCIPer >= 0){
                rci31Scale++;
              }else{
                rci32Scale++;
              }
            }
          }
        }
      }

      const clients = props.clients;
      
      for(let i = 0; i < user.length; i++){
        const d = clients.filter((a) => (a.ClientNo === user[i]))[0];
        allUser++;
        if(d.NotOpen){
          notOpenUser++;
        }
        if(d.State < 8 && d.State > 1){ // 진행중 : 종결, 신청 제외한 모두
          notState8User++;
        }else if(d.State === 8 && d.Closing === 2){
          state8Closing2User++;
        }else if(d.State === 8){
          state8Closing1User++;
        }
        let s = d.Schedule_ids;
        s = s.sort((a, b) => (new Date(a.StartDate) > new Date(b.StartDate)));
        let cd = false; // checkDay
        for(let j = s.length-1; j >= 0; j--){
          if(s[j].State === 4 && !cd){
            if(new Date() > new Date(new Date(s[j].StartDate).setDate(new Date(s[j].StartDate).getDate()+30))){
              day30User++;
              cd = true;
            }else if(new Date() > new Date(new Date(s[j].StartDate).setDate(new Date(s[j].StartDate).getDate()+10))){
              day10User++;
              cd = true;
            }else{
              cd = true;
            }
          }
          if(s[j].State === 2){
            state2Schedule++;
          }else if(s[j].State === 3){
            state3Schedule++;
          }   
        }
      }
      
      // 스케줄 없는 내담자 확인
      let noneDataClients = clients.filter((a) => (user.indexOf(a.ClientNo) === -1));
      let teamUserList = [];
      for(let i = 0; i < props.teams.length; i++){
        if(teamChecked[i]){
          teamUserList = teamUserList.concat(props.teams[i].User_ids);
        }
      }
      
      // console.log(props.teams)
      for(let i = 0; i < noneDataClients.length; i++){
        const d = noneDataClients[i];
        // 본인 내담자
        if(teamAllChecked){ // 전체
          allUser++;
          if(d.NotOpen){
            notOpenUser++;
          }
          if(d.State < 8 && d.State > 1){ // 진행중 : 종결, 신청 제외한 모두
            notState8User++;
          }else if(d.State === 8 && d.Closing === 2){
            state8Closing2User++;
          }else if(d.State === 8){
            state8Closing1User++;
          }
        }else if(teamChecked.filter((a) => (a)).length){ //팀
          if(teamUserList.indexOf(d.Counselor_id?._id) > -1){
            allUser++;
            if(d.NotOpen){
              notOpenUser++;
            }
            if(d.State < 8 && d.State > 1){ // 진행중 : 종결, 신청 제외한 모두
              notState8User++;
            }else if(d.State === 8 && d.Closing === 2){
              state8Closing2User++;
            }else if(d.State === 8){
              state8Closing1User++;
            }
          }
        }else{ // 전체, 팀 체크 해제 (본인 내담자만)
          if(d.Counselor_id?._id === props.userId){
            allUser++;
            if(d.NotOpen){
              notOpenUser++;
            }
            if(d.State < 8 && d.State > 1){ // 진행중 : 종결, 신청 제외한 모두
              notState8User++;
            }else if(d.State === 8 && d.Closing === 2){
              state8Closing2User++;
            }else if(d.State === 8){
              state8Closing1User++;
            }
          }
        }
      }
      switch(Number(key)){
        case 1: return allUser
        case 2: return notOpenUser
        case 3: return notState8User
        case 4: return state8Closing1User
        case 5: return state8Closing2User
        case 6: return day10User
        case 7: return day30User
        case 8: return state2Schedule
        case 9: return state3Schedule
        case 10: return rci1Scale
        case 11: return rci2Scale
        case 12: return rci3Scale
        case 13: return rci4Scale
        case 14: return rci31Scale
        case 15: return rci32Scale
        default: return 'ㅤ'
      };
    }; 

    const handleClickPoint = (n) => {
      if(isMobile){
        return false;
      }else{
        if(!checkedSubScale){
          if(n.data.User_id === props.userId){
            navigate(`/client/${n.data.ClientNo}`);
          }else{
            navigate(`/manage/supervisee/${n.data.ClientNo}`);
          }
        }else{
          return false;
        }
      } 
    };

    useEffect(() => {
      // 체크 데이터 표시
      let changeDatas = [...datas];
      let max = 0;
      let min = 0;
      const checkCnt = checkedScales.filter((item) => (item === true)).length;
      for(let i = 0; i < checkedScales.length; i++){
        if(checkedScales[i]){
          if(checkCnt === 1){
            for(let j = 0; j < changeDatas[i]?.data.length; j++){
              changeDatas[i].data[j].x = changeDatas[i].data[j].rawx;
              changeDatas[i].data[j].y = changeDatas[i].data[j].rawy;
            }
            max = changeDatas[i]?.data[0].Max ? changeDatas[i].data[0].Max : 100;
            min = changeDatas[i]?.data[0].Min ? changeDatas[i].data[0].Min : 0;
          }else{
            for(let j = 0; j < changeDatas[i]?.data.length; j++){
              changeDatas[i].data[j].x = changeDatas[i].data[j].Max ? Math.round(changeDatas[i].data[j].rawx/changeDatas[i].data[j].Max*100, 1) : 0;
              changeDatas[i].data[j].y = changeDatas[i].data[j].Max ? Math.round(changeDatas[i].data[j].rawy/changeDatas[i].data[j].Max*100, 1) : 0;
            }
            max = 100;
            min = 0;
          }
        }
      }

      // // y축 평행선 cutoff
      let loadMarkers = [];
      for(let i = 0; i < changeDatas.length; i++){ 
        if(standard && checkedScales[i] && changeDatas[i].data[0]?.Standard){
          // y축 환산 cutoff
          let ratioCutoff = changeDatas[i].data[0]?.Max ? changeDatas[i].data[0]?.Standard * (max/changeDatas[i].data[0].Max) : changeDatas[i].data[0]?.Standard;
          loadMarkers.push({
            axis: 'y',
            legend: `${changeDatas[i].id} Cutoff (${changeDatas[i].data[0]?.Standard})`,
            legendPosition: 'bottom-left',
            lineStyle: {
              stroke: '#b0413e',
              strokeWidth: 2,
              stroke: 'rgba(255, 0, 0, 0.5)',
              strokeDasharray: '3 6'
            },
            textStyle: {
              fontSize: '12px'
            },
            value: ratioCutoff,
          });
        }
      }

      // 하위 요인
      let changeSubDatas = [];
      if(checkedSubScale){ // 하위요인 체크 여부
        // x, y 축 변환
        if(checkedScales.filter((a) => (a === true)).length === 1){ // 1개만 체크일 때
          for(let i = 0; i < checkedScales.length; i++){
            if(checkedScales[i]){
              max = changeDatas[i]?.data[0].SubMax ? changeDatas[i]?.data[0].SubMax : 100;
              min = changeDatas[i]?.data[0].SubMin ? changeDatas[i]?.data[0].SubMin : 0;
            }
          }
        }else{
          max = 100;
          min = 0;
        }
        for(let i = 0; i < changeDatas.length; i++){ // 출력 검사 수 만큼 반복
          if(checkedScales[i]){ // 검사 체크 여부 
            let subScaleName = '';
            for(let j = 0; j < changeDatas[i]?.data[0].SubScale.length; j++){ // 하위요인 개수만큼 반복
              subScaleName = `${changeDatas[i]?.id} ${changeDatas[i]?.data[0].SubScale[j]}`;
              let tumpData = [];
              for(let k = 0; k < changeDatas[i]?.data.length; k++){ // 검사 데이터 수 만큼 반복
                let tumpX = 0;
                let tumpY = 0; 
                if(checkedScales.filter((a) => (a === true)).length === 1 || !changeDatas[i]?.data[k].SubMax){
                  tumpX = changeDatas[i]?.data[k].SubPointX[j];
                  tumpY = changeDatas[i]?.data[k].SubPointY[j];
                }else{
                  tumpX = changeDatas[i]?.data[k].SubPointX[j]*(max/changeDatas[i]?.data[k].SubMax);
                  tumpY = changeDatas[i]?.data[k].SubPointY[j]*(max/changeDatas[i]?.data[k].SubMax);
                }
                tumpData.push({
                  ClientName: changeDatas[i]?.data[k].ClientName,
                  ClientNo: changeDatas[i]?.data[k].ClientNo,
                  x: tumpX,
                  y: tumpY,
                  rawx: changeDatas[i]?.data[k].SubPointX[j],
                  rawy: changeDatas[i]?.data[k].SubPointY[j]
                });
              }
              changeSubDatas.push({ id: subScaleName, data: tumpData });
            }
          }
        }
      }

      setDatas(changeDatas);
      setSubScaleDatas(changeSubDatas);
      setMarkers(loadMarkers);
      setChartMax(max);
      setChartMin(min);
    }, [checkedScales, standard, checkedSubScale, stateChecked]);
    
    const sessionComment = `> 내담자 수: 체크한 옵션에 해당하는 총 내담자 수 
    - 상담중 : 상담현황의 "신청" 상태를 제외한 진행중인 총 내담자 수 
    - 합의종결: 종결 혹은 합의 종결로 보고된 내담자 수 
    - 조기종결: 조기종결로 보고된 내담자 수 
    제외된 사례: ROM 결과 분석 제외로 표기된 내담자 수 (검사 결과 평균 계산에서 제외된 내담자 수)   

    10일 경과: 마지막 방문 일정 이후 10일이 경과된 내담자 수 
    30일 경과: 마지막 방문 일정 이후 30일이 경과된 내담자 수 

    > 사례 분류는 주요 검사로 표기한 검사를 기준으로 분류합니다. 
    회복된 사례: RCI% >= 100% 이상이면서, Cutoff 를 넘은 사례 (녹색+) 
    개선된 사례: RCI% >= 100% 이상인 사례 (녹색) 
    미미한 사례: - 100% < RCI% < 100% 인 사례  
    - 미미한 개선:  0 =< RCI% < 100 인 사례 (노란색)
    - 미미한 악화: - 100% < RCI% < 0 인 사례 (주황색) 
    악화된 사례: RCI% =< -100% 인 사례 (빨간색)`;

    return (
        <>
        <ListContainer
            title='전체 차트'
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={2.5} style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', fontSize: '95%'}}>
                    <h3 style={{ textAlign: 'center', marginTop: '0px' }}>차트 관리</h3>
                    <p style={{ textAlign: 'center', marginTop: '0px' }}>검사 선택</p>
                    <FormControl fullWidth>
                        <FormGroup onChange={handleChange}>
                            <FormControlLabel control={<Checkbox value={'0'} checked={allChecked} />} label={'ALL'} />
                            {scales.map((scales, i) => (
                            <FormControlLabel key={i} control={<Checkbox value={i+1} checked={checkedScales[i]} />} label={scales} />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <p style={{ textAlign: 'center', marginTop: '4px', borderTop: '1px solid rgb(234, 236, 240)', paddingTop: '4px', marginBottom: '0px' }}>옵션</p>
                    <FormControl fullWidth>
                      <FormGroup>
                        <FormControlLabel control={<Radio checked={checkedSubScale} onClick={() => {setStandard(false);setCheckedSubScale(!checkedSubScale);}} />} label={
                          <Grid container alignItems={'center'}>
                            <Grid item>
                              하위요인
                            </Grid>
                            <Grid item >
                              <TooltipButton tooltip={'검사의 하위요인(문항)별 그래프를 제시해 줍니다.'} />
                            </Grid>
                          </Grid>
                        }/>
                        <FormControlLabel control={<Radio checked={standard} onClick={() => {setStandard(!standard);setCheckedSubScale(false);}} />} label={
                          <Grid container alignItems={'center'}>
                            <Grid item>
                              Cutoff
                            </Grid>
                            <Grid item >
                              <TooltipButton tooltip={'임상군과 비임상군을 구분하는데 기준이 되는 점수로, 특정 검사의 점수를 기준으로 치료의 필요 여부와 회복 정도를 평가할 때 참고할 수 있습니다.'} />
                            </Grid>
                          </Grid>
                        } />
                        <p style={{ textAlign: 'center', marginTop: '4px', borderTop: '1px solid rgb(234, 236, 240)', paddingTop: '4px', marginBottom: '0px' }} />
                        <FormControlLabel control={<Checkbox checked={stateChecked[0]} />} label={'진행중'} onChange={() => setStateChecked([!stateChecked[0], stateChecked[1]])}/>
                        <FormControlLabel control={<Checkbox checked={stateChecked[1]} />} label={'종결'} onChange={() => setStateChecked([stateChecked[0], !stateChecked[1]])}/>
                        {props.status === '1' ?
                        <>
                        <p style={{ textAlign: 'center', marginTop: '4px', borderTop: '1px solid rgb(234, 236, 240)', paddingTop: '4px', marginBottom: '0px' }} />
                        {/* <FormControlLabel control={<Checkbox checked={teamChecked[0]} />} label={'팀별'} onChange={() => setTeamChecked([!teamChecked[0], teamChecked[1]])}/> */}
                        <FormControlLabel control={<Checkbox checked={teamAllChecked} />} label={'전체'} onChange={handleChangeTeamAllChecked}/>
                        {teams.map((team, i) => (
                        <FormControlLabel key={i} control={<Checkbox checked={teamChecked[i]} />} label={team} onChange={() => handleChangeTeamChecked(i)}/>
                        ))}
                        </>:null}
                      </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={9.5}>
                    <Grid height={'500px'} marginBottom={'16px'} style={{ position: 'relative' }}>
                      <div style={{ background: 'linear-gradient(140deg, blue 5%, yellow, red)', opacity: 0.5, height: '500px', width: '100%', position: 'absolute', display: bgc ? 'block' : 'none' }} />
                        <ResponsiveScatterPlot
                            data={checkedSubScale ? subScaleDatas : filterDatas(datas)}
                            margin={{ top: 10, right: 110, bottom: 60, left: 60 }}
                            xScale={{ type: 'linear', max: chartMax, min: chartMin }}
                            xFormat='>-.2f'
                            yScale={{ type: 'linear', max: chartMax, min: chartMin }}
                            yFormat='>-.2f'
                            blendMode='multiply'
                            // colors={{ scheme: 'paired' }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '마지막 점수',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '초기 점수',
                                legendPosition: 'middle',
                                legendOffset: 46
                            }}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemWidth: 100,
                                    itemHeight: 12,
                                    itemsSpacing: 5,
                                    itemDirection: 'left-to-right',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            
                            tooltip={(e) => (
                                <Grid container sx={{ boxShadow: 1, p: 1, bgcolor: '#FFF', borderRadius: '4px', fontSize: '14px' }} alignItems={'center'}>
                                    <Grid item sx={{ pr: 0.5 }}><div style={{ width: '15px', height: '15px', backgroundColor: e.node.color, borderRadius: '7.5px' }}/></Grid>
                                    <Grid item sx={{ pr: 1 }}>{e.node.serieId}</Grid>
                                    <Grid item sx={{ pr: 1 }}>
                                        <span style={{ color: '#C0C0C0'}}>First: </span>{e.node.data.rawx}<br/>
                                        <span style={{ color: '#C0C0C0'}}>Last: </span> {e.node.data.rawy}
                                    </Grid>
                                    <Grid item>{e.node.data.ClientName}</Grid>
                                </Grid>
                            )}
                            onClick={(node, _) => {handleClickPoint(node);}}
                            // theme={{ 'background': bgc ? 'rgba(255, 255, 0, 0.1)' : 'rgba(0, 0, 0, 0)' }}
                            markers={markers}
                            layers={[
                              'grid', 'axes', 'nodes', 'markers', 'mesh', 'legends',
                              (e) => {
                                return(
                                <line
                                  x1={e.xScale(chartMin)}
                                  y1={e.yScale(chartMin)}
                                  x2={e.xScale(chartMax)}
                                  y2={e.yScale(chartMax)}
                                  stroke="rgba(0, 0, 0, 0.2)"
                                  strokeWidth={2}
                                  style={{ strokeDasharray: '4 4' }}  // 대시된 선
                                />)}
                            ]}
                        />
                    </Grid>
                    <Grid container alignItems={'stretch'} style={{ padding: '16px 8px 0px 8px'}}>
                        <Grid item xs={12} sm={12} md={3}>
                            <Grid style={{ padding: '0px 8px', height: '100%' }} >
                                {stateChecked[0] && !stateChecked[1] ? // 진행중 체크 시
                                <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                  <h3 style={{ textAlign: 'center', marginTop: '0px' }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                        사례 요약
                                        </Grid>
                                        <Grid item sx={{ ml: 1 }}>
                                          <TooltipButton tooltip={sessionComment} />
                                        </Grid>
                                      </Grid>
                                  </h3>
                                  <Grid container>
                                      <Grid item xs={8}>내담자 수</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 1) - calcData(filterSessionDatas(datas), 4) - calcData(filterSessionDatas(datas), 5)}</Grid>
                                  </Grid>
                                  <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                      <Grid item xs={8}>- 상담중</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 3)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>제외된 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 2)}</Grid>
                                  </Grid>
                                  <hr/>
                                  <Grid container>
                                      <Grid item xs={8}>10일 경과</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 6)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>30일 경과</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 7)}</Grid>
                                  </Grid>
                                  <hr/>
                                    <Grid container>
                                        <Grid item xs={8}>회복된 사례</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 10)}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>개선된 사례</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 11)}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>미미한 사례</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 12)}</Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                        <Grid item xs={8}>- 미미한 개선</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 14)}</Grid>
                                    </Grid>
                                    <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                        <Grid item xs={8}>- 미미한 악화</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 15)}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>악화된 사례</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 13)}</Grid>
                                    </Grid>
                                    {/* <hr/>
                                    <Grid container>
                                        <Grid item xs={8}>취소된 일정</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 8)}</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>불참한 일정</Grid>
                                        <Grid item xs={4}>{calcData(filterSessionDatas(datas), 9)}</Grid>
                                    </Grid> */}
                                    {/* <p style={{ textAlign: 'center'}}>Effect Size</p>
                                    <Grid container>
                                        <Grid item xs={8}>Cohen's d</Grid>
                                        <Grid item xs={4}>{cnt.Cohen}</Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>Hedges' g*</Grid>
                                        <Grid item xs={4}>{cnt.Hedges}</Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid> */}
                                </Grid>
                                :
                                !stateChecked[0] && stateChecked[1] ? // 종결 체크 시
                                <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                  <h3 style={{ textAlign: 'center', marginTop: '0px' }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                      <Grid item>
                                      사례 요약
                                      </Grid>
                                      <Grid item sx={{ ml: 1 }}>
                                        <TooltipButton tooltip={sessionComment} />
                                      </Grid>
                                    </Grid>
                                  </h3>
                                  <Grid container>
                                      <Grid item xs={8}>내담자 수</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 4) + calcData(filterSessionDatas(datas), 5)}</Grid>
                                  </Grid>
                                  <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                      <Grid item xs={8}>- 합의종결</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 4)}</Grid>
                                  </Grid>
                                  <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                      <Grid item xs={8}>- 조기종결</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 5)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>제외된 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 2)}</Grid>
                                  </Grid>
                                  <hr/>
                                  <Grid container>
                                      <Grid item xs={8}>회복된 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 10)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>개선된 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 11)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>미미한 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 12)}</Grid>
                                  </Grid>
                                  <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                      <Grid item xs={8}>- 미미한 개선</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 14)}</Grid>
                                  </Grid>
                                  <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                      <Grid item xs={8}>- 미미한 악화</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 15)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>악화된 사례</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 13)}</Grid>
                                  </Grid>
                                  {/* <hr/>
                                  <Grid container>
                                      <Grid item xs={8}>취소된 일정</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 8)}</Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>불참한 일정</Grid>
                                      <Grid item xs={4}>{calcData(filterSessionDatas(datas), 9)}</Grid>
                                  </Grid> */}
                                  {/* <p style={{ textAlign: 'center'}}>Effect Size</p>
                                  <Grid container>
                                      <Grid item xs={8}>Cohen's d</Grid>
                                      <Grid item xs={4}>{cnt.Cohen}</Grid>
                                      <Grid item xs={4}></Grid>
                                  </Grid>
                                  <Grid container>
                                      <Grid item xs={8}>Hedges' g*</Grid>
                                      <Grid item xs={4}>{cnt.Hedges}</Grid>
                                      <Grid item xs={4}></Grid>
                                  </Grid> */}
                              </Grid>:
                              <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                              <h3 style={{ textAlign: 'center', marginTop: '0px' }}>
                                <Grid container justifyContent={'center'} alignItems={'center'}>
                                  <Grid item>
                                  사례 요약
                                  </Grid>
                                  <Grid item sx={{ ml: 1 }}>
                                    <TooltipButton tooltip={sessionComment} />
                                  </Grid>
                                </Grid>
                              </h3>
                              <Grid container>
                                  <Grid item xs={8}>내담자 수</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 1)}</Grid>
                              </Grid>
                              <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                  <Grid item xs={8}>- 상담중</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 3)}</Grid>
                              </Grid>
                              <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                  <Grid item xs={8}>- 합의종결</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 4)}</Grid>
                              </Grid>
                              <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                  <Grid item xs={8}>- 조기종결</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 5)}</Grid>
                              </Grid>
                              <Grid container>
                                  <Grid item xs={8}>제외된 사례</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 2)}</Grid>
                              </Grid>
                              <hr/>
                              <Grid container>
                                  <Grid item xs={8}>10일 경과</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 6)}</Grid>
                              </Grid>
                              <Grid container>
                                  <Grid item xs={8}>30일 경과</Grid>
                                  <Grid item xs={4}>{calcData(filterSessionDatas(datas), 7)}</Grid>
                              </Grid>
                              <hr/>
                                <Grid container>
                                    <Grid item xs={8}>회복된 사례</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 10)}</Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>개선된 사례</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 11)}</Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>미미한 사례</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 12)}</Grid>
                                </Grid>
                                <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                    <Grid item xs={8}>- 미미한 개선</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 14)}</Grid>
                                </Grid>
                                <Grid container style={{ fontSize: '80%', paddingLeft: '5%' }}>
                                    <Grid item xs={8}>- 미미한 악화</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 15)}</Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>악화된 사례</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 13)}</Grid>
                                </Grid>
                                {/* <hr/>
                                <Grid container>
                                    <Grid item xs={8}>취소된 일정</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 8)}</Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>불참한 일정</Grid>
                                    <Grid item xs={4}>{calcData(filterSessionDatas(datas), 9)}</Grid>
                                </Grid> */}
                                {/* <p style={{ textAlign: 'center'}}>Effect Size</p>
                                <Grid container>
                                    <Grid item xs={8}>Cohen's d</Grid>
                                    <Grid item xs={4}>{cnt.Cohen}</Grid>
                                    <Grid item xs={4}></Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>Hedges' g*</Grid>
                                    <Grid item xs={4}>{cnt.Hedges}</Grid>
                                    <Grid item xs={4}></Grid>
                                </Grid> */}
                            </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            <Grid style={{ padding: '0px 8px', height: '100%' }} >
                                <Grid style={{ padding: '16px', border: '1px solid #EAECF0', borderRadius: '15px', height: '100%' }}>
                                    <h3 >
                                      <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                        검사 결과 평균
                                        </Grid>
                                        <Grid item sx={{ ml: 1 }}>
                                          <TooltipButton tooltip={`- 각 검사의 초기(첫번째) 검사 결과와 가장 마지막에 시행한 검사 결과를 바탕으로 계산합니다.
                                          - 증감: 마지막 결과 – 초기 결과
                                          - 증감률:  [(마지막 결과 - 초기 결과) / 초기 결과] * 100
                                          - RCI % : (증감 / RCI) * 100 (해당 검사에 Reliable Change Index (RCI)가 있는 경우 계산됨)  
                                          `} />
                                        </Grid>
                                      </Grid>
                                    </h3>
                                    <table className='mini_table'>
                                        <thead>
                                            <tr>
                                                <th>검사</th>
                                                <th>초기</th>
                                                <th>마지막</th>
                                                <th>증감</th>
                                                <th>증감률</th>
                                                <th>RCI%</th>
                                                <th style={{ position: 'relative' }}><img src={ICONLABEL} style={{ position: 'absolute', width: '24px', height: '24px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} alt='icon_label'/></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterDatas(datas).length > 0 ?
                                            filterDatas(datas).map((data, i) => (
                                            <tr key={i}>
                                              <td>{data.id}</td>
                                              <td>{calcPoint(data.data, 1)}</td>
                                              <td>{calcPoint(data.data, 2)}</td>
                                              <td>{calcPoint(data.data, 4)}</td>
                                              <td>{calcPoint(data.data, 3)}</td>
                                              <td>{calcPoint(data.data, 5)}</td>
                                              <td>
                                                {calcPoint(data.data, 6) ?
                                                <img src={calcPoint(data.data, 6)} alt='icon' style={{ width: '24px', marginTop: '4px' }} />:
                                                null}
                                              </td>
                                            </tr>
                                            )):
                                            <tr>
                                              <td colSpan={6}></td>
                                              <td></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default ClientChartView;